/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Dropdown, Menu, Radio, Select, Table } from 'antd';
import { useState } from 'react';
import { ReactComponent as AddDarkIcon } from '../assets/icons/add-dark-icon.svg';
import { ReactComponent as AddLightIcon } from '../assets/icons/add-light-icon.svg';
import { ReactComponent as AdvanceFilter } from '../assets/icons/advance-filter-icon.svg';
import { ReactComponent as ExportIcon } from '../assets/icons/export-down-icon.svg';
import { ReactComponent as VarianceIconDisable } from '../assets/icons/variance-disable-icon.svg';
import { ReactComponent as Equal } from '../assets/icons/variance-equal-icon.svg';
import { ReactComponent as VarianceIcon } from '../assets/icons/variance-icon.svg';
import CsvUploadComponent from '../components/CsvUpload/CsvUpload.component';
import ExportDataMenuComponent from '../components/ExportDataMenu/ExportDataMenu.component';
import ExportDataMenuSAP from '../components/VarianceAnalysis/ExportForSap/ExportForSap.component';
import VarianceAnalysisAdvancedFilters from '../components/VarianceAnalysis/VarianceAnalysisAdvancedFilters/VarianceAnalysisAdvancedFilters.component';
import ColoredValue from '../components/VarianceAnalysis/VarianceAnalysisShared/VarianceAnalysisShared.component';
import {
	AUDIT_COMPLETED,
	CLOSED,
	JNJ_BLUE,
	JNJ_RED,
	RECONCILIATION_IN_PROGRESS,
	UPLOAD_ACTIVE,
	UPLOAD_DISABLED,
	UPLOAD_ERROR_RESPONSE,
} from '../constants';
import {
	isLocationSearchActive,
	searchForLocationAsync,
} from '../redux/Location/Location.action';
import { store } from '../redux/store';
import { getLocationFilter } from './LocationModule.utils';

const { Option } = Select;

/* visualization mode list */
function Mode({ onChangeHandler, state }) {
	/* render */
	return (
		<div>
			<Menu className="pl-8 font-normal text-base">
				<div className="pb-4 pl-3  font-black text-base">
					<span>Visualize by</span>
				</div>
				<Radio.Group onChange={onChangeHandler} value={state}>
					<div className="p-2">
						<Radio value="lot" key="0">
							<span>Item Lot Level</span>
						</Radio>
					</div>

					<Menu.Divider />
					<div className="p-2">
						<Radio value="product" key="1">
							<span>Item Level</span>
						</Radio>
					</div>

					<Menu.Divider />
					<div className="p-2">
						<Radio value="discrepancyLot" key="2">
							<span>Discrepancy - Lot Level</span>
						</Radio>
					</div>

					<Menu.Divider />
					<div className="p-2">
						<Radio value="discrepancyProduct" key="3">
							<span>Discrepancy - Item Level</span>
						</Radio>
					</div>

					<Menu.Divider />
				</Radio.Group>
			</Menu>
		</div>
	);
}

const getCreateIcon = isCanCreate => {
	/* check the state */
	if (!isCanCreate) {
		/* return with light icon */
		return <AddLightIcon />;
	}
	/* return with dark icon */
	return <AddDarkIcon />;
};
/* IsCreateCount */
export function IsCreateCount({ createCount, isCanCreate, loading }) {
	return createCount ? (
		<Button
			className="px-2 lg:px-10"
			type="primary"
			disabled={isCanCreate}
			onClick={createCount}
			loading={loading}>
			<span className="pr-1 relative bottom-1">Create Count Instance</span>
			{getCreateIcon(isCanCreate)}
		</Button>
	) : null;
}
/* ShowUpload */
export function ShowUpload({ showUpload, callerComponent }) {
	switch (showUpload) {
		case UPLOAD_ACTIVE:
			return (
				<span className="h-10 lg:border-l border-solid">
					<CsvUploadComponent type={callerComponent} />
				</span>
			);
		case UPLOAD_DISABLED:
			return (
				<span className="h-10 lg:border-l border-solid">
					<CsvUploadComponent type={callerComponent} disabled />
				</span>
			);
		case UPLOAD_ERROR_RESPONSE:
			return (
				<span className="h-10 lg:border-l border-solid">
					<CsvUploadComponent
						disabled
						type={callerComponent}
						errorResponse="Cannot proceed due to Count or Variance Status"
					/>
				</span>
			);

		default:
			return null;
	}
}
/* ExportData */
export function ExportData({
	csvData,
	exportHeaders,
	callerComponent,
	mappedData,
	searchQuery,
	year,
}) {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{csvData ? (
				<ExportDataMenuComponent
					csvData={csvData}
					exportHeaders={exportHeaders}
					exportedFileName={callerComponent}
					mappedData={mappedData}
					searchQuery={searchQuery}
					year={year}
				/>
			) : null}
		</>
	);
}
/* isInputWithKey */
export const isInputWithKey = (e, resetData) => {
	if (!e.target.value) {
		resetData();
		store.dispatch(
			isLocationSearchActive({
				...store.getState().getLocation.isSearchActive,
				active: false,
			}),
		);
	}
};
/* getBackgroundColor */
export const getBackgroundColor = index => {
	const x = 2;
	if (index % x === 0) return 'bg-white-100';
	return 'bg-jnj_gray';
};
/* getSelectionType */
export const getSelectionType = (selectionType, rowSelection) => {
	if (selectionType) return { type: selectionType, ...rowSelection };

	return null;
};
/* getEditButton */
export const getEditButton = (selectRecordToEdit, editButton, text) => {
	if (selectRecordToEdit) return editButton(text);
	return null;
};
/* getHasDetail */
export const getHasDetail = (hasDetail, detailButton, encodedId) => {
	if (hasDetail) return detailButton(encodedId);
	return null;
};
/* getVarianceButton */
export const getVarianceButton = (callerComponent, varianceButton, text) => {
	if (callerComponent.toString() === 'Count Instance')
		return varianceButton(text);
	return null;
};
/* getDeleteButton */
export const getDeleteButton = (deleteData, deleteButton, id) => {
	if (deleteData) return deleteButton(id);
	return null;
};

/* renderPaginatedData */
export const renderPaginatedData = (
	erpType,
	country,
	sorter,
	pagination,
	filter,
	searchField,
	year,
) => {
	let Params = {};
	if (sorter?.field) {
		Params = {
			erpType,
			country,
			searchField,
			year,
			limit: pagination.pageSize,
			offset: pagination.current * pagination.pageSize - pagination.pageSize,
			coulumnName: sorter.field,
			sortkey: sorter.order,
		};
	} else {
		Params = {
			erpType,
			country,
			searchField,
			year,
			limit: pagination.pageSize,
			offset: pagination.current * pagination.pageSize - pagination.pageSize,
			coulumnName: 'cac',
			sortkey: 'asc',
		};
	}
	return store.dispatch(
		searchForLocationAsync(getLocationFilter(filter, Params)),
	);
};
/* visualization mode dropdown */
export function VisualizationMode({
	visualizationMode,
	onChangeHandler,
	state,
}) {
	if (visualizationMode)
		return (
			<Dropdown
				overlay={() => Mode({ onChangeHandler, state })}
				trigger={['click']}
				className="border-l border-solid ">
				<Button className="px-5">
					<span className="pr-1 relative bottom-1 ">Visualization Mode</span>
					<ExportIcon />
				</Button>
			</Dropdown>
		);
	return <div />;
}

export function DisplayCurrency({ convertToExtCurrency }) {
	return (
		<div className="px-4 flex border-l border-solid">
			<div className=" py-2 content-center">
				<span className="font-black text-base text-jnj_black pr-3 relative bottom-1">
					Currency
				</span>
			</div>
			<div>
				<Select
					onChange={e => convertToExtCurrency(e)}
					defaultValue="EUR"
					style={{ width: 120 }}
					suffixIcon={<ExportIcon />}>
					<Option value="eur">EUR</Option>
					<Option value="usd">USD</Option>
					<Option value="gbp">GBP</Option>
				</Select>
			</div>
		</div>
	);
}

export function ErpSelection({
	erpSelection,
	erpSource,
	defaultSource,
	changeErpSelection,
}) {
	if (erpSelection)
		return (
			<Select
				defaultValue={defaultSource}
				onChange={e => changeErpSelection(e)}
				value={erpSource}
				placeholder="Erp Source"
				style={{ width: 200, marginRight: '10px' }}>
				{erpSelection.map(erpSrcType => (
					<Option key={erpSrcType} value={erpSrcType}>
						{erpSrcType}
					</Option>
				))}
			</Select>
		);
	return <div />;
}

export function AdvancedFilter({
	advanceFilter,
	advanceParams,
	showExportExcelForSAP,
}) {
	const [visible, setVisible] = useState(false);
	const activateDropdown = () => {
		setVisible(x => !x);
	};
	if (advanceFilter)
		return (
			<>
				<div>
					<Dropdown
						overlay={
							<VarianceAnalysisAdvancedFilters
								toggleDropdown={activateDropdown}
								advanceParams={advanceParams}
							/>
						}
						trigger={['click']}
						className="border-l border-solid p-0"
						open={visible}
						onClick={activateDropdown}>
						<Button>
							<span className="relative  px-3 bottom-1">Advanced Filters</span>
							<AdvanceFilter />
						</Button>
					</Dropdown>
				</div>
				<div>{showExportExcelForSAP ? <ExportDataMenuSAP /> : null}</div>
			</>
		);
	return <div />;
}

const getSummaryClassName = (hiddenCols, colName) =>
	hiddenCols.filter(col => col === colName).length ? 'hidden' : 'visible';

export const varianceTableSummary = (
	pageData,
	summaryData,
	columnsOption,
	hiddenCols,
	visualization,
) => {
	const filtered = hiddenCols.map(hidden => columnsOption.indexOf(hidden));
	if (summaryData !== null && pageData && pageData.length)
		return (
			<Table.Summary fixed="top">
				<Table.Summary.Row>
					<Table.Summary.Cell
						className={
							7 - Number(visualization) - filtered.filter(n => n < 7).length > 0
								? 'visible'
								: 'hidden'
						}
						index={0}
						colSpan={
							7 - Number(visualization) * 2 - filtered.filter(n => n < 7).length
						}
					/>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'expectedQuantity')}
						index={
							8 - Number(visualization) * 2 - filtered.filter(n => n < 8).length
						}>
						<div className="flex gap-1 ">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								{' '}
								<ColoredValue value={summaryData?.expectedQuantity} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'hardCommit')}
						index={
							9 - Number(visualization) * 2 - filtered.filter(n => n < 9).length
						}>
						<div className="flex gap-1 ">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								{' '}
								<ColoredValue value={summaryData?.hardCommit} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'expectedValue')}
						index={
							10 - Number(visualization) - filtered.filter(n => n < 10).length
						}>
						<div className="flex gap-1">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								{' '}
								<ColoredValue value={summaryData?.expectedValue} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'blindCountQuantity')}
						index={
							11 - Number(visualization) - filtered.filter(n => n < 11).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData.blindCountQuantity >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.blindCountQuantity} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'blindCountValue')}
						index={
							12 - Number(visualization) - filtered.filter(n => n < 12).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData.blindCountValue >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue
									value={parseFloat(
										summaryData?.blindCountValue || 0 * 1.0,
									).toFixed(2)}
								/>
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'blindCountVariance')}
						index={
							13 - Number(visualization) - filtered.filter(n => n < 13).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.blindCountVariance >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData.blindCountVariance} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(
							hiddenCols,
							'blindCountVarianceValue',
						)}
						index={
							14 - Number(visualization) - filtered.filter(n => n < 14).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.blindCountVarianceValue >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue
									value={parseFloat(
										summaryData?.blindCountVarianceValue || 0 * 1.0,
									).toFixed(2)}
								/>
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'actualQuantity')}
						index={
							15 - Number(visualization) - filtered.filter(n => n < 15).length
						}>
						<div className="flex gap-1">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								{' '}
								<ColoredValue value={summaryData?.actualQuantity} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'actualValue')}
						index={
							16 - Number(visualization) - filtered.filter(n => n < 16).length
						}>
						<div className="flex gap-1">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.actualValue} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'initialVariance')}
						index={
							17 - Number(visualization) - filtered.filter(n => n < 17).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.initialVariance >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData.initialVariance} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'initialVarianceValue')}
						index={
							18 - Number(visualization) - filtered.filter(n => n < 18).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.initialVarianceValue >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.initialVarianceValue} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'adjustmentQuantity')}
						index={
							19 - Number(visualization) - filtered.filter(n => n < 19).length
						}>
						<div className="flex gap-1">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								{' '}
								<ColoredValue value={summaryData?.adjustmentQuantity} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'finalQuantity')}
						index={20 - filtered.filter(n => n < 20).length}>
						<div className="flex gap-1">
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.finalQuantity} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'finalValue')}
						index={
							21 - Number(visualization) - filtered.filter(n => n < 21).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData.finalValue >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.finalValue} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'finalVariance')}
						index={
							22 - Number(visualization) - filtered.filter(n => n < 22).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.finalVariance >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.finalVariance} />
							</div>
						</div>
					</Table.Summary.Cell>
					<Table.Summary.Cell
						className={getSummaryClassName(hiddenCols, 'finalVarianceValue')}
						index={
							23 - Number(visualization) - filtered.filter(n => n < 23).length
						}>
						<div
							className={`flex gap-1 ${
								summaryData?.finalVarianceValue >= 0 ? JNJ_BLUE : JNJ_RED
							} `}>
							<div className="py-1">
								<Equal />
							</div>
							<div>
								<ColoredValue value={summaryData?.finalVarianceValue} />
							</div>
						</div>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</Table.Summary>
		);
	return null;
};
export const disableVarianceAnalysisIcon = status =>
	status === AUDIT_COMPLETED ||
	status === RECONCILIATION_IN_PROGRESS ||
	status === CLOSED ? (
		<VarianceIcon />
	) : (
		<VarianceIconDisable style={{ cursor: 'not-allowed' }} />
	);
