/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createStructuredSelector } from 'reselect';
import VarianceAnalysis from '../../components/VarianceAnalysis/VarianceAnalysisComponent/VarianceAnalysis.component';
import WithSpinner from '../../components/WithSpinner/WithSpinner.HOC';
import { fetchConfigStartAsync } from '../../redux/Configuration/Config.actions';
import { fetchCountInstanceStartAsync } from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.actions';
import { selectErpType } from '../../redux/User/User.selector';
import {
	getFranchiseSubFranchiseRCodeStartAsync,
	searchCurrentVarianceAnalysisStartAsync,
} from '../../redux/VarianceAnalysis/VarianceAnalysis.action';
import {
	selectCurrentVarianceAnalysisError,
	selectErrorFranchiseSubFranchiseReasonCode,
	selectIsCurrentVarianceAnalysisLoaded,
	selectIsFranchiseSubFranchiseReasonCodeLoaded,
} from '../../redux/VarianceAnalysis/VarianceAnalysis.selectors';

const VarianceAnalysisWithSpinner = WithSpinner(VarianceAnalysis);
const countinstanceIdIdx = 3;

function VarianceAnalysisContainer({
	fetchVarianceAnalysisStart,
	isLoaded,
	errorFranchiseReasonCode,
	isFranchiseSubFranchiseLoaded,
	getFranchiseSubFranchiseReasonCodes,
	errorMessage,
	fetchCountInstanceStart,
	fetchConfigurationsStart,
	erpType,
	...otherProps
}) {
	const params = useParams();
	const history = useHistory();
	useEffect(() => {
		const id = history.location.pathname.split('/')[countinstanceIdIdx];
		fetchCountInstanceStart(id);
		fetchVarianceAnalysisStart(params.uuid);
		getFranchiseSubFranchiseReasonCodes(params.uuid, erpType);
		/* fetch list of approvers */
		fetchConfigurationsStart(params.country);
	}, []);

	return errorMessage == null && errorFranchiseReasonCode == null ? (
		<VarianceAnalysisWithSpinner
			isLoaded={isLoaded && isFranchiseSubFranchiseLoaded}
			{...otherProps}
		/>
	) : (
		<VarianceAnalysisWithSpinner
			isLoaded={!isLoaded || !isFranchiseSubFranchiseLoaded}
			{...otherProps}
		/>
	);
}

const mapStateToProps = createStructuredSelector({
	isLoaded: selectIsCurrentVarianceAnalysisLoaded,
	isFranchiseSubFranchiseLoaded: selectIsFranchiseSubFranchiseReasonCodeLoaded,
	errorMessage: selectCurrentVarianceAnalysisError,
	errorFranchiseReasonCode: selectErrorFranchiseSubFranchiseReasonCode,
	erpType: selectErpType,
});
const mapDispatchToProps = dispatch => ({
	fetchVarianceAnalysisStart: id =>
		dispatch(searchCurrentVarianceAnalysisStartAsync({ countInstanceId: id })),
	getFranchiseSubFranchiseReasonCodes: (id, erpType) =>
		dispatch(getFranchiseSubFranchiseRCodeStartAsync(id, erpType)),
	fetchCountInstanceStart: id => dispatch(fetchCountInstanceStartAsync(id)),
	fetchConfigurationsStart: countryCode =>
		dispatch(fetchConfigStartAsync(`${countryCode}`)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(VarianceAnalysisContainer);
