import CountInstanceActionTypes from '../CountInstance.types';

const INITIAL_STATE = {
	countInstances: null,
	errorMessage: null,
	isFetching: false,
	totalCount: null,
	errorFilterMessage: undefined,
	filters: null,
};

const getCountInstanceReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_START:
			return {
				...state,
				isFetching: true,
			};
		case CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isFetching: false,
				countInstances: payload.result,
				totalCount: payload.totalCount,
			};
		case CountInstanceActionTypes.SEARCH_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: payload,
			};
		case CountInstanceActionTypes.GET_COUNT_INSTANCE_FILTERS_SUCCESS:
			return {
				...state,
				isFetching: false,
				filters: payload,
			};
		case CountInstanceActionTypes.GET_COUNT_INSTANCE_FILTERS_FAILURE:
			return {
				...state,
				isFetching: false,
				errorFilterMessage: payload,
			};

		default:
			return state;
	}
};

export default getCountInstanceReducer;
