import CountryService from '../../services/CountryService';
import CountryListTypes from './CountryList.types';

const countryListService = new CountryService();

export const fetchCountriesStart = () => ({
	type: CountryListTypes.FETCH_COUNTRIES_START,
});

export const fetchCountriesSuccess = usersMap => ({
	type: CountryListTypes.FETCH_COUNTRIES_SUCCESS,
	payload: usersMap,
});

export const fetchCountriesFailure = errorMessage => ({
	type: CountryListTypes.FETCH_COUNTRIES_FAILURE,
	payload: errorMessage,
});

// eslint-disable-next-line arrow-body-style
export const fetchCountriesStartAsync = () => {
	return dispatch => {
		const listOfCountries = countryListService.getCountryListFactory();
		dispatch(fetchCountriesStart());
		listOfCountries
			.then(usersMap => {
				dispatch(fetchCountriesSuccess(usersMap));
			})
			.catch(error => dispatch(fetchCountriesFailure(error.message)));
	};
};
