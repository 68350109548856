import { createSelector } from 'reselect';

const selectUser = state => state.user;

// user session
export const selectIsUserLoginIn = createSelector(
	[selectUser],
	user => user.isSignInFetch,
);

export const selectUserLoginError = createSelector(
	[selectUser],
	user => user.errorSignIn,
);

export const selectUserLoginSuccess = createSelector(
	[selectUser],
	user => user.successSignIn,
);

// User Permission
export const selectLoggedInUser = createSelector(
	[selectUser],
	user => user.loggedInUser,
);
export const selectErpType = createSelector([selectUser], user => user.erpType);

export const selectWebPerms = createSelector([selectUser], user =>
	user.loggedInUser ? user.loggedInUser.web_perms : null,
);

export const selectUserPerms = createSelector([selectUser], user =>
	user.loggedInUser ? user.loggedInUser.user_perms : null,
);

export const selectUserCountry = createSelector([selectUser], user =>
	user.loggedInUser ? user.loggedInUser.country : null,
);

export const selectLoggedInUserRoles = createSelector(
	[selectUser],
	user => user.loggedInUser.roles,
);

// user Fetch /Search
export const selectUsersList = createSelector(
	[selectUser],
	user => user.userList,
);
export const selectNumberOfRecord = createSelector(
	[selectUser],
	user => user.totalNumberOfRecord,
);
export const selectUsersListLoaded = createSelector(
	[selectUser],
	user => !!user.userList,
);
export const selectUserFetchError = createSelector(
	[selectUser],
	user => user.errorFetch,
);
export const selectIsUsersListFetching = createSelector(
	[selectUser],
	user => user.isFetching,
);
export const selectIsUsersSearching = createSelector(
	[selectUser],
	user => user.isSearching,
);

// user modal
export const showUserModalForm = createSelector(
	[selectUser],
	user => user.showUserModalForm,
);

// Submit User
export const selectUserSubmitSuccess = createSelector(
	[selectUser],
	user => user.successSubmitUser,
);
export const selectUserSubmitError = createSelector(
	[selectUser],
	user => user.errorSubmitUser,
);
export const selectIsUserSubmitting = createSelector(
	[selectUser],
	user => user.isSubmitting,
);
export const selectSelectedUserToEdit = createSelector(
	[selectUser],
	user => user.selectedUserToEdit,
);
// Send Password Reset Selectors

export const selectSendPasswordResetSuccess = createSelector(
	[selectUser],
	user => user.successSendReset,
);
export const selectSendPasswordResetError = createSelector(
	[selectUser],
	user => user.errorSendReset,
);
export const selectIsPasswordResetSending = createSelector(
	[selectUser],
	user => user.isResetLinkSending,
);
