/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import WithSpinner from '../../components/WithSpinner/WithSpinner.HOC';
import { fetchConfigStartAsync } from '../../redux/Configuration/Config.actions';
import { selectIsConfigurationLoaded } from '../../redux/Configuration/Config.selector';
import { fetchCountriesStartAsync } from '../../redux/CountryList/CountryList.action';
import Configuration from './Configuration.component';

const ConfigurationWithSpinner = WithSpinner(Configuration);

function ConfigurationContainer({
	fetchConfigurationsStart,
	isLoaded,
	getCountriesList,
	...otherProps
}) {
	const country = useSelector(state => state.user.loggedInUser.country);
	useEffect(() => {
		fetchConfigurationsStart(country);
		getCountriesList();
	}, []);
	return <ConfigurationWithSpinner isLoaded={isLoaded} {...otherProps} />;
}

const mapStateToProps = createStructuredSelector({
	isLoaded: selectIsConfigurationLoaded,
});
const mapDispatchToProps = dispatch => ({
	fetchConfigurationsStart: countryCode =>
		dispatch(fetchConfigStartAsync(`${countryCode}`)),
	/* get countries list */
	getCountriesList: () => dispatch(fetchCountriesStartAsync()),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfigurationContainer);
