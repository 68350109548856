/* API base url */
export const backendBaseUrl = `${process.env.REACT_APP_BACKEND_API_URL}`;

/* API's */
const api = {
	backendEndpoints: {
		countryList: `${backendBaseUrl}/configurations/countries`,
		configuration: `${backendBaseUrl}/configurations`,
		scheduledCount: `${backendBaseUrl}/scheduledCount`,
		uploadAuditCount: `${backendBaseUrl}/scheduledcount`,
		countSheet: `${backendBaseUrl}/count-sheet`,
		auditCount: `${backendBaseUrl}/count-audit`,
		searchUrl: `${backendBaseUrl}/search`,
		erpUrl: `${backendBaseUrl}`,
		location: `${backendBaseUrl}/location`,
		login: `${backendBaseUrl}/login`,
		user: `${backendBaseUrl}/users`,
		sendNotification: `${backendBaseUrl}/email/send`,
		varianceAnalysis: `${backendBaseUrl}/variance`,
		varianceAnalysisWorkflow: `${backendBaseUrl}/workflow`,
		s3Bucket: `${backendBaseUrl}/s3/variance`,
	},
	customReportUrl: `${backendBaseUrl}/redirect?destination=custom_report`,
	privacyDocUrl: '/privacy-policy',
	termsAndConditionsDocUrl: '/terms-and-conditions',
	footerNoticeUrl: '/footer-notice',
	internalLoginUrl: '/redirect?destination=sso',
};

export default api;
