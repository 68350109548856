import React from 'react';
import { ErrorModal } from './errorMessage';
/* create count instance  */
export const isCanCreateInstance = (
	selectedLocation,
	setSelectedElement,
	setIsCanCreate,
	isConsignmentLead,
) => {
	/* if no selected Location, disable button  */
	if (selectedLocation.length) {
		setSelectedElement(selectedLocation);
		return setIsCanCreate(isConsignmentLead);
	}
	setSelectedElement([]);
	return setIsCanCreate(true);
};
/* render status and activate bg-color */
export const renderStatus = status => {
	/* bg-white is default color */
	let color = 'bg-white';
	switch (status) {
		/* if status is completed,bg-green  */
		case 'Completed':
			color = 'bg-jnj_active !important';
			break;
		case 'In Review':
			color = 'bg-jnj_progress !important';
			break;
		default:
			break;
	}
	return {
		props: {
			className: color,
		},
		children: <div>{status}</div>,
	};
};
/* create count logic  */
export const createCount = (
	selectedElement,
	setUniqueKeyVisible,
	setCountId,
	getCountInstanceStatus,
) => {
	if (
		/* in order to create a count, cac name has to be the same  */
		selectedElement.filter(v => v.cac === selectedElement[0].cac).length ===
		selectedElement.length
	) {
		return getCountInstanceStatus(
			selectedElement,
			setUniqueKeyVisible,
			setCountId,
		);
	}
	/* if it's not, notify user  */
	return ErrorModal('Cannot create one count for different Hospitals');
};
/* getLocationFilter */
export const getLocationFilter = (filter, data) => {
	const params = data;
	if (filter?.statusRiskAssessment) {
		params.filterStatusRiskAssessmentValue =
			filter.statusRiskAssessment.join(',');
	}
	if (filter?.locationStatus) {
		params.filterLocationStatusValue = filter.locationStatus.join(',');
	}
	if (filter?.subfranchiseCode) {
		params.filterSubFranchiseValue = filter.subfranchiseCode.join(',');
	}
	if (filter?.branch) {
		params.filterBranchPlantValue = filter.branch.join(',');
	}
	if (filter?.assignedRiskRating) {
		params.filterAssignedRiskRatingValue = filter.assignedRiskRating.join(',');
	}
	if (
		data.year &&
		data.year !== undefined &&
		Object.keys(data).filter(key => key === 'year').length === 0
	) {
		params.year = data.year;
	}

	return params;
};
/* if it's not, notify user  */
export const GenerateLocationExportLink = (
	erpType,
	country,
	totalRecord,
	limit,
	url,
	keyword,
	filterData,
	year,
) => {
	const filters = Object.entries(getLocationFilter(filterData, {}))
		.map(([key, value]) => `${key}=${value}`)
		.join('&');
	const concatUrl = [];
	for (let index = 0; index < Math.ceil(totalRecord / limit); index += 1) {
		concatUrl.push(
			`${url}/erps/${erpType.toLowerCase()}/location/search?searchField=${keyword}&limit=${limit}&offset=${
				limit * index
			}&coulumnName=locationId&sortkey=asc&${country}&${filters}&year=${year}`,
		);
	}
	return concatUrl;
};
