/* eslint-disable react/react-in-jsx-scope */
import { Radio, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DataTable from '../../components/DataTable/DataTable.component';
import LocationCreateForm from '../../components/LocationForm/LocationForm.component';
import UniqueKeyForm from '../../components/LocationForm/UniqueKeyForm/UniqueKeyForm.component';
import {
	CLOSED,
	DEFAULT_LIMIT_START,
	RISK_ASSESSMENT,
	locationDataMapped,
	userRoles,
} from '../../constants';
import { databaseDateFormat } from '../../constants/country';
import { selectCountInstances } from '../../redux/CountInstance/CountInstance.selectors';
import {
	getScheduleCountStatusAsync,
	searchForLocationAsync,
	setSelectedLocationFilters,
	updateLocationAsync,
	uploadLocationAsync,
} from '../../redux/Location/Location.action';
import {
	selectIsCountStatusLoading,
	selectIsLocationFetching,
	selectLocationFilters,
	selectLocations,
	selectTotalLocationRecord,
} from '../../redux/Location/Location.selectors';
import {
	selectErpType,
	selectLoggedInUser,
	selectUserCountry,
} from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { renderPaginatedData } from '../../utils/DataTable.utils';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';
import { LocationColumnsList } from '../../utils/LocationColumnTitle.utils';
import {
	createCount,
	getLocationFilter,
	isCanCreateInstance,
	renderStatus,
} from '../../utils/LocationModule.utils';
import { uploadLogic } from '../../utils/Upload.utils';

const InReview = 'In Review';
const Completed = 'Completed';
const NotAssessed = 'Not Assessed';
const NotStarted = 'Not Started';
const Closed = 'Closed';
const InProgress = 'In Progress';
const MaxNumberOfCharacter = 3;
/* LocationPage component */
function LocationPage({
	countryCode,
	loggedInUser,
	locations,
	updateLocationData,
	searchForLocationData,
	isFetching,
	resetLocationData,
	totalLocationRecords,
	getCountInstanceStatus,
	loading,
	erpType,
	locationFilters,
	setLocationFilters,
}) {
	/* state for selection Type */
	const [selectionType, setSelectionType] = useState('checkbox');
	/* state for updateVisible */
	const [updateVisible, setUpdateVisible] = useState(false);
	/* state for selectedElement */
	const [selectedElement, setSelectedElement] = useState([]);
	/* state for isCanCreate */
	const [isCanCreate, setIsCanCreate] = useState(true);
	/* state for selectedRecord */
	const [selectedRecord, setSelectedRecord] = useState({});
	/* state for selectedKey */
	const [selectedKey, setSelectedKey] = useState(0);
	/* state for uniqueKeyVisible */
	const [uniqueKeyVisible, setUniqueKeyVisible] = useState(false);
	/* set search query */

	const [searchQuery, setSearchQuery] = useState('');
	const [year, setYear] = useState(new Date().getFullYear());

	/* state for countId */
	const [countId, setCountId] = useState('');
	/* state for selectTranslations */
	const t = useSelector(selectTranslations);
	/* date Format */
	const dateFormat = getCountryDateFormat(countryCode);
	/* updateLocation */
	const updateLocation = async (value, id) => {
		updateLocationData(value, id);
		setUpdateVisible(false);
	};
	/* handle table change */
	const handleTableChange = (pagination, filter, sorter) => {
		/* search field is empty so paginate and sort   */
		setLocationFilters(filter);
		renderPaginatedData(
			erpType,
			!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
			sorter,
			pagination,
			filter,
			searchQuery,
			year,
		);
	};

	/* createCountHandler */
	const createCountHandler = () =>
		createCount(
			selectedElement,
			setUniqueKeyVisible,
			setCountId,
			getCountInstanceStatus,
		);
	/* row Selection */
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			/* check whether the selected row can create count instance or not  */
			isCanCreateInstance(
				selectedRows,
				setSelectedElement,
				setIsCanCreate,
				loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN),
			);
		},
		getCheckboxProps: record => ({
			disabled: record.name === 'Disabled User',
			// Column configuration not to be checked
			name: record.name,
		}),
	};
	/* selectedRecordItem */
	const selectedRecordItem = record => {
		if (record) {
			setUpdateVisible(true);
		}
		setSelectedRecord(record);
		setSelectedKey(record.id);
	};

	/* location Columns */
	const locationColumns = [
		{
			title: t.CAC,
			dataIndex: 'cac',
			key: 'cac',
			width: 200,
			render: text => <span>{text}</span>,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
			fixed: 'left',
		},
		{
			title: t.CACName,
			dataIndex: 'cacCustomerName',
			key: 'cacCustomerName',
			width: 200,
			render: text => <a href="#/">{text}</a>,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.FranchiseCode,
			dataIndex: 'franchiseCode',
			key: 'franchiseCode',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: franchiseCode => (
				<Tooltip placement="topLeft" title={franchiseCode}>
					{franchiseCode}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.SubFranchiseCode,
			dataIndex: 'subfranchiseCode',
			key: 'subfranchiseCode',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: subfranchiseCode => (
				<Tooltip placement="topLeft" title={subfranchiseCode}>
					{subfranchiseCode}
				</Tooltip>
			),
			filters: locationFilters?.subfranchise?.map(SubFranchiseValue => {
				/* filter as a franchise */
				const filter = { text: '', value: '' };
				filter.text = SubFranchiseValue.toUpperCase();
				filter.value = SubFranchiseValue;
				/* return filter value */
				return filter;
			}),
		},
		{
			title: t.SubFranchise,
			dataIndex: 'subfranchiseDescription',
			key: 'subfranchiseDescription',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: subfranchiseDescription => (
				<Tooltip placement="topLeft" title={subfranchiseDescription}>
					{subfranchiseDescription}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.Location,
			dataIndex: 'location',
			key: 'location',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: location => (
				<Tooltip placement="topLeft" title={location}>
					{location}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ShipTo,
			dataIndex: 'shipTo',
			key: 'shipTo',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: shipTo => (
				<Tooltip placement="topLeft" title={shipTo}>
					{shipTo}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ShipToName,
			dataIndex: 'shipToName',
			key: 'shipToName',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: text => (
				<Tooltip placement="topLeft" title={text}>
					{text}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.Branch,
			dataIndex: 'branch',
			key: 'branch',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: branch => (
				<Tooltip placement="topLeft" title={branch}>
					{branch}
				</Tooltip>
			),
			filters: locationFilters?.branch_plant?.map(branchPlant => {
				/* filter as a franchise */
				const filter = { text: '', value: '' };
				filter.text = branchPlant.toUpperCase();
				filter.value = branchPlant;
				/* return filter value */
				return filter;
			}),
			onFilter: (value, record) =>
				record.branch ? record.branch.indexOf(value) === 0 : '',
		},
		{
			title: t.ProductsQuantity,
			key: 'productQuantity',
			dataIndex: 'productQuantity',
			width: 200,
			render: productQuantity => renderStatus(productQuantity),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.TotalConsignedValue,
			dataIndex: 'totalConsignedValue',
			key: 'totalConsignedValue',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: totalConsignedValue => (
				<Tooltip placement="topLeft" title={totalConsignedValue}>
					{totalConsignedValue}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.InventoryContribution,
			dataIndex: 'inventoryContribution',
			key: 'inventoryContribution',
			ellipsis: {
				showTitle: true,
			},
			width: 200,
			render: inventoryContribution => (
				<Tooltip placement="topLeft" title={inventoryContribution}>
					<div className="w-40 whitespace-normal">{inventoryContribution}</div>
				</Tooltip>
			),

			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.AccuracyLastCount,
			dataIndex: 'lastCountAccuracy',
			key: 'lastCountAccuracy',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: lastCountAccuracy => (
				<Tooltip placement="topLeft" title={lastCountAccuracy}>
					{lastCountAccuracy}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.AccuracySecondLastCount,
			dataIndex: 'secondLastCountAccuracy',
			key: 'secondLastCountAccuracy',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: secondLastCountAccuracy => (
				<Tooltip placement="topLeft" title={secondLastCountAccuracy}>
					{secondLastCountAccuracy}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.CalculatedRiskRating,
			dataIndex: 'calculatedRiskRating',
			key: 'calculatedRiskRating',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: calculatedRiskRating => (
				<Tooltip placement="topLeft" title={calculatedRiskRating}>
					{calculatedRiskRating}
				</Tooltip>
			),

			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.InventoryTurnRate,
			dataIndex: 'inventoryTurnRate',
			key: 'inventoryTurnRate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: inventoryTurnRate => (
				<Tooltip placement="topLeft" title={inventoryTurnRate}>
					{inventoryTurnRate}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.AssignedRiskRating,
			dataIndex: 'assignedRiskRating',
			key: 'assignedRiskRating',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: assignedRiskRating => (
				<Tooltip placement="topLeft" title={assignedRiskRating}>
					{assignedRiskRating}
				</Tooltip>
			),
			filters: locationFilters?.assigned_risk_rating?.map(
				assignedRiskRating => {
					/* filter as a franchise */
					const filter = { text: '', value: '' };
					filter.text = assignedRiskRating.toUpperCase();
					filter.value = assignedRiskRating;
					/* return filter value */
					return filter;
				},
			),
		},
		{
			title: t.statusRiskAssessment,
			key: 'statusRiskAssessment',
			dataIndex: 'statusRiskAssessment',
			width: 200,
			render: statusRiskAssessment => renderStatus(statusRiskAssessment),
			filters: [
				{ text: NotAssessed, value: NotAssessed },
				{ text: InReview, value: InReview },
				{ text: Completed, value: Completed },
			],
			onFilter: (value, record) =>
				record.statusRiskAssessment
					? record.statusRiskAssessment.indexOf(value) === 0
					: '',
		},
		{
			title: t.PlannedCountMonth,
			dataIndex: 'plannedCountMonth',
			key: 'plannedCountMonth',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: plannedCountMonth => (
				<Tooltip
					placement="topLeft"
					title={
						plannedCountMonth && plannedCountMonth instanceof Date
							? moment(plannedCountMonth, databaseDateFormat).format(dateFormat)
							: ''
					}>
					{plannedCountMonth.toString().length > 1
						? moment(plannedCountMonth, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ExpectedCountNumber,
			dataIndex: 'expectedCountNumber',
			key: 'expectedCountNumber',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: expectedCountNumber => (
				<Tooltip placement="topLeft" title={expectedCountNumber}>
					{expectedCountNumber}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ActualCountNumber,
			dataIndex: 'actualCountNumber',
			key: 'actualCountNumber',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: actualCountNumber => (
				<Tooltip placement="topLeft" title={actualCountNumber}>
					{actualCountNumber}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.LastCountDate,
			dataIndex: 'lastCountDate',
			key: 'lastCountDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: lastCountDate => (
				<Tooltip
					placement="topLeft"
					title={
						lastCountDate && lastCountDate instanceof Date
							? moment(lastCountDate, databaseDateFormat).format(dateFormat)
							: ''
					}>
					{lastCountDate.toString().length > 1
						? moment(lastCountDate, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.LocationStatus,
			key: 'locationStatus',
			dataIndex: 'locationStatus',
			width: 200,
			render: locationStatus => renderStatus(locationStatus),
			filters: [
				{ text: NotStarted, value: NotStarted },
				{ text: InProgress, value: InProgress },
				{ text: Completed, value: Completed },
				{ text: Closed, value: Closed },
			],
			onFilter: (value, record) =>
				record.locationStatus ? record.locationStatus.indexOf(value) === 0 : '',
		},

		{
			title: t.RationaleForDeviation,
			dataIndex: 'rationaleForDeviation',
			key: 'rationaleForDeviation',
			ellipsis: {
				showTitle: true,
			},
			width: 200,
			render: rationaleForDeviation => (
				<Tooltip placement="topLeft" title={rationaleForDeviation}>
					<div className="w-40 whitespace-normal">{rationaleForDeviation}</div>
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.Notes,
			dataIndex: 'notes',
			key: 'notes',
			ellipsis: {
				showTitle: true,
			},
			width: 200,
			render: notes => (
				<Tooltip placement="topLeft" title={notes}>
					<div className="w-40 whitespace-normal">{notes}</div>
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
	];
	/* filter Options */
	const filterOptions = [
		{ id: 1, label: 'CAC', value: 'cac' },
		{ id: 2, label: 'Ship To', value: 'shipTo' },
		{ id: 3, label: 'Ship To Name', value: 'shipToName' },
		{ id: 4, label: 'CAC Name', value: 'cacCustomerName' },
		{ id: 5, label: 'Branch', value: 'branch' },
		{ id: 6, label: 'Location', value: 'location' },
		{ id: 7, label: 'Franchise Code', value: 'franchiseCode' },
		{ id: 8, label: 'Sub-Franchise Code', value: 'subfranchiseCode' },
		{ id: 9, label: 'Sub-Franchise', value: 'subfranchiseDescription' },
		{ id: 10, label: 'Total Consigned Value ', value: 'totalConsignedValue' },
		{ id: 11, label: 'Inventory Contribution', value: 'inventoryContribution' },
		{ id: 12, label: 'Accuracy Last Count(%)', value: 'lastCountAccuracy' },
		{
			id: 13,
			label: 'Accuracy Second Last Count(%)',
			value: 'secondLastCountAccuracy',
		},
		{ id: 14, label: 'Calculated Risk Rating', value: 'calculatedRiskRating' },
		{ id: 15, label: 'Inventory Turn Rate', value: 'inventoryTurnRate' },
		{ id: 16, label: 'Assigned Risk Rating', value: 'assignedRiskRating' },
		{ id: 17, label: 'Status Risk Assessment', value: 'statusRiskAssessment' },
		{ id: 18, label: 'Planned Count Month', value: 'plannedCountMonth' },
		{ id: 19, label: 'Expected Count Number', value: 'expectedCountNumber' },
		{ id: 20, label: 'Actual Count Number', value: 'actualCountNumber' },
		{ id: 21, label: 'Last Count Date', value: 'lastCountDate' },
		{ id: 22, label: 'Location Status', value: 'locationStatus' },
		{
			id: 23,
			label: 'Rationale for Deviation',
			value: 'rationaleForDeviation',
		},
		{ id: 24, label: 'Notes', value: 'notes' },
		{ id: 25, label: 'Products Quantity', value: 'productQuantity' },
	];

	useEffect(() => {
		if (searchQuery !== '') {
			searchForLocationData(
				erpType,
				!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
					? countryCode.join(' ')
					: '',
				searchQuery,
				year,
			);
		}
	}, [searchQuery]);
	/* Render component */
	/* eslint-disable react/jsx-props-no-spreading */
	return (
		<div>
			<Radio.Group
				onChange={({ target: { value } }) => {
					setSelectionType(value);
				}}
				value={selectionType}
			/>
			<DataTable
				erpSelection={{
					erpSource: loggedInUser?.erp_source_values,
					defaultSource: loggedInUser?.erp_source,
				}}
				columnsInit={locationColumns.map(column => LocationColumnsList(column))}
				showUpload={uploadLogic(
					RISK_ASSESSMENT,
					loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN) ? CLOSED : null,
					null,
					null,
					year,
				)}
				isCanCreate={isCanCreate}
				createCount={createCountHandler}
				data={locations || null}
				columnsOption={filterOptions}
				rowSelection={rowSelection}
				selectionType={selectionType}
				selectRecordToEdit={selectedRecordItem}
				hasEdit={year === new Date().getFullYear()}
				resetData={() => {
					resetLocationData(
						!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
							? countryCode.join(' ')
							: '',
						erpType,
						year,
					);
					setSearchQuery('');
				}}
				searchData={query => {
					setSearchQuery(query);
				}}
				filterCAC
				mappedData={locationDataMapped}
				exportHeaders={Array(filterOptions.map(column => column.label))}
				callerComponent="Risk Assessment"
				searchable
				perPage={DEFAULT_LIMIT_START}
				csvData={
					locations ? locations.map(({ id, ...keepAttrs }) => keepAttrs) : null
				}
				totalRecords={totalLocationRecords}
				handleTableChange={handleTableChange}
				isFetching={isFetching}
				loading={loading}
				year={year}
				setYear={setYear}
				enableYearSelection
			/>

			<LocationCreateForm
				selectedKey={selectedKey}
				record={selectedRecord}
				title="Update Location Data"
				visible={updateVisible}
				onCancel={() => setUpdateVisible(false)}
				onUpdate={(values, id) => updateLocation(values, id)}
				loading={isFetching}
				canUpdate={!loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)}
			/>
			<UniqueKeyForm
				setUniqueKeyVisible={setUniqueKeyVisible}
				visible={uniqueKeyVisible}
				onCancel={() => setUniqueKeyVisible(false)}
				countId={countId}
				setCountId={setCountId}
				selectedElement={selectedElement}
			/>
		</div>
	);
}
/* mapStateToProps */
const mapStateToProps = createStructuredSelector({
	locations: selectLocations,
	countInstance: selectCountInstances,
	isFetching: selectIsLocationFetching,
	totalLocationRecords: selectTotalLocationRecord,
	countryCode: selectUserCountry,
	loading: selectIsCountStatusLoading,
	loggedInUser: selectLoggedInUser,
	erpType: selectErpType,
	locationFilters: selectLocationFilters,
});

/* mapDispatchToProps */
const mapDispatchToProps = dispatch => ({
	updateLocationData: (value, id) => dispatch(updateLocationAsync(value, id)),
	searchForLocationData: (
		erpType,
		country,
		keyword,
		year = new Date().getFullYear(),
		limit = DEFAULT_LIMIT_START,
		offset = 0,
		columnName = 'cac',
		sortKey = 'asc',
		filter = null,
	) => {
		/* max keyword character for search has to be three */
		if (keyword?.length >= MaxNumberOfCharacter)
			dispatch(
				searchForLocationAsync(
					getLocationFilter(filter, {
						erpType,
						country,
						searchField: keyword,
						limit,
						offset,
						coulumnName: columnName,
						sortkey: sortKey,
						year,
					}),
				),
			);
	},
	resetLocationData: (country, erpType, year) =>
		dispatch(
			searchForLocationAsync({
				erpType,
				country,
				searchField: '',
				limit: DEFAULT_LIMIT_START,
				offset: 0,
				coulumnName: 'cac',
				sortkey: 'asc',
				year,
			}),
		),
	uploadLocationData: value => dispatch(uploadLocationAsync(value)),
	getCountInstanceStatus: (selectedElement, setUniqueKeyVisible, setCountId) =>
		dispatch(
			getScheduleCountStatusAsync(
				selectedElement,
				setUniqueKeyVisible,
				setCountId,
			),
		),
	setLocationFilters: filters => dispatch(setSelectedLocationFilters(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
