import { Select } from 'antd';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as SelectIcon } from '../../../assets/icons/export-down-icon.svg';
import { userRoles } from '../../../constants';
import { fetchConfigStartAsync } from '../../../redux/Configuration/Config.actions';
import { selectLoggedInUserRoles } from '../../../redux/User/User.selector';
import { filterCountry } from '../../../utils/Configuration/Configuration.utils';
/* select option */
const { Option } = Select;
/* country selector component  */
function CountrySelector({
	setSelectedCountry,
	countryCode,
	fetchConfigurationsStart,
	loggedInUserRoles,
}) {
	/* country onchange handler */
	const CountryHandler = value => {
		setSelectedCountry(value);
		fetchConfigurationsStart(value);
	};
	const countriesList = useSelector(state => state.getCountriesList);
	if (!countriesList?.countryList?.length) {
		return null;
	}
	/* render */
	return (
		<div className="md:flex md:gap-36 sm:xs:block">
			<div className="text-jnj_black text-lg font-black content-center">
				Configuration
			</div>

			<Select
				defaultValue={filterCountry(countriesList.countryList, countryCode)}
				className="sm:xs:w-36 md:w-52"
				onChange={CountryHandler}
				placeholder="Country"
				multiple
				disabled={
					!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN) &&
					!loggedInUserRoles.includes(userRoles.CONSIGNMENT_LEAD)
				}
				suffixIcon={<SelectIcon />}>
				{loggedInUserRoles &&
					loggedInUserRoles?.includes(userRoles.SYSTEM_ADMIN) &&
					/* remove ALL Country name from the list  */
					countriesList &&
					countriesList.countryList
						.filter(({ countryName }) => countryName !== 'ALL')
						?.map(({ countryName }) => (
							<Option key={countryName} value={countryName}>
								{countryName}
							</Option>
						))}
				{loggedInUserRoles &&
					loggedInUserRoles?.includes(userRoles.CONSIGNMENT_LEAD) &&
					/* remove ALL Country name from the list  */
					countryCode &&
					countryCode
						.filter(code => code !== 'ALL')
						?.map(code => (
							<Option key={code} value={code}>
								{code}
							</Option>
						))}
				{!loggedInUserRoles?.includes(userRoles.SYSTEM_ADMIN) &&
					!loggedInUserRoles?.includes(userRoles.CONSIGNMENT_LEAD) && <div />}
			</Select>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	loggedInUserRoles: selectLoggedInUserRoles,
});

const mapDispatchToProps = dispatch => ({
	/* get configurations */
	fetchConfigurationsStart: countryCode =>
		dispatch(fetchConfigStartAsync(countryCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
