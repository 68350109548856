import { createSelector } from 'reselect';

const selectConfigurations = state => state.getConfigurations;

// user Fetch /Search
export const selectConfigList = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.configurations,
);

export const selectIsConfigurationLoaded = createSelector(
	[selectConfigurations],
	getConfigurations => !!getConfigurations.configurations,
);

export const selectIsApproversListLoaded = createSelector(
	[selectConfigurations],
	getConfigurations => !!getConfigurations.approversList,
);

export const selectConfigurationsError = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.errorMessage,
);

export const selectIsLoading = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.isFetching,
);
export const selectIsUpdate = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.isUpdate,
);
export const selectIsSearching = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.isSearching,
);

export const selectApproversList = createSelector(
	[selectConfigurations],
	getConfigurations => getConfigurations.approversList,
);
