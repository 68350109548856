const VarianceAnalysisTypes = {
	FETCH_VARIANCE_ANALYSIS_EXPORT_START: 'FETCH_VARIANCE_ANALYSIS_EXPORT_START',
	FETCH_VARIANCE_ANALYSIS_EXPORT_SUCCESS:
		'FETCH_VARIANCE_ANALYSIS_EXPORT_SUCCESS',
	FETCH_VARIANCE_ANALYSIS_EXPORT_EXCEL_SUCCESS:
		'FETCH_VARIANCE_ANALYSIS_EXPORT_EXCEL_SUCCESS',
	FETCH_VARIANCE_ANALYSIS_EXPORT_FAILURE:
		'FETCH_VARIANCE_ANALYSIS_EXPORT_FAILURE',
	SEARCH_CURRENT_VARIANCE_ANALYSIS_START:
		'SEARCH_CURRENT_VARIANCE_ANALYSIS_START',
	SEARCH_CURRENT_VARIANCE_ANALYSIS_SUCCESS:
		'SEARCH_CURRENT_VARIANCE_ANALYSIS_SUCCESS',
	SEARCH_CURRENT_VARIANCE_ANALYSIS_FAILURE:
		'SEARCH_CURRENT_VARIANCE_ANALYSIS_FAILURE',

	GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_START:
		'GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_START',
	GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_SUCCESS:
		'GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_SUCCESS',
	GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_FAILURE:
		'GET_FRANCHISE_SUB_FRANCHISE_REASONCODES_FAILURE',
	VARIANCE_ANALYSIS_SUMMARY_START: 'VARIANCE_ANALYSIS_SUMMARY_START',
	VARIANCE_ANALYSIS_SUMMARY_SUCCESS: 'VARIANCE_ANALYSIS_SUMMARY_SUCCESS',
	VARIANCE_ANALYSIS_SUMMARY_FAILURE: 'VARIANCE_ANALYSIS_SUMMARY_FAILURE',
	ADV_FILTER_VARIANCE_ANALYSIS_START: 'ADV_FILTER_VARIANCE_ANALYSIS_START',
	ADV_FILTER_VARIANCE_ANALYSIS_SUCCESS: 'ADV_FILTER_VARIANCE_ANALYSIS_SUCCESS',
	ADV_FILTER_VARIANCE_ANALYSIS_FAILURE: 'ADV_FILTER_VARIANCE_ANALYSIS_FAILURE',
	SHOW_SUMMARY_MODAL: 'SHOW_SUMMARY_MODAL',
	SET_COUNT_ID: 'SET_COUNT_ID',
	CSV_UPLOAD_VARIANCE_START: 'CSV_UPLOAD_VARIANCE_START',
	CSV_UPLOAD_VARIANCE_SUCCESS: 'CSV_UPLOAD_VARIANCE_SUCCESS',
	CSV_UPLOAD_VARIANCE_FAILURE: 'CSV_UPLOAD_VARIANCE_FAILURE',
	VARIANCE_ANALYSIS_WORKFLOW_START: 'VARIANCE_ANALYSIS_WORKFLOW_START',
	VARIANCE_ANALYSIS_WORKFLOW_SUCCESS: 'VARIANCE_ANALYSIS_WORKFLOW_SUCCESS',
	VARIANCE_ANALYSIS_WORKFLOW_FAILURE: 'VARIANCE_ANALYSIS_WORKFLOW_FAILURE',
	VARIANCE_ANALYSIS_DB_UPDATE_START: 'VARIANCE_ANALYSIS_DB_UPDATE_START',
	VARIANCE_ANALYSIS_DB_UPDATE_SUCCESS: 'VARIANCE_ANALYSIS_DB_UPDATE_SUCCESS',
	VARIANCE_ANALYSIS_DB_UPDATE_FAILURE: 'VARIANCE_ANALYSIS_DB_UPDATE_FAILURE',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_START:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_START',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_SUCCESS:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_SUCCESS',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_FAILURE:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_FAILURE',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_START:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_START',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_SUCCESS:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_SUCCESS',
	VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_FAILURE:
		'VARIANCE_ANALYSIS_INIT_WORKFLOW_STEP_FAILURE',
	BULK_EXPORT_VARIANCE_ANALYSIS_START: 'BULK_EXPORT_VARIANCE_ANALYSIS_START',
	BULK_EXPORT_VARIANCE_ANALYSIS_SUCCESS:
		'BULK_EXPORT_VARIANCE_ANALYSIS_SUCCESS',
	BULK_EXPORT_VARIANCE_ANALYSIS_FAILURE:
		'BULK_EXPORT_VARIANCE_ANALYSIS_FAILURE',
	SET_VARIANCE_ANALYSIS_SORTER: 'SET_VARIANCE_ANALYSIS_SORTER',
};
export default VarianceAnalysisTypes;
