const generateUniqueKey = (cac, shipTo, shipToName, franchiseCode) =>
	cac
		.toString()
		.concat('_', shipTo.split(' ').join('__').toLowerCase().toString())
		.concat('_', shipToName.split(' ').join('__').toLowerCase().toString())
		.concat('_', franchiseCode.toString());

export const populateUniqueKeyId = selectedItem => {
	let concatId = '';
	/* eslint-disable-next-line */
	for (const item of selectedItem) {
		if (!concatId) {
			concatId = generateUniqueKey(
				item.cac,
				item.shipTo,
				item.shipToName,
				item.franchiseCode,
			);
		} else {
			concatId = concatId.concat(
				'_',
				generateUniqueKey(
					item.cac,
					item.shipTo,
					item.shipToName,
					item.franchiseCode,
				),
			);
		}
	}
	return concatId;
};

export const ListOutFranchise = selectedItem => {
	let concatId = '';
	if (selectedItem.length === 1) {
		return selectedItem[0].franchiseCode.toString();
	}
	/* eslint-disable-next-line */
	for (const item of selectedItem) {
		if (!concatId) {
			concatId = item.franchiseCode.toString();
		} else {
			concatId = concatId.concat(',', item.franchiseCode.toString());
		}
	}
	return concatId;
};
export default populateUniqueKeyId;
