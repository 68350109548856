/* eslint-disable react/jsx-props-no-spreading */
import { message } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import WithSpinner from '../../components/WithSpinner/WithSpinner.HOC';
import { userRoles } from '../../constants';
import {
	getCountInstanceFiltersStartAsync,
	searchCountInstanceStartAsync,
} from '../../redux/CountInstance/CountInstance.actions';
import {
	selectCountInstanceFiltersErrorMessage,
	selectCountInstancesError,
	selectIsCountInstancesLoaded,
} from '../../redux/CountInstance/CountInstance.selectors';
import {
	selectErpType,
	selectLoggedInUserRoles,
	selectUserCountry,
} from '../../redux/User/User.selector';
import CountInstance from './CountInstance.component';

const CountInstanceWithSpinner = WithSpinner(CountInstance);

function CountInstanceContainer({
	loggedInUserRoles,
	countryCode,
	fetchCountInstanceStart,
	isLoaded,
	errorMessage,
	erpType,
	errorMessageFilters,
	fetchCountInstanceFilters,
	...otherProps
}) {
	useEffect(() => {
		if (erpType === null) message.error('No Erp Type Selected!');
		else
			fetchCountInstanceStart(
				erpType,
				!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
					? countryCode.join(' ')
					: '',
			);
		fetchCountInstanceFilters(
			erpType,
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
			new Date().getFullYear(),
		);
	}, []);
	return errorMessage == null &&
		erpType !== null &&
		errorMessageFilters !== null ? (
		<CountInstanceWithSpinner isLoaded={isLoaded} {...otherProps} />
	) : (
		<CountInstanceWithSpinner isLoaded={!isLoaded} {...otherProps} />
	);
}

const mapStateToProps = createStructuredSelector({
	isLoaded: selectIsCountInstancesLoaded,
	errorMessage: selectCountInstancesError,
	countryCode: selectUserCountry,
	loggedInUserRoles: selectLoggedInUserRoles,
	errorMessageFilters: selectCountInstanceFiltersErrorMessage,
	erpType: selectErpType,
});
const mapDispatchToProps = dispatch => ({
	fetchCountInstanceStart: (erpType, countryCode) =>
		dispatch(searchCountInstanceStartAsync(erpType, countryCode)),
	fetchCountInstanceFilters: (erpType, country, selectedYear) =>
		dispatch(getCountInstanceFiltersStartAsync(erpType, country, selectedYear)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CountInstanceContainer);
