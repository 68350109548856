/* eslint-disable react/react-in-jsx-scope */
import { message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	erpAuditCountDataMapped,
	SEARCH_MIN_CHAR,
} from '../../constants';
import { selectCountInstance } from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import {
	getErpCountDetailsStartAsync,
	searchERPCountInstanceStartAsync,
} from '../../redux/CountInstanceDetails/ErpCount/ErpCount.action';
import {
	selectErpCountDetail,
	selectErpCountInstance,
	selectErpCountInstanceError,
	selectIsErpCountFetching,
	selectTotalErpCount,
} from '../../redux/CountInstanceDetails/ErpCount/ErpCount.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { checkArrayNull } from '../../utils/Verify.utils';
import DataTable from '../DataTable/DataTable.component';

function ErpCountInstance({
	totalCount,
	erpCountInstance,
	searchERPCount,
	resetERPData,
	errorMessage,
	isFetching,
	getNextPage,
	getErpDetail,
	lastGenerated,
	countInstance
}) {
	const t = useSelector(selectTranslations);
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => getErpDetail(), []);
	// ERP field columns
	const erpCountColumns = [
		{
			title: t.ProductId,
			dataIndex: 'itemNumber',
			key: 'itemNumberErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemNumber => (
				<Tooltip placement="topLeft" title={itemNumber}>
					{itemNumber}
				</Tooltip>
			),
		},
		{
			title: t.ProductDescription,
			dataIndex: 'itemDescription',
			key: 'itemDescriptionErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemDescription => (
				<Tooltip placement="topLeft" title={itemDescription}>
					{itemDescription}
				</Tooltip>
			),
		},
		{
			title: t.LotId,
			dataIndex: 'itemLotNum',
			key: 'itemLotNumErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemLotNum => (
				<Tooltip placement="topLeft" title={itemLotNum}>
					{itemLotNum}
				</Tooltip>
			),
		},
		{
			title: t.ExpiryDate,
			dataIndex: 'itemLotNumExpry',
			key: 'itemLotNumExpryErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemLotNumExpry => (
				<Tooltip placement="topLeft" title={itemLotNumExpry}>
					{itemLotNumExpry}
				</Tooltip>
			),
		},
		{
			title: t.Quantity,
			dataIndex: 'itemCount',
			key: 'itemCountErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemCount => (
				<Tooltip placement="topLeft" title={itemCount}>
					{itemCount}
				</Tooltip>
			),
		},
		{
			title: t.Franchise,
			dataIndex: 'franchise',
			key: 'franchiseErp',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: franchise => (
				<Tooltip placement="topLeft" title={franchise}>
					{franchise}
				</Tooltip>
			),
		},
	];
	// handle pagination
	const handleTableChange = pagination => {
		getNextPage(
			(pagination.current - 1) * pagination.pageSize,
			pagination.pageSize,
			searchQuery,
		);
	};
	return (
		<>
			{errorMessage ? message.error(errorMessage) : null}
			<DataTable
				totalRecords={totalCount}
				columnsInit={erpCountColumns.map(column =>
					column.title
						? {
								...column,
								title: (
									<div className="flex font-bold  justify-between">
										<div className="w-4/5 whitespace-normal">
											{column.title}
										</div>
									</div>
								),
						  }
						: column,
				)}
				resetData={() => {
					resetERPData();
					setSearchQuery('');
				}}
				data={erpCountInstance || null}
				perPage={DEFAULT_LIMIT_START}
				searchData={e => {
					searchERPCount(e);
					setSearchQuery(e);
				}}
				title={
					<span className="flex">
						<span style={{ fontWeight: 'bold' }}>
							{t.ERPCount}-{t.LastGenerated}
						</span>
						<span>
							: {lastGenerated ? moment(lastGenerated).format('lll') : ''}
						</span>
					</span>
				}
				mappedData={erpAuditCountDataMapped}
				exportHeaders={Array(erpCountColumns.map(column => column.title))}
				callerComponent="ERP Count"
				csvData={
					checkArrayNull(erpCountInstance)
						? erpCountInstance.map(({ id, ...keepAttrs }) => keepAttrs)
						: null
				}
				handleTableChange={handleTableChange}
				isFetching={isFetching}
				year={countInstance?.archive_year}
			/>
		</>
	);
}
// map state to props
const mapStateToProps = createStructuredSelector({
	erpCountInstance: selectErpCountInstance,
	errorMessage: selectErpCountInstanceError,
	isFetching: selectIsErpCountFetching,
	totalCount: selectTotalErpCount,
	lastGenerated: selectErpCountDetail,
	countInstance: selectCountInstance,
});
// map dispatch to props
const mapDispatchToProps = (dispatch, props) => ({
	// Search for Erp Count instance
	searchERPCount: query => {
		if (query.length > SEARCH_MIN_CHAR)
			dispatch(
				searchERPCountInstanceStartAsync(
					props.match.params.uuid,
					DEFAULT_OFFSET_START,
					DEFAULT_LIMIT_START,
					query,
				),
			);
	},
	// get the next page records for pagination
	getNextPage: (offset, limit, query) => {
		if (query.length < SEARCH_MIN_CHAR)
			dispatch(
				searchERPCountInstanceStartAsync(
					props.match.params.uuid,
					offset,
					limit,
					'',
				),
			);
		else
			dispatch(
				searchERPCountInstanceStartAsync(
					props.match.params.uuid,
					offset,
					limit,
					query,
				),
			);
	},
	// reset the default data when search query is cleared
	resetERPData: () =>
		dispatch(
			searchERPCountInstanceStartAsync(
				props.match.params.uuid,
				DEFAULT_OFFSET_START,
				DEFAULT_LIMIT_START,
				'',
			),
		),
	getErpDetail: () =>
		dispatch(getErpCountDetailsStartAsync(props.match.params.uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErpCountInstance);
