import CountInstanceActionTypes from '../CountInstance.types';

const INITIAL_STATE = {
	countInstancesResponse: null,
	errorMessage: null,
	isPosting: false,
};

const postCountInstanceReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case CountInstanceActionTypes.ADD_COUNT_INSTANCE_START:
			return {
				...state,
				isPosting: true,
			};
		case CountInstanceActionTypes.ADD_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isPosting: false,
				countInstancesResponse: payload,
			};
		case CountInstanceActionTypes.ADD_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isPosting: false,
				countInstancesResponse: null,
				errorMessage: payload,
			};
		default:
			return state;
	}
};

export default postCountInstanceReducer;
