/* eslint-disable class-methods-use-this */
import url from '../config/url';
import HttpService from './HttpService';

export default class CountryService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.countryList}`;
	}

	async getCountryListFactory() {
		const { data } = await this.http.get({ url: this.url });
		return data;
	}
}
