import AuditCountActionTypes from '../AuditCount.types';

const INITIAL_STATE = {
	isAuditFetching: false,
	errorMessageAudit: null,
	scheduledCountAudit: null,
	totalCount: null,
};

const auditCountGetReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_START:
			return {
				...state,
				isAuditFetching: true,
			};
		case AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isAuditFetching: false,
				scheduledCountAudit: payload.result,
				totalCount: payload.totalCount,
			};
		case AuditCountActionTypes.SEARCH_AUDIT_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isAuditFetching: false,
				errorMessageAudit: payload,
			};
		default:
			return state;
	}
};

export default auditCountGetReducer;
