import { MenuOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import JnjLogo from '../../assets/icons/JnJ_MedTech.png';
import { ReactComponent as BrandingLogo } from '../../assets/icons/smart-logo.svg';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import CustomNavLink from '../UI/CustomNavLink/CustomNavLink';

function NavBar() {
	const t = useSelector(selectTranslations);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className="flex items-center justify-between flex-wrap px-10 h-90 border-b-4">
			<div className="flex justify-start flex-wrap w-96 py-1">
				<span className="w-46 ">
					<BrandingLogo />
				</span>
				<span className="w-40">
					<img alt="JnjLogo" src={JnjLogo} />
				</span>
			</div>
			<div className="md:hidden">
				<MenuOutlined
					onClick={toggleMenu}
					className="text-jnj_black text-xl cursor-pointer"
				/>
			</div>
			<div className="hidden md:block md:flex items-baseline gap-12 text-jnj_black font-black text-base">
				<CustomNavLink to="location">{t.RiskAssessment}</CustomNavLink>
				<CustomNavLink to="count-instance">{t.CountInstance}</CustomNavLink>
				<CustomNavLink to="configuration">{t.Configuration}</CustomNavLink>
				<CustomNavLink to="user-list">{t.Users}</CustomNavLink>
			</div>
			<div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
				<div className="items-baseline gap-12 justify-end text-jnj_black font-black text-base h-full transition-transform ease-in-out duration-300 transform">
					<CustomNavLink to="location">{t.RiskAssessment}</CustomNavLink>
					<CustomNavLink to="count-instance">{t.CountInstance}</CustomNavLink>
					<CustomNavLink to="configuration">{t.Configuration}</CustomNavLink>
					<CustomNavLink to="user-list">{t.Users}</CustomNavLink>
				</div>
			</div>
		</nav>
	);
}

export default NavBar;
