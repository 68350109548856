// TODO constant files will be translated
import { getActiveUser } from '../utils/Authentication/UserSession.utils';
/* supported Languages */
export const supportedLanguages = {
	en: 'Eng',
	fr: 'Fre',
	cn: 'Chi',
};

/* Notification email initial message */
export const notificationEmail = countInstance =>
	countInstance
		? `The Count ${countInstance.scheduledCountID} scheduled on ${
				countInstance.expectedStartDate
					? countInstance.expectedStartDate
					: ' -- '
		  } has been assigned to you. for any question please contact ${
				JSON.parse(getActiveUser()).email
		  } or the Local Consignment Team.`
		: 'Sorry we are not able to load the information';
