const ConfigurationActionTypes = {
	FETCH_CONFIG_START: 'FETCH_CONFIG_START',
	FETCH_CONFIG_SUCCESS: 'FETCH_CONFIG_SUCCESS',
	FETCH_CONFIG_FAILURE: 'FETCH_CONFIG_FAILURE',
	UPDATE_CONFIG_START: 'UPDATE_CONFIG_START',
	UPDATE_CONFIG_SUCCESS: 'UPDATE_CONFIG_SUCCESS',
	UPDATE_CONFIG_FAILURE: 'UPDATE_CONFIG_FAILURE',
	SEARCH_FOR_APPROVER_START: 'SEARCH_FOR_APPROVER_START',
	SEARCH_FOR_APPROVER_SUCCESS: 'SEARCH_FOR_APPROVER_SUCCESS',
	SEARCH_FOR_APPROVER_FAILURE: 'SEARCH_FOR_APPROVER_FAILURE',
};
export default ConfigurationActionTypes;
