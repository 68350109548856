/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import { Button, Form, InputNumber } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { searchCurrentVarianceAnalysisStartAsync } from '../../../redux/VarianceAnalysis/VarianceAnalysis.action';
import { perseAndPrepareAdvancedFilterParams } from '../../../utils/VarianceAnalysis.utils';

export function FormItem(props) {
	const { label, from, to } = props;
	return (
		<div className="flex flex-row text-base justify-between items-center font-black w-104 h-9 mb-2 gap-2 ">
			<div className="h-5" style={{ width: '200px' }}>
				{label}
			</div>
			<Form.Item style={{ marginBottom: '0' }} name={from}>
				<InputNumber placeholder="from" className="w-27" data-testid={from} />
			</Form.Item>
			<Form.Item style={{ marginBottom: '0' }} name={to}>
				<InputNumber placeholder="to" className="w-27" data-testid={to} />
			</Form.Item>
		</div>
	);
}

export function CustomForm({ form, onClear, onFinish }) {
	return (
		<Form form={form} onFinish={onFinish}>
			<FormItem
				label="Expected Qty:"
				from="expectedQtyFrom"
				to="expectedQtyTo"
				minimumValue={0}
			/>
			<FormItem label="Count Qty:" from="countQtyFrom" to="countQtyTo" />
			<FormItem
				label="Initial Variance:"
				from="initialVarianceFrom"
				to="initialVarianceTo"
			/>
			<FormItem
				label="Initial Variance Value:"
				from="initialVarianceValueFrom"
				to="initialVarianceValueTo"
			/>
			<FormItem
				label="Adjustment Qty:"
				from="adjustmentQtyFrom"
				to="adjustmentQtyTo"
			/>
			<FormItem label="Final Qty:" from="finalQtyFrom" to="finalQtyTo" />
			<FormItem
				label="Final Variance:"
				from="finalVarianceFrom"
				to="finalVarianceTo"
			/>
			<FormItem
				label="Final Variance Value:"
				from="finalVarianceValueFrom"
				to="finalVarianceValueTo"
			/>
			<div className="flex flex-row justify-between mt-6">
				<Button
					className="border-jnj_red rounded-sm  flex items-center justify-center w-48 h-9 border-2"
					onClick={onClear}
					data-testid="clear">
					Clear
				</Button>
				<Button
					htmlType="submit"
					type="primary"
					className="rounded-sm h-9 flex items-center justify-center w-48"
					data-testid="apply">
					Apply
				</Button>
			</div>
		</Form>
	);
}

function VarianceAnalysisAdvancedFilters({
	toggleDropdown,
	fetchVarianceAnalysisStart,
	advanceParams,
}) {
	const [form] = Form.useForm();
	const params = useParams();

	const onFinish = () => {
		const {
			adjustmentQtyFrom,
			adjustmentQtyTo,
			countQtyFrom,
			countQtyTo,
			expectedQtyFrom,
			expectedQtyTo,
			finalQtyFrom,
			finalQtyTo,
			finalVarianceFrom,
			finalVarianceTo,
			finalVarianceValueFrom,
			finalVarianceValueTo,
			initialVarianceFrom,
			initialVarianceTo,
			initialVarianceValueFrom,
			initialVarianceValueTo,
		} = form.getFieldsValue();
		const advFilterParams = {
			expQty: {
				from: `from:${expectedQtyFrom}`,
				to: `expQty=to:${expectedQtyTo}`,
			},
			actQty: {
				from: `from:${countQtyFrom}`,
				to: `actQty=to:${countQtyTo}`,
			},
			initVar: {
				from: `from:${initialVarianceFrom}`,
				to: `initVar=to:${initialVarianceTo}`,
			},
			initVarVal: {
				from: `from:${initialVarianceValueFrom}`,
				to: `initVarVal=to:${initialVarianceValueTo}`,
			},
			adjQty: {
				from: `from:${adjustmentQtyFrom}`,
				to: `adjQty=to:${adjustmentQtyTo}`,
			},
			finalQty: {
				from: `from:${finalQtyFrom}`,
				to: `finalQty=to:${finalQtyTo}`,
			},
			finalVarValue: {
				from: `from:${finalVarianceFrom}`,
				to: `finalVarValue=to:${finalVarianceTo}`,
			},
			finalVar: {
				from: `from:${finalVarianceValueFrom}`,
				to: `finalVar=to:${finalVarianceValueTo}`,
			},
		};
		const paramter = perseAndPrepareAdvancedFilterParams(advFilterParams);
		advanceParams(paramter);
		fetchVarianceAnalysisStart({
			id: params.uuid,
			advancedFilter: paramter,
		});

		toggleDropdown();
	};

	const onClear = () => {
		advanceParams('');
		form.resetFields();
		toggleDropdown();
		fetchVarianceAnalysisStart({ id: params.uuid });
	};

	return (
		<div width={490} className="bg-white">
			<div className="px-10 py-6">
				<CustomForm form={form} onFinish={onFinish} onClear={onClear} />
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	fetchVarianceAnalysisStart: ({ id, advancedFilter }) =>
		dispatch(
			searchCurrentVarianceAnalysisStartAsync({
				countInstanceId: id,
				advancedFilter,
			}),
		),
});
export default connect(
	null,
	mapDispatchToProps,
)(VarianceAnalysisAdvancedFilters);
