import { PRIVATE_ROUTE_BASE, userPermissions } from './constants';
import Login from './pages/AuthPages/Login/Login.component';
import ConfigurationContainer from './pages/Configuration/Configuration.container';
import CountInstanceContainer from './pages/CountInstance/CountInstance.container';
import CountInstanceDetailContainer from './pages/CountInstanceDetail/CountInstanceDetail.container';
import Page404 from './pages/ErrorPages/Page404/Page404.component';
import LocationContainer from './pages/Location/Location.container';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.component';
import UserListContainer from './pages/UserManagement/UserList/UserList.container';
import UserProfile from './pages/UserManagement/UserProfile/UserProfile.component';
import VarianceAnalysisContainer from './pages/VarianceAnalysis/VarianceAnalysis.container';
import VarianceAnalysisWorkstep from './pages/VarianceAnalysisWorkFlow/VarianceAnalysisWorkFlow.component';

const routes = {
	public: [
		{
			key: 'login',
			exact: true,
			path: '/',
			component: Login,
		},
		{
			key: 'login',
			exact: true,
			path: '/login',
			component: Login,
		},
		{
			key: 'privacy-policy',
			exact: true,
			path: '/privacy-policy',
			component: PrivacyPolicy,
		},
		{
			key: 'workflow-step',
			exact: true,
			path: '/workflow-step',
			component: VarianceAnalysisWorkstep,
		},
		{
			key: 'terms-and-conditions',
			exact: true,
			path: `/terms-and-conditions`,
			component: Page404,
		},
	],
	private: [
		{
			key: 'count-instance',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/count-instance`,
			component: CountInstanceContainer,
			permission: [
				{
					name: userPermissions.COUNT_INSTANCE_MANAGEMENT,
				},
				{
					name: userPermissions.COUNT_INSTANCE_GRID,
				},
				{
					name: userPermissions.VARIANCE_ANALYSIS_MANAGEMENT,
				},
				{
					name: userPermissions.VARIANCE_APPROVAL_FUNCTION,
				},
			],
		},
		{
			key: 'count-instance',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/count-instance/:uuid`,
			component: CountInstanceDetailContainer,
			permission: {
				name: userPermissions.COUNT_INSTANCE_MANAGEMENT,
			},
		},

		{
			key: 'variance-analysis',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/count-instance/:uuid/variance-analysis/:uuid/:country`,
			component: VarianceAnalysisContainer,
			permission: [
				{
					name: userPermissions.VARIANCE_ANALYSIS_MANAGEMENT,
				},
				{
					name: userPermissions.VARIANCE_APPROVAL_FUNCTION,
				},
			],
		},
		{
			key: 'location',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/location`,
			component: LocationContainer,
			permission: {
				name: userPermissions.LOCATION_MANAGEMENT,
			},
		},

		{
			key: 'location',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/`,
			component: LocationContainer,
			permission: {
				name: userPermissions.LOCATION_MANAGEMENT,
			},
		},

		{
			key: 'user-profile',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/user-profile`,
			component: UserProfile,
		},
		{
			key: 'user-list',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/user-list`,
			component: UserListContainer,
			permission: {
				name: userPermissions.USER_MANAGEMENT,
			},
		},
		{
			key: 'configuration',
			exact: true,
			path: `${PRIVATE_ROUTE_BASE}/configuration`,
			component: ConfigurationContainer,
			permission: {
				name: userPermissions.CONFIGURATION_MANAGEMENT,
			},
		},
	],
};

export default routes;
