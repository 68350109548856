import { CloseOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React from 'react';
import successLogo from '../assets/icons/success-icon.svg';

const SuccessMessage = messageToDisplay =>
	message.success({
		content: (
			<div className="pl-10 pr-10 pb-10">
				<Button onClick={() => message.destroy()}type="ghost" shape='circle' icon={<CloseOutlined />} style={{float: 'right', marginTop: '-100px', marginRight: '-20px', color: '#000000'}}/>
				<div className="text-jnj_black font-bold text-lg ">
					{messageToDisplay}
				</div>
			</div>
		),
		style: {
			marginTop: '20vh',
		},
		icon: (
			<div className="pt-10 flex pb-4 justify-center">
				<div>
					<img src={successLogo} alt="React Logo" />
				</div>
			</div>
		),
	});

export default SuccessMessage;
