import VarianceAnalysisTypes from '../VarianceAnalysis.types';

const INITIAL_STATE = {
	bulkVarianceAnalysisData: null,
	bulkExcelVarianceAnalysisData: null,
	errorMessage: undefined,
	isBulkRequestFetching: false,
	isExportGrid: false,
	isDownloadInit: false,
	totalNumberOfRecord: 0,
	countInstanceId: null,
};

const varianceAnalysisExportReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_START:
			return {
				...state,
				isDownloadInit: payload === 'initial',
				isExportGrid: payload === 'current',
			};
		case VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_SUCCESS:
			return {
				...state,
				isDownloadInit: false,
				isExportGrid: false,
				bulkVarianceAnalysisData: payload?.VarianceAnalysisData,
			};

		case VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_EXCEL_SUCCESS:
			return {
				...state,
				isDownloadInit: false,
				isExportGrid: false,
				bulkExcelVarianceAnalysisData: payload?.VarianceAnalysisData,
			};
		case VarianceAnalysisTypes.FETCH_VARIANCE_ANALYSIS_EXPORT_FAILURE:
			return {
				...state,
				isDownloadInit: false,
				isExportGrid: false,
				errorMessage: payload,
			};
		case VarianceAnalysisTypes.SET_COUNT_ID:
			return {
				...state,
				countInstanceId: payload,
			};

		default:
			return state;
	}
};

export default varianceAnalysisExportReducer;
