/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// TODO refactor this component to improve cognitive-complexity
/* eslint-disable react/jsx-props-no-spreading */

import { Button, message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as ArrowBackIcon } from '../../../assets/icons/arrow-back-icon.svg';
import {
	CLOSED,
	DEFAULT_LIMIT_START,
	DEFAULT_VISUALIZATION_MODE,
	DISCREPANCY_LOT,
	DISCREPANCY_PRODUCT,
	PRODUCT,
	SEARCH_MIN_CHAR,
	userPermissions,
	userRoles,
	VARIANCE_ANALYSIS,
	varianceAnalysisDataMapped,
} from '../../../constants';
import { databaseDateFormat } from '../../../constants/country';
import {
	selectConfigList,
	selectIsConfigurationLoaded,
} from '../../../redux/Configuration/Config.selector';
import { selectCountInstance } from '../../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import { selectTranslations } from '../../../redux/i18n/i18nSlice';
import { store } from '../../../redux/store';
import {
	selectLoggedInUserRoles,
	selectUserCountry,
	selectWebPerms,
} from '../../../redux/User/User.selector';
import {
	bulkExportVarianceAnalysisAsync,
	getCSVUploadStatusStartAsync,
	getVarianceAnalysisSummaryStartAsync,
	getVarianceAnalysisWorkflowStartAsync,
	searchCurrentVarianceAnalysisStartAsync,
	setCountId,
	setVarianceAnalysisSorter,
	setVarianceAnalysisWorkflowStepStartAsync,
} from '../../../redux/VarianceAnalysis/VarianceAnalysis.action';
import {
	selectCurrentVarianceAnalysis,
	selectCurrentVarianceAnalysisError,
	selectCurrentVarianceAnalysisIsSearching,
	selectCurrentVarianceAnalysisTotalRecords,
	selectErrorFranchiseSubFranchiseReasonCode,
	selectFranchiseSubFranchiseReasonCode,
	selectInitialVarianceAnalysisExportData,
	selectIsInitialVarianceAnalysisExportLoading,
	selectShowSummaryModal,
	selectVarianceAnalysisSummaryLoaded,
	selectWorkflowStatus,
	selectWorkflowStatusFetching,
	selectWorkflowStepFetching,
} from '../../../redux/VarianceAnalysis/VarianceAnalysis.selectors';
import {
	DisplayCurrency,
	varianceTableSummary,
} from '../../../utils/DataTable.utils';
import { getCountryDateFormat } from '../../../utils/GetCountryFormats.utils';
import { uploadLogic } from '../../../utils/Upload.utils';
import { VarianceColumnsList } from '../../../utils/VarainceColumnTilte.utils';
import { isDisableButton } from '../../../utils/VarianceAnalysis.utils';
import DataTable from '../../DataTable/DataTable.component';
import DownLoadInitVariance from '../DownLoadInitVariance/DownLoadInitVariance.component';
import VarianceAnalysisReject from '../VarianceAnalysisReject/VarianceAnalysisReject.component';
import ColoredValue from '../VarianceAnalysisShared/VarianceAnalysisShared.component';
import VarianceAnalysisSummary from '../VarianceAnalysisSummary/VarianceAnalysisSummary.component';

/* Variance Analysis component */
function VarianceAnalysis({
	webPermissions,
	varianceAnalysis,
	searchVarianceAnalysis,
	getNextPage,
	resetVarianceAnalysisData,
	errorMessage = null,
	isFetching,
	totalRecords,
	exportLoading,
	exportData,
	downloadInitialVariance,
	franchiseSubFranchiseReasonCode,
	errorFranchiseSubFranchiseReasonCode,
	getVarianceAnalysisSummary,
	summaryLoading,
	showSummaryModal,
	switchToVisualizationMode,
	countInstance,
	configLoaded,
	approveVarianceAnalysis,
	getVarianceAnalysisWorkflowStatus,
	workflowStepLoading,
	workflowStatusLoading,
	configurationsList,
	getUpdateStatus,
	countryCode,
	setSorter,
	loggedInUserRoles,
}) {
	/* useSelector */
	const t = useSelector(selectTranslations);
	/* history */
	const history = useHistory();
	/* useParams */
	const params = useParams();
	const [hideExportExcel, setHideExportExcel] = useState(false);
	const [currentLimit, setCurrentLimit] = useState(10);

	const csvUploadStatus = useSelector(
		state => state?.importVarianceAnalysis?.dbUpdateStatus,
	);

	const csvUploadError = useSelector(
		state => state?.importVarianceAnalysis?.dbUpdateError,
	);
	const dateFormat = getCountryDateFormat(countryCode);

	/* show summery state */
	const [setShowSummery] = useState(false);
	/* show reject state */
	const [showReject, setShowReject] = useState(false);
	/* searchQuery */
	const [searchQuery, setSearchQuery] = useState('');
	const [visualization, setVisualization] = useState(false);
	const [prevCurrency, setPrevCurrency] = useState('eur');

	const [paginate, setPaginate] = useState(null);
	/* set Advanced parameters */
	const [advanceParam, setAdvancedParams] = useState('');
	const [varianceData, setVarianceData] = useState(null);
	const [varianceSummary, setVarianceSummary] = useState(null);
	const [visualMode, setVisualMode] = useState(null);
	/* variance analysis columns */
	const varianceAnalysisColumns = [
		/* FranchiseCode */
		{
			title: t.FranchiseCode,
			dataIndex: 'franchise',
			key: 'franchise',
			render: franchise => (
				<Tooltip placement="topLeft" title={franchise}>
					{franchise}
				</Tooltip>
			),
			width: 200,
			filters: franchiseSubFranchiseReasonCode?.franchise?.map(franchise => {
				const filter = { text: '', value: '' };
				filter.text = franchise.toUpperCase();
				filter.value = franchise;
				return filter;
			}),
		},
		/* SubFranchise */
		{
			title: t.SubFranchise,
			dataIndex: 'subFranchise',
			key: 'subFranchise',
			render: subFranchise => (
				<Tooltip placement="topLeft" title={subFranchise}>
					{subFranchise}
				</Tooltip>
			),
			width: 200,

			filters: franchiseSubFranchiseReasonCode?.Subfranchise?.map(
				subFranchise => {
					const filter = { text: '', value: '' };
					filter.text = subFranchise.toUpperCase();
					filter.value = subFranchise;
					return filter;
				},
			),
		},
		/* Product */
		{
			title: t.Product,
			dataIndex: 'productNumber',
			key: 'product_number',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: productNumber => (
				<Tooltip placement="topLeft" title={productNumber}>
					{productNumber}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Lot */
		{
			title: t.Lot,
			dataIndex: 'productLot',
			key: 'product_lot',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: productLot => (
				<Tooltip placement="topLeft" title={productLot}>
					{productLot}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Lot */
		{
			title: t.ExpiryDate,
			dataIndex: 'expiryDate',
			key: 'expiry_date',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: expiryDate => (
				<Tooltip placement="topLeft" title={expiryDate}>
					{expiryDate}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Lot */
		{
			title: t.Description,
			dataIndex: 'description',
			key: 'description',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: description => (
				<Tooltip placement="topLeft" title={description}>
					{description}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Lot */
		{
			title: t.Sterile,
			dataIndex: 'sterile',
			key: 'sterile',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: sterile => (
				<Tooltip placement="topLeft" title={sterile}>
					{sterile}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},

		/* ExpectedQty */
		{
			title: t.ExpectedQty,
			dataIndex: 'expectedQuantity',
			key: 'expected_quantity',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: expectedQuantity => (
				<Tooltip placement="topLeft" title={expectedQuantity}>
					<ColoredValue value={expectedQuantity} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.HardCommit,
			dataIndex: 'hardCommit',
			key: 'hard_commit',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: hardCommit => (
				<Tooltip placement="topLeft" title={hardCommit}>
					<ColoredValue value={hardCommit || 0} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* ExpectedValue */
		{
			title: t.ExpectedValue,
			dataIndex: 'expectedValue',
			key: 'expected_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: expectedValue => (
				<Tooltip placement="topLeft" title={expectedValue}>
					<ColoredValue value={expectedValue} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Blind Count Qty */
		{
			title: t.BlindCountQuantity,
			dataIndex: 'blindCountQuantity',
			key: 'blind_count_quantity',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: blindCountQuantity => (
				<Tooltip placement="topLeft" title={blindCountQuantity}>
					<ColoredValue value={blindCountQuantity || 0} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Blind Count Value */
		{
			title: t.BlindCountvalue,
			dataIndex: 'blindCountValue',
			key: 'blind_count_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: blindCountValue => (
				<Tooltip placement="topLeft" title={blindCountValue}>
					<ColoredValue
						value={parseFloat(blindCountValue || 0 * 1.0).toFixed(2)}
					/>
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Blind Count Variance */
		{
			title: t.BlindCountVariance,
			dataIndex: 'blindCountVariance',
			key: 'blind_count_variance',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: blindCountVariance => (
				<Tooltip placement="topLeft" title={blindCountVariance}>
					<ColoredValue value={blindCountVariance || 0} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Blind Count Variance Value */
		{
			title: t.BlindCountVarianceValue,
			dataIndex: 'blindCountVarianceValue',
			key: 'blind_count_variance_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: blindCountVarianceValue => (
				<Tooltip placement="topLeft" title={blindCountVarianceValue}>
					<ColoredValue
						value={parseFloat(blindCountVarianceValue || 0 * 1.0).toFixed(2)}
					/>
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* CountQty */
		{
			title: t.CountQty,
			dataIndex: 'actualQuantity',
			key: 'actual_quantity',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: actualQuantity => (
				<Tooltip placement="topLeft" title={actualQuantity}>
					<ColoredValue value={actualQuantity} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* CountValue */
		{
			title: t.CountValue,
			dataIndex: 'actualValue',
			key: 'actual_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: actualValue => (
				<Tooltip placement="topLeft" title={actualValue}>
					<ColoredValue value={actualValue} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* InitialVariance */
		{
			title: t.InitialVariance,
			dataIndex: 'initialVariance',
			key: 'initial_variance',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: initialVariance => (
				<Tooltip placement="topLeft" title={initialVariance}>
					<ColoredValue value={initialVariance} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* InitialVarianceValue */
		{
			title: t.InitialVarianceValue,
			dataIndex: 'initialVarianceValue',
			key: 'initial_variance_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: initialVarianceValue => (
				<Tooltip placement="topLeft" title={initialVarianceValue}>
					<ColoredValue value={initialVarianceValue} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* AdjustmentQty */
		{
			title: t.AdjustmentQty,
			dataIndex: 'adjustmentQuantity',
			key: 'adjustment_quantity',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: adjustmentQuantity => (
				<Tooltip placement="topLeft" title={adjustmentQuantity}>
					<ColoredValue value={adjustmentQuantity} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* FinalQty */
		{
			title: t.FinalQty,
			dataIndex: 'finalQuantity',
			key: 'final_quantity',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: finalQuantity => (
				<Tooltip placement="topLeft" title={finalQuantity}>
					<ColoredValue value={finalQuantity} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* FinalValue */
		{
			title: t.FinalValue,
			dataIndex: 'finalValue',
			key: 'final_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: finalValue => (
				<Tooltip placement="topLeft" title={finalValue}>
					<ColoredValue value={finalValue} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* FinalVariance */
		{
			title: t.FinalVariance,
			dataIndex: 'finalVariance',
			key: 'final_variance',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: finalVariance => (
				<Tooltip placement="topLeft" title={finalVariance}>
					<ColoredValue value={finalVariance} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* FinalVariance */
		{
			title: t.FinalVarianceValue,
			dataIndex: 'finalVarianceValue',
			key: 'final_variance_value',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: finalVarianceValue => (
				<Tooltip placement="topLeft" title={finalVarianceValue}>
					<ColoredValue value={finalVarianceValue} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* ReasonCode */
		{
			title: t.ReasonCode,
			dataIndex: 'reasonCode',
			key: 'reasonCode',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: reasonCode => (
				<Tooltip placement="topLeft" title={reasonCode}>
					{reasonCode}
				</Tooltip>
			),

			filters: franchiseSubFranchiseReasonCode?.reasonCodes?.map(reasonCode => {
				/* filter as a resonCode */
				const filter = { text: '', value: '' };
				filter.text = reasonCode.toUpperCase();
				filter.value = reasonCode;
				/* return filter value */
				return filter;
			}),
		},
		/* Reviewer */
		{
			title: t.Reviewer,
			dataIndex: 'reviewer',
			key: 'reviewer',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: reviewer => (
				<Tooltip placement="topLeft" title={reviewer}>
					{reviewer}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Notes */
		{
			title: t.CheckInNotes,
			dataIndex: 'notes',
			key: 'notes',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: notes => (
				<Tooltip placement="topLeft" title={notes}>
					<ColoredValue value={notes} />
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		/* Comments */
		{
			title: t.Comments,
			dataIndex: 'comments',
			key: 'comments',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: comments => (
				<Tooltip placement="topLeft" title={comments}>
					{comments}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
	];
	/* set variance analysis columns */
	const [columnOptions, setColumnOptions] = useState(varianceAnalysisColumns);
	const onApproveHandler = () => {
		getVarianceAnalysisWorkflowStatus(
			countInstance?.scheduledCountID,
			approveVarianceAnalysis,
			'approve',
			configurationsList?.reconConfig,
		);
	};
	const rejectionHandler = () => {
		getVarianceAnalysisWorkflowStatus(
			countInstance?.scheduledCountID,
			setShowReject,
			'reject',
			configurationsList?.reconConfig,
		);
	};
	const calculateConversionRate = currency => {
		switch (currency) {
			case 'gbp':
				return configurationsList.financial.conversionRate.eur.gbp;
			case 'usd':
				return configurationsList.financial.conversionRate.eur.usd;
			default:
		}
		return 1;
	};

	const convertToExtCurrency = currency => {
		let conversionRate = 1;
		conversionRate = calculateConversionRate(currency);
		setPrevCurrency(currency);

		if (!varianceAnalysis.varianceData) {
			message.warning('Variance Data does not exist');
			return;
		}
		if (!varianceAnalysis.varianceSum) {
			message.warning('Variance Summary Data does not exist');
			return;
		}
		if (currency === 'eur') {
			setVarianceData(varianceAnalysis.varianceData);
			setVarianceSummary(varianceAnalysis.varianceSum);
			return;
		}
		if (configLoaded) {
			let updatedVarianceData = JSON.parse(
				JSON.stringify(varianceAnalysis.varianceData),
			);

			updatedVarianceData = updatedVarianceData.map(variance => {
				variance.expectedValue = parseFloat(
					variance.expectedValue || 0 * conversionRate,
				).toFixed(2);
				variance.actualValue = parseFloat(
					variance.actualValue || 0 * conversionRate,
				).toFixed(2);
				variance.initialVarianceValue = parseFloat(
					variance.initialVarianceValue || 0 * conversionRate,
				).toFixed(2);
				variance.finalVarianceValue = parseFloat(
					variance.finalVarianceValue || 0 * conversionRate,
				).toFixed(2);
				variance.finalValue = parseFloat(
					variance.finalValue || 0 * conversionRate,
				).toFixed(2);
				variance.blindCountValue = parseFloat(
					variance.blindCountValue || 0 * conversionRate,
				).toFixed(2);
				variance.blindCountVarianceValue = parseFloat(
					variance.blindCountVarianceValue || 0 * conversionRate,
				).toFixed(2);

				return variance;
			});
			setVarianceData(updatedVarianceData);

			const updatedVarianceSummary = {
				expectedQuantity: varianceAnalysis.varianceSum.expectedQuantity,
				hardCommit: varianceAnalysis.varianceSum.hardCommit,
				actualQuantity: varianceAnalysis.varianceSum.actualQuantity,
				initialVariance: varianceAnalysis.varianceSum.initialVariance,
				expectedValue: varianceAnalysis.varianceSum.expectedValue,
				actualValue: varianceAnalysis.varianceSum.actualValue,
				initialVarianceValue: varianceAnalysis.varianceSum.initialVarianceValue,
				finalVarianceValue: varianceAnalysis.varianceSum.finalVarianceValue,
				finalVariance: varianceAnalysis.varianceSum.finalVariance,
				finalValue: varianceAnalysis.varianceSum.finalValue,
				adjustmentQuantity: varianceAnalysis.varianceSum.adjustmentQuantity,
				finalQuantity: varianceAnalysis.varianceSum.finalQuantity,
				blindCountQuantity: varianceAnalysis.varianceSum.blindCountQuantity,
				blindCountValue: varianceAnalysis.varianceSum.blindCountValue,
				blindCountVariance: varianceAnalysis.varianceSum.blindCountVariance,
				blindCountVarianceValue:
					varianceAnalysis.varianceSum.blindCountVarianceValue,
			};

			updatedVarianceSummary.expectedValue = parseFloat(
				updatedVarianceSummary.expectedValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.actualValue = parseFloat(
				updatedVarianceSummary.actualValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.initialVarianceValue = parseFloat(
				updatedVarianceSummary.initialVarianceValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.finalVarianceValue = parseFloat(
				updatedVarianceSummary.finalVarianceValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.finalValue = parseFloat(
				updatedVarianceSummary.finalValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.blindCountValue = parseFloat(
				updatedVarianceSummary.blindCountValue || 0 * conversionRate,
			).toFixed(2);
			updatedVarianceSummary.blindCountVarianceValue = parseFloat(
				updatedVarianceSummary.blindCountVarianceValue || 0 * conversionRate,
			).toFixed(2);
			setVarianceSummary(updatedVarianceSummary);
		} else {
			message.error('Error Loading conversion Rate configuration');
		}
	};
	useEffect(() => {
		if (
			varianceAnalysis?.varianceData?.length &&
			varianceAnalysis.varianceSum
		) {
			convertToExtCurrency(prevCurrency);
		}

		/* store schedule id  */
		store.dispatch(setCountId(countInstance?.scheduledCountID));
	}, [varianceAnalysis]);

	useEffect(() => {
		/* download initial variance analysis data */
		downloadInitialVariance(10, 'initial', params.uuid);
		getUpdateStatus(countInstance?.id);
	}, []);

	/* handle whe summary button clicked */
	const summaryHandler = () => {
		/* get variance analysis summary data */
		getVarianceAnalysisSummary(countInstance?.scheduledCountID);
		getVarianceAnalysisWorkflowStatus(countInstance?.scheduledCountID);
	};
	const visualizationModeHandler = e => {
		const mode = e.target.value;
		setVisualMode(mode);
		/* if the visualization mode is either PRODUCT OR DISCREPANCY_PRODUCT hide the columns */
		if (mode === PRODUCT || mode === DISCREPANCY_PRODUCT) {
			setColumnOptions(
				columnOptions.filter(
					item =>
						item.dataIndex !== 'expiryDate' &&
						item.dataIndex !== 'productLot' &&
						item.dataIndex !== 'reasonCode' &&
						item.dataIndex !== 'reviewer' &&
						item.dataIndex !== 'comments' &&
						item.dataIndex !== 'notes',
				),
			);
			/* open visualization dropdown */
			setVisualization(true);
		}
		/* if not set all variance analysis colum with out hiding columns */
		if (mode === DEFAULT_VISUALIZATION_MODE || mode === DISCREPANCY_LOT) {
			setColumnOptions(varianceAnalysisColumns);
			/* open visualization dropdown */
			setVisualization(false);
		}
		if (mode === DISCREPANCY_LOT) {
			setHideExportExcel(true);
		} else {
			setHideExportExcel(false);
		}
		/* switch with mode */
		setPaginate(1);
		return switchToVisualizationMode(
			params.uuid,
			searchQuery,
			mode,
			0,
			currentLimit,
		);
	};
	const handleTableChange = (pagination, filter, sorter) => {
		/* paginate with filter and search query */
		getNextPage(
			params.uuid,
			(pagination.current - 1) * pagination.pageSize,
			pagination.pageSize,
			searchQuery,
			sorter.columnKey,
			sorter.order,
			filter,
			advanceParam,
			visualMode,
		);
		setSorter({ column: sorter.columnKey, order: sorter.order });
		setCurrentLimit(pagination.pageSize);
		setPaginate(undefined);
	};

	useEffect(() => {
		if (varianceAnalysis.varianceData.length === 0)
			setVarianceData(varianceAnalysis.varianceData);
	}, [isFetching]);

	/* render */
	return (
		<>
			<div className="py-5 w-fit px-10 w-full border-2 rounded-lg">
				<div className="flex justify-between flex-wrap border-b-2 pb-4">
					{/* navigate to smart/count-instance/id */}
					<div
						type="button"
						className="float-left cursor-pointer"
						onClick={() =>
							history.replace(
								`/smart/count-instance/${countInstance?.scheduledCountID}`,
							)
						}>
						<ArrowBackIcon className="mr-2" />
						{/* BackToCountDetail */}
						<span className=" relative bottom-1 font-bold">
							{t.BackToCountDetail}
						</span>
					</div>
					<div className="sm:xs:block md:flex gap-2">
						{/* download initial variance button */}
						<div>
							<DownLoadInitVariance
								exportData={exportData}
								id={countInstance?.scheduledCountID}
								exportLoading={exportLoading}
							/>
						</div>
						{/* Reject button */}
						{webPermissions &&
						webPermissions.includes(
							userPermissions.VARIANCE_APPROVAL_FUNCTION,
						) ? (
							<div>
								<Button
									disabled={isDisableButton(
										countInstance?.variance_status,
										franchiseSubFranchiseReasonCode?.isReasonCodes,
									)}
									loading={workflowStatusLoading}
									onClick={rejectionHandler}
									className="border-jnj_red border-2 justify-center">
									{t.Reject}
								</Button>
							</div>
						) : null}
						{/* approve button */}
						{webPermissions &&
						webPermissions.includes(
							userPermissions.VARIANCE_APPROVAL_FUNCTION,
						) ? (
							<div>
								<Button
									disabled={isDisableButton(
										countInstance?.variance_status,
										franchiseSubFranchiseReasonCode?.isReasonCodes,
									)}
									loading={workflowStepLoading}
									onClick={onApproveHandler}
									className="border-jnj_red border-2">
									{t.Approve}
								</Button>
							</div>
						) : null}

						{/* summary button */}
						<div>
							<Button
								className="border-jnj_red border-2"
								loading={summaryLoading}
								onClick={() => summaryHandler()}>
								{t.Summary}
							</Button>
						</div>
					</div>
				</div>
				<div className="md:flex sm:xs:block gap-12 pt-6">
					<div>
						{/* Account Name */}
						<span className="font-bold mr-2">{t.AccountName}:</span>
						<span className="font-normal text-base">
							{countInstance?.cac_customer_name}
						</span>
					</div>
					<div>
						{/* Account Number */}
						<span className="font-bold mr-2">{t.AccountNumber}: </span>
						<span className="font-normal text-base">
							{countInstance?.cac}
						</span>{' '}
					</div>
					<div>
						<span className="font-bold mr-2">{t.ShipToName}: </span>
						<span className="font-normal text-base">
							{countInstance?.ship_data[0].ship_to_name}
						</span>
					</div>
					<div>
						{/* CountID */}
						<span className="font-bold mr-2">{t.CountID}: </span>
						<span className="font-normal text-base">
							{countInstance?.scheduledCountID}
						</span>
					</div>
					<div>
						{/* CSV Upload */}
						<div className="flex-col">
							<div>
								<span className="font-bold">File Upload Status:</span>
								{csvUploadError || `${csvUploadStatus?.update_batch_status} `}
							</div>
							<div>
								<span className="font-normal text-base">
									{csvUploadError || (
										<>
											<span className="font-bold">File Last Updated Time:</span>
											{csvUploadStatus?.batch_last_updated_time
												? moment(
														csvUploadStatus?.batch_last_updated_time,
														databaseDateFormat,
												  ).format(dateFormat)
												: csvUploadStatus?.batch_last_updated_time}
										</>
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Data table component */}
			<div>
				{errorMessage ? message.error(errorMessage) : null}
				{errorFranchiseSubFranchiseReasonCode
					? message.error(errorFranchiseSubFranchiseReasonCode)
					: null}

				<DataTable
					searchable
					searchData={e => {
						searchVarianceAnalysis(params.uuid, e);
						setSearchQuery(e);
					}}
					csvData={
						varianceData
							? varianceData.map(({ id, ...keepAttrs }) => keepAttrs)
							: null
					}
					exportHeaders={Array(
						varianceAnalysisColumns.map(column => column.title),
					)}
					showUpload={
						webPermissions &&
						webPermissions.includes(
							userPermissions.VARIANCE_ANALYSIS_MANAGEMENT,
						)
							? uploadLogic(
									VARIANCE_ANALYSIS,
									countInstance?.variance_status,
									null,
									loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
										? CLOSED
										: countInstance?.status,
									countInstance?.archive_year,
							  )
							: null
					}
					columnsOption={columnOptions.map(column => ({
						label: column.title,
						value: column.dataIndex,
					}))}
					paginate={paginate || undefined}
					isFetching={isFetching}
					handleTableChange={handleTableChange}
					resetData={() => {
						resetVarianceAnalysisData(params.uuid, '');
						setSearchQuery('');
					}}
					columnsInit={varianceAnalysisColumns.map(column =>
						VarianceColumnsList(column),
					)}
					/* Dummy Variance Analysis Data */
					data={varianceData}
					callerComponent="Variance Analysis"
					/* display visualization mode */
					visualizationMode
					/* display currency */
					displayCurrency={
						<DisplayCurrency
							convertToExtCurrency={currency => convertToExtCurrency(currency)}
							className="flex-auto"
						/>
					}
					/* display advance filter */
					advanceFilter
					showExportExcelForSAP={hideExportExcel}
					/* number of row per page */
					perPage={DEFAULT_LIMIT_START}
					/* total record */
					totalRecords={totalRecords}
					summaryData={varianceSummary}
					/* display summary */
					enableSummary={
						varianceAnalysis?.varianceSum
							? (data, summaryData, columnsOption, hiddenCols) =>
									varianceTableSummary(
										data,
										summaryData,
										columnsOption.map(col => col.dataIndex),
										hiddenCols,
										visualization,
									)
							: null
					}
					setVisualization={setVisualization}
					visualizationModeHandler={visualizationModeHandler}
					mappedData={varianceAnalysisDataMapped}
					advanceParams={e => setAdvancedParams(e)}
				/>
				{/* V.A summary component */}
				<VarianceAnalysisSummary
					showModal={showSummaryModal}
					setShowSummery={setShowSummery}
					reasonCodes={franchiseSubFranchiseReasonCode?.reasonCodes}
				/>
				{/* V.A Reject component */}
				<VarianceAnalysisReject
					showReject={showReject}
					setShowReject={setShowReject}
					approveVarianceAnalysis={approveVarianceAnalysis}
					id={countInstance?.scheduledCountID}
					workflowLoading={workflowStepLoading}
				/>
			</div>
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	webPermissions: selectWebPerms,
	varianceAnalysis: selectCurrentVarianceAnalysis,
	errorMessage: selectCurrentVarianceAnalysisError,
	isFetching: selectCurrentVarianceAnalysisIsSearching,
	totalRecords: selectCurrentVarianceAnalysisTotalRecords,
	exportLoading: selectIsInitialVarianceAnalysisExportLoading,
	exportData: selectInitialVarianceAnalysisExportData,
	summaryLoading: selectVarianceAnalysisSummaryLoaded,
	showSummaryModal: selectShowSummaryModal,
	franchiseSubFranchiseReasonCode: selectFranchiseSubFranchiseReasonCode,
	errorFranchiseSubFranchiseReasonCode:
		selectErrorFranchiseSubFranchiseReasonCode,
	countInstance: selectCountInstance,
	configLoaded: selectIsConfigurationLoaded,
	workflowStatus: selectWorkflowStatus,
	workflowStepLoading: selectWorkflowStepFetching,
	configurationsList: selectConfigList,
	workflowStatusLoading: selectWorkflowStatusFetching,
	countryCode: selectUserCountry,
	loggedInUserRoles: selectLoggedInUserRoles,
});

const mapDispatchToProps = dispatch => ({
	searchVarianceAnalysis: (countInstanceId, query) => {
		if (query.length > SEARCH_MIN_CHAR)
			dispatch(
				searchCurrentVarianceAnalysisStartAsync({
					countInstanceId,
					searchQuery: query,
				}),
			);
	},
	getNextPage: (
		countInstanceId,
		offset,
		limit,
		query,
		sortBy,
		sortOrder,
		filter,
		advanceParam,
		visualMode,
	) =>
		dispatch(
			searchCurrentVarianceAnalysisStartAsync({
				countInstanceId,
				searchQuery: query,
				offset,
				limit,
				sortBy,
				sortKey: sortOrder,
				filter,
				advancedFilter: advanceParam,
				visualizationMode: visualMode ?? DEFAULT_VISUALIZATION_MODE,
			}),
		),
	resetVarianceAnalysisData: (countInstanceID, query) =>
		dispatch(
			searchCurrentVarianceAnalysisStartAsync({
				countInstanceId: countInstanceID,
				searchQuery: query,
			}),
		),
	switchToVisualizationMode: (
		countInstanceID,
		searchQuery,
		mode,
		currentOffset,
		currentLimit,
	) =>
		dispatch(
			searchCurrentVarianceAnalysisStartAsync({
				countInstanceId: countInstanceID,
				searchQuery,
				offset: currentOffset,
				limit: currentLimit,
				visualizationMode: mode,
			}),
		),

	downloadInitialVariance: (totalRecord, type, id) =>
		dispatch(bulkExportVarianceAnalysisAsync(totalRecord, id, type)),

	approveVarianceAnalysis: (id, payload, callBack) =>
		dispatch(
			setVarianceAnalysisWorkflowStepStartAsync({
				scheduledCountId: id,
				payload,
				callBack,
			}),
		),
	getVarianceAnalysisWorkflowStatus: (id, cb, from, approvers) =>
		dispatch(
			getVarianceAnalysisWorkflowStartAsync({
				scheduledCountId: id,
				cb,
				from,
				approvers,
			}),
		),

	getVarianceAnalysisSummary: id =>
		dispatch(getVarianceAnalysisSummaryStartAsync(id)),
	setSorter: vaSorter => dispatch(setVarianceAnalysisSorter(vaSorter)),
	getUpdateStatus: id => dispatch(getCSVUploadStatusStartAsync(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(VarianceAnalysis);
