import {
	countryCodes,
	dateFormats,
	phoneNumberExts,
} from '../constants/country';

export const getCountryDateFormat = countryCode => {
	if (countryCode) {
		if (countryCode === countryCodes.US) {
			return dateFormats.US;
		}
		return dateFormats.DEFAULT;
	}

	return dateFormats.DEFAULT;
};

export const getCountryPhoneNumberFormat = countryCode => {
	if (countryCode) {
		if (countryCode === countryCodes.US) {
			return phoneNumberExts.US;
		}
		if (countryCode === countryCodes.DE || countryCode === countryCodes.UK) {
			return phoneNumberExts.EU;
		}
		return phoneNumberExts.DEFAULT;
	}
	return phoneNumberExts.DEFAULT;
};
