import { Spin } from 'antd';
import React from 'react';
import { ReactComponent as SortDownIcon } from '../assets/icons/sort-down-icon.svg';
import { ReactComponent as SortUpIcon } from '../assets/icons/sort-up-icon.svg';

const formatPhoneNumber = value => {
	if (!value) return value;

	const phoneNumber = value.replace(/[^\d]/g, '').toString();

	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6,
	)}-${phoneNumber.slice(6)}`;
};

export const mapUserRole = type => {
	switch (type) {
		case 'OPERATOR':
			return 'auditor';
		case 'AUDITOR':
			return 'auditor';
		case 'APPROVER':
			return 'approver';
		case 'SYSTEM_ADMIN':
			return 'system_admin';
		case 'CONSIGNMENT_ANALYST':
			return 'consignment_analyst';
		case 'CONSIGNMENT_LEAD':
			return 'consignment_lead';
		default:
			break;
	}
	return null;
};

export const showFilterIcon = column => {
	if (
		column.dataIndex === 'roles' ||
		column.dataIndex === 'phoneNumber' ||
		column.dataIndex === 'status' ||
		column.dataIndex === 'lastLogin'
	)
		return <div />;
	return (
		<div>
			{' '}
			<div className="h-2">
				<SortUpIcon />
			</div>
			<div>
				<SortDownIcon />
			</div>
		</div>
	);
};

export const searchSpinner = isSearching =>
	isSearching ? <Spin size="small" /> : null;

export const filterUserByUserStatus = filter => {
	if (!filter || filter.length === 2) return null;
	return filter[0];
};
export default formatPhoneNumber;
