import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { showCloseCountInstanceModal } from '../../redux/CountInstance/CountInstance.actions';
import { selectTranslations } from '../../redux/i18n/i18nSlice';

const { TextArea } = Input;

function CloseCountInstanceWarning({ handleConfirm, showCloseModal }) {
	const [closeReason, setCloseReason] = useState('');
	const [form] = Form.useForm();

	const t = useSelector(selectTranslations);
	const onFinish = () => {
		handleConfirm(closeReason);
	};
	return (
		<Modal
			width={600}
			maskClosable={false}
			open
			closable={false}
			footer={null}
			title={null}>
			<div className="p-5 ">
				<div className="text-jnj_black font-bold text-xl flex justify-center">
					Warning:
				</div>
				<div className="text-jnj_black text-lg flex justify-center">
					<div>
						You are about to close this Count Instance, once Closed you will not
						be able to re-open the Count Instance again. Proceed anyway?
					</div>
				</div>
				<div className="font-bold py-2">
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="closeReason"
							labelCol={{ span: 24 }}
							label="Reason to close : "
							rules={[
								{
									required: true,
									message: 'Please input the reason to close!',
								},
							]}>
							<TextArea
								showCount
								maxLength={250}
								onChange={e => setCloseReason(e.target.value)}
							/>
						</Form.Item>

						<div className="flex-1 flex-wrap  px-10">
							<div className="flex content-center">
								<Button
									onClick={showCloseModal}
									className="font-black text-lg"
									htmlType="button"
									style={{ width: '220px' }}>
									{t.Cancel}
								</Button>
								<Button
									type="primary"
									className="font-bold text-lg"
									htmlType="submit"
									style={{ width: '220px' }}>
									{t.Proceed}
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</Modal>
	);
}

const mapDispatchToProps = dispatch => ({
	showCloseModal: () => dispatch(showCloseCountInstanceModal()),
});

export default connect(null, mapDispatchToProps)(CloseCountInstanceWarning);
