import React from 'react';
import { ReactComponent as BlackRightArrowIcon } from '../../assets/icons/black-right-arrow-icon.svg';
import { ReactComponent as RedRightArrowIcon } from '../../assets/icons/red-right-arrow-icon.svg';
import { MAX_DECIMAL_LENGTH } from '../../constants';
import { ErrorModal } from '../errorMessage';

export const TabsKey = {
	optionOne: 1,
	optionTwo: 2,
	optionThree: 3,
	optionFour: 4,
	optionFive: 5,
};

Object.freeze(TabsKey);

/* active tab font size helper function */
export const ActiveTabFontSize = option => {
	if (option) return 'font-black text-jnj_red';
	return 'font-normal';
};
/* dynamic arrow color helper function */
export function ArrowIcon(option) {
	if (option)
		return <RedRightArrowIcon data-testid="red-right-arrow-svg-icon" />;
	return <BlackRightArrowIcon data-testid="black-right-arrow-svg-icon" />;
}
/* hide and show tabs helper function */
export const HideAndShowTab = option => {
	if (option) return 'block';
	return 'hidden';
};
/* return 0 if if the data is null */

export const nullCheck = data => {
	if (data) return data;
	return 0;
};

/* filters the country code from list of country codes */
export const filterCountry = (list, countryCodes) =>
	list?.filter(x => countryCodes.indexOf(x.countryName))[0].countryCode;

/* changes the string if it null and changes it to empty string */
export const nullCheckAndSetToString = item => {
	if (!item) return '';
	return item;
};

/* changes ship-to to standard format value */
export const setToDefaultLGD = item => {
	if (!item) return 'cac';
	if (item === 'shipTo') return 'ship-to';
	return item;
};

/* set the value to default variance analysis value */
export const setToDefaultVA = item => {
	if (!item) return 'item+Lot';
	return item;
};
/* set the value to v-itemLot value */
export const setToDefaultVL = item => {
	if (!item) return 'v-itemLot';
	return item;
};

/* checkes if the writeoff treshold value is valid */
export const isWriteOffThresholdValid = values => {
	if (
		+values.writeOffThresholdL2 <= +values.writeOffThresholdL1 &&
		+values.writeOffThresholdL2 !== 0
	) {
		ErrorModal('Threshold L2 should not be less than or equal to L1');
		return 1;
	}

	if (
		+values.writeOffThresholdL3 <= +values.writeOffThresholdL1 &&
		+values.writeOffThresholdL3 !== 0
	) {
		ErrorModal('Threshold L3 should not be less than or equal to L1');
		return 1;
	}
	if (
		+values.writeOffThresholdL3 <= +values.writeOffThresholdL2 &&
		+values.writeOffThresholdL3 !== 0
	) {
		ErrorModal('Threshold L3 should not be less than or equal to L2');
		return 1;
	}
	return 0;
};

/* checkes decimal numbers with precision four digits */
export const checkDecimalNumber = value => {
	const stringValue = String(value);
	if (
		stringValue.includes('.') &&
		stringValue.split('.')[1].length > MAX_DECIMAL_LENGTH
	) {
		throw new Error('Invalid decimal');
	}
	return Promise.resolve();
};
