import { createSlice } from '@reduxjs/toolkit';
import cn from '../../translations/cn.json';
import en from '../../translations/en.json';
import fr from '../../translations/fr.json';

const initialState = {
	lang: 'en',
	translations: { en, fr, cn },
};

export const i18nSlice = createSlice({
	name: 'i18n',
	initialState,
	reducers: {
		setLang: (state, action) => {
			// eslint-disable-next-line no-param-reassign
			state.lang = action.payload;
		},
	},
});

export const { setLang } = i18nSlice.actions;

export const selectTranslations = state =>
	state.i18n.translations[state.i18n.lang];

export default i18nSlice.reducer;
