/* eslint-disable arrow-body-style */

import CountInstanceService from '../../../services/CountInstanceService';
import { ErrorMessage } from '../../../utils/errorMessage';
import SuccessMessage from '../../../utils/successMessage';
import { showEditCountInstanceModal } from '../CountInstanceDetails.actions';
import CountInstanceSingleActionTypes from './CountInstanceSingle.types';

const countInstanceService = new CountInstanceService();

export const fetchCountInstanceStart = () => ({
	type: CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_START,
});

export const fetchCountInstanceSuccess = countInstanceMap => ({
	type: CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_SUCCESS,
	payload: countInstanceMap,
});

export const fetchCountInstanceFailure = errorMessage => ({
	type: CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const fetchCountInstanceStartAsync = id => {
	return dispatch => {
		const countInstanceData = countInstanceService.getCountInstanceFactory(id);
		dispatch(fetchCountInstanceStart());
		countInstanceData
			.then(countInstanceMap => {
				dispatch(fetchCountInstanceSuccess(countInstanceMap));
			})
			.catch(error => dispatch(fetchCountInstanceFailure(error.message)));
	};
};

// Update Count Instance
export const updateCountInstanceStart = () => ({
	type: CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_START,
});

export const updateCountInstanceFailure = errorMessage => ({
	type: CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const updateCountInstanceSuccess = () => ({
	type: CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_SUCCESS,
});

export const updateCountInstanceStartAsync = (data, countId) => dispatch => {
	const countInstanceResponse = countInstanceService.updateCountInstanceFactory(
		data,
		countId,
	);
	dispatch(updateCountInstanceStart());
	countInstanceResponse
		.then(countInstanceMap => {
			SuccessMessage(countInstanceMap.message);
			dispatch(showEditCountInstanceModal());
			dispatch(updateCountInstanceSuccess());
			dispatch(fetchCountInstanceStartAsync(countId));
		})
		.catch(error => {
			dispatch(updateCountInstanceFailure(error.message));
			ErrorMessage();
		});
};
