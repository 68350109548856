import ErpCountActionTypes from '../ErpCount.types';

const INITIAL_STATE = {
	isErpFetching: false,
	errorMessageErp: null,
	scheduledCountErp: null,
	totalCount: null,
};

const erpCountGetReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
	switch (type) {
		case ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_START:
			return {
				...state,
				isErpFetching: true,
			};
		case ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isErpFetching: false,
				scheduledCountErp: payload.result,
				totalCount: payload.totalCount,
			};
		case ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isErpFetching: false,
				errorMessageErp: payload,
			};
		default:
			return state;
	}
};

export default erpCountGetReducer;
