import { Form, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { ReactComponent as SelectIcon } from '../../../assets/icons/export-down-icon.svg';
/* Grid Default Grouping */
function GridDefaultGrouping() {
	return (
		<div className="gap-2">
			{/* Location Grid Default Grouping */}
			<p className=" text-base font-bold pb-2">
				Location Grid Default Grouping:
			</p>
			<Form.Item name="locationGridDefault">
				<Select suffixIcon={<SelectIcon />}>
					<Select.Option value="cac">CAC</Select.Option>
					<Select.Option value="ship-to">Ship To</Select.Option>
				</Select>
			</Form.Item>
			<div className="flex gap-10">
				<Form.Item name="franchiseBreakdown" valuePropName="checked">
					{/* Franchise Breakdown check box */}
					<Checkbox>
						<div className="text-base font-medium">Franchise Breakdown</div>
					</Checkbox>
				</Form.Item>
				<Form.Item name="combineDeputy" valuePropName="checked">
					{/* Combine Deputy/Synths Spine check box */}
					<Checkbox>
						<div className="text-base font-medium">
							Combine Deputy/Synths Spine
						</div>
					</Checkbox>
				</Form.Item>
			</div>
			{/* Variance Analysis Grid Default Grouping */}
			<p className=" text-base font-bold pb-2">
				Variance Analysis Grid Default Grouping:
			</p>
			<Form.Item name="varianceAnalysis">
				{/* Variance Analysis options */}
				<Select suffixIcon={<SelectIcon />}>
					<Select.Option value="item+Lot">Item+Lot</Select.Option>
					<Select.Option value="item">Item</Select.Option>
				</Select>
			</Form.Item>
			{/* Variance Log Grid Default Grouping */}
			<p className=" text-base font-bold pb-2">
				Variance Log Grid Default Grouping:
			</p>
			<Form.Item name="varianceLog">
				{/* Variance Log options */}
				<Select suffixIcon={<SelectIcon />}>
					<Select.Option value="v-itemLot">Item+Lot</Select.Option>
					<Select.Option value="v-item">Item</Select.Option>
					<Select.Option value="dType">Discrepancy type</Select.Option>
				</Select>
			</Form.Item>
		</div>
	);
}
export default GridDefaultGrouping;
