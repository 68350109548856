import CountInstanceSingleActionTypes from './CountInstanceSingle.types';

const INITIAL_STATE = {
	isCountInstanceFetching: false,
	errorCountInstance: null,
	countInstance: null,
	isCountInstanceUpdating: false,
};

const countInstanceSingleReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		// Single Count Instance action dispatch
		case CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_START:
			return {
				...state,
				isCountInstanceFetching: true,
			};
		case CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isCountInstanceFetching: false,
				countInstance: payload,
			};
		case CountInstanceSingleActionTypes.FETCH_SINGLE_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isCountInstanceFetching: false,
				errorCountInstance: payload,
			};
		// Update Count Instance
		case CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_START:
			return {
				...state,
				isCountInstanceUpdating: true,
			};
		case CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_FAILURE:
		case CountInstanceSingleActionTypes.UPDATE_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isCountInstanceUpdating: false,
			};
		default:
			return state;
	}
};

export default countInstanceSingleReducer;
