import { Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

function YearSelection({onValueChange, year}){
  const yearsList = () => {
    const start = new Date().getFullYear();
    const end = 2021;
    return Array.from(
  { length: start - end },
  (_, i) => start - i
).map(y => ({label: y, value: y}));
  };
  return (<Select
      value={year}
      onChange={onValueChange}
      options={yearsList()}
    />);
}
const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = () => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(YearSelection);
