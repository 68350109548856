/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import WithSpinner from '../../../components/WithSpinner/WithSpinner.HOC';
import { userRoles } from '../../../constants';
import { searchUsersStartAsync } from '../../../redux/User/User.actions';
import {
	selectLoggedInUserRoles,
	selectUserCountry,
	selectUsersListLoaded,
} from '../../../redux/User/User.selector';
import UserList from './UserList.component';

const UserListWithSpinner = WithSpinner(UserList);

function UserListContainer({
	loggedInUserRoles,
	countryCode,
	fetchUsersStart,
	isFetching,
	...otherProps
}) {
	useEffect(() => {
		fetchUsersStart(
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
		);
	}, []);
	return <UserListWithSpinner isLoaded={isFetching} {...otherProps} />;
}

const mapStateToProps = createStructuredSelector({
	isFetching: selectUsersListLoaded,
	countryCode: selectUserCountry,
	loggedInUserRoles: selectLoggedInUserRoles,
});
const mapDispatchToProps = dispatch => ({
	fetchUsersStart: country => dispatch(searchUsersStartAsync(country)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
