import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function RedirectUrl({ children }) {
	const location = useLocation();
	useEffect(() => {
		/* if the user coming from external link/mobile link,
		store the pathname, check successfull login, then redirect
		into the desired page */
		if (location.pathname) {
			localStorage?.setItem(`redirect-url`, JSON.stringify(location.pathname));
		}
	}, []);
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

export default RedirectUrl;
