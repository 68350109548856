import { message, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	AUDIT_COUNT,
	CREATED,
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	erpAuditCountDataMapped,
	IN_PROGRESS,
	SEARCH_MIN_CHAR,
	userRoles,
} from '../../constants';
import {
	searchAuditCountInstanceStartAsync,
	signatureDownloadAsync,
} from '../../redux/CountInstanceDetails/AuditCount/AuditCount.action';
import {
	selectAuditCountInstance,
	selectAuditCountInstanceError,
	selectIsAuditCountFetching,
	selectisSignatureFileDownloading,
	selectTotalAuditCount,
} from '../../redux/CountInstanceDetails/AuditCount/AuditCount.selector';
import {
	selectCountInstance,
	selectCountInstanceError,
} from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { selectLoggedInUserRoles } from '../../redux/User/User.selector';
import { uploadLogic } from '../../utils/Upload.utils';
import { checkArrayNull } from '../../utils/Verify.utils';
import DataTable from '../DataTable/DataTable.component';

function AuditCountInstance({
	auditCountInstance,
	errorMessage,
	isFetching,
	searchAuditCount,
	getNextPage,
	resetAuditData,
	totalCount,
	downloadSignature,
	isSignatureDownloading,
	countInstance,
	errorCountInstanceMessage,
	loggedInUserRoles,
}) {
	const t = useSelector(selectTranslations);
	// the initial value of search query
	const [searchQuery, setSearchQuery] = useState('');
	// the total number of records initially when fetch
	const [initialTotalCount, setInitialTotalCount] = useState();

	// List of Fields for audit count columns
	const auditCountColumns = [
		{
			title: t.ProductId,
			dataIndex: 'itemNumber',
			key: 'itemNumberAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemNumber => (
				<Tooltip placement="topLeft" title={itemNumber}>
					{itemNumber}
				</Tooltip>
			),
		},
		{
			title: t.ProductDescription,
			dataIndex: 'itemDescription',
			key: 'itemDescriptionAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemDescription => (
				<Tooltip placement="topLeft" title={itemDescription}>
					{itemDescription}
				</Tooltip>
			),
		},
		{
			title: t.LotId,
			dataIndex: 'itemLotNum',
			key: 'itemLotNumAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemLotNum => (
				<Tooltip placement="topLeft" title={itemLotNum}>
					{itemLotNum}
				</Tooltip>
			),
		},
		{
			title: t.ExpiryDate,
			dataIndex: 'itemLotNumExpry',
			key: 'itemLotNumExpryAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemLotNumExpry => (
				<Tooltip placement="topLeft" title={itemLotNumExpry}>
					{itemLotNumExpry}
				</Tooltip>
			),
		},
		{
			title: t.Quantity,
			dataIndex: 'itemCount',
			key: 'itemCountAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: itemCount => (
				<Tooltip placement="topLeft" title={itemCount}>
					{itemCount}
				</Tooltip>
			),
		},
		{
			title: t.Franchise,
			dataIndex: 'franchise',
			key: 'franchiseAudit',
			ellipsis: {
				showTitle: false,
			},
			width: 100,
			render: franchise => (
				<Tooltip placement="topLeft" title={franchise}>
					{franchise}
				</Tooltip>
			),
		},
	];
	const handleTableChange = pagination => {
		getNextPage(
			(pagination.current - 1) * pagination.pageSize,
			pagination.pageSize,
			searchQuery,
		);
	};
	// Updates total number record count based on search query
	useEffect(() => {
		if (searchQuery.length === 0) setInitialTotalCount(totalCount);
	});
	return (
		<>
			{errorMessage ? message.error(errorMessage) : null}
			{errorCountInstanceMessage
				? message.error(errorCountInstanceMessage)
				: null}
			<DataTable
				totalRecords={totalCount}
				mappedData={erpAuditCountDataMapped}
				columnsInit={auditCountColumns.map(column =>
					column.title
						? {
								...column,
								title: (
									<div className="flex font-bold  justify-between">
										<div className="w-4/5 whitespace-normal">
											{column.title}
										</div>
									</div>
								),
						  }
						: column,
				)}
				resetData={() => {
					// reset audit data when the search query is cleared
					resetAuditData('', countInstance?.archive_year);
					setSearchQuery('');
				}}
				data={auditCountInstance || null}
				searchData={e => {
					//
					searchAuditCount(e, countInstance?.archive_year);
					setSearchQuery(e);
				}}
				perPage={DEFAULT_LIMIT_START}
				showUpload={uploadLogic(
					AUDIT_COUNT,
					loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
						? null
						: countInstance && countInstance.status,
					auditCountInstance && initialTotalCount,
					null,
					countInstance?.archive_year,
				)}
				isSignatureDownloading={isSignatureDownloading}
				signatureDownload={() => {
					downloadSignature();
				}}
				signatureDownloadDisabled={
					!!(
						countInstance?.status === IN_PROGRESS ||
						countInstance?.status === CREATED
					)
				}
				title={
					<span className="flex justify-between">
						<span>
							<span style={{ fontWeight: 'bold' }}>
								{t.AuditCount}-{t.LastGenerated}
							</span>
							<span>
								:
								{countInstance && countInstance.actualEndDate
									? moment(countInstance.actualEndDate).format('lll')
									: ''}
							</span>
						</span>
					</span>
				}
				exportHeaders={Array(auditCountColumns.map(column => column.title))}
				// handles the pagination
				handleTableChange={handleTableChange}
				callerComponent="Audit Count"
				csvData={
					checkArrayNull(auditCountInstance)
						? auditCountInstance.map(({ id, ...keepAttrs }) => keepAttrs)
						: null
				}
				isFetching={isFetching}
				year={countInstance?.archive_year}
			/>
		</>
	);
}
// maps the selector to props
const mapStateToProps = createStructuredSelector({
	auditCountInstance: selectAuditCountInstance,
	errorMessage: selectAuditCountInstanceError,
	isFetching: selectIsAuditCountFetching,
	totalCount: selectTotalAuditCount,
	countInstance: selectCountInstance,
	errorCountInstanceMessage: selectCountInstanceError,
	isSignatureDownloading: selectisSignatureFileDownloading,
	loggedInUserRoles: selectLoggedInUserRoles,
});
// maps dispatch function to props
const mapDispatchToProps = (dispatch, props) => ({
	// search audit count
	searchAuditCount: (query, year) => {
		if (query.length > SEARCH_MIN_CHAR)
			dispatch(
				searchAuditCountInstanceStartAsync(
					props.match.params.uuid,
					DEFAULT_OFFSET_START,
					DEFAULT_LIMIT_START,
					year,
					query,
				),
			);
	},
	// get next page data for pagination
	getNextPage: (offset, limit, query, year) => {
		if (query.length < SEARCH_MIN_CHAR)
			dispatch(
				searchAuditCountInstanceStartAsync(
					props.match.params.uuid,
					offset,
					limit,
					year,
					query,
				),
			);
		// search audit count instance
		else
			dispatch(
				searchAuditCountInstanceStartAsync(
					props.match.params.uuid,
					offset,
					limit,
					year,
					'',
				),
			);
	},
	downloadSignature: () =>
		dispatch(signatureDownloadAsync(props.match.params.uuid)),
	resetAuditData: (query, year) =>
		dispatch(
			searchAuditCountInstanceStartAsync(
				props.match.params.uuid,
				DEFAULT_OFFSET_START,
				DEFAULT_LIMIT_START,
				year,
				query,
			),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditCountInstance);
