import { createSelector } from 'reselect';

const selectGetErpCount = state => state.getErpCount;
const selectExportErpCount = state => state.exportErpCount;
const selectGetErpDetail = state => state.getErpCountDetail;

// ERP Count Selectors
export const selectErpCountInstance = createSelector(
	[selectGetErpCount],
	getErpCount => getErpCount.scheduledCountErp,
);
export const selectErpCountInstanceError = createSelector(
	[selectGetErpCount],
	getErpCount => getErpCount.errorMessageErp,
);
export const selectIsErpCountFetching = createSelector(
	[selectGetErpCount],
	getErpCount => getErpCount.isErpFetching,
);

export const selectTotalErpCount = createSelector(
	[selectGetErpCount],
	getErpCount => getErpCount.totalCount,
);

export const selectIsErpCountInstanceLoaded = createSelector(
	[selectGetErpCount],
	getErpCount => !!getErpCount.scheduledCountErp,
);

export const selectErpCountDetail = createSelector(
	[selectGetErpDetail],
	getErpCountDetail => getErpCountDetail.detailErpData,
);
// bulk export erp count selector

export const selectErpBulkExportRecord = createSelector(
	[selectExportErpCount],
	exportErpCount => exportErpCount.bulkErpCountInstanceData,
);
export const selectErpBulkExportFetching = createSelector(
	[selectExportErpCount],
	exportErpCount => exportErpCount.isErpBulkRequestFetching,
);
