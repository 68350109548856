/* eslint-disable react/jsx-no-comment-textnodes */
import { Button, Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Info } from '../../assets/icons/questionMark-icon.svg';
import { databaseDateFormat } from '../../constants/country';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';

function LocationCreateForm(props) {
	// prop spread from input
	const { visible, onCancel, onCreate, loading, record, onUpdate, canUpdate } =
		props;
	const [form] = Form.useForm();
	// Date format from db to logged in user date format
	const dateFormat = getCountryDateFormat(
		useSelector(state => state?.user?.loggedInUser?.country),
	);

	const handleSubmit = () => {
		form.validateFields().then(values => {
			if (record) {
				onUpdate(values, record.id);
			} else {
				onCreate(values);
			}
		});
	};

	// set the modal visible when prop change
	useEffect(() => {
		if (!visible) {
			return;
		}
		form.resetFields();
	}, [visible, form]);

	// set the fields from API
	useEffect(() => {
		form.setFieldsValue({
			inventoryContribution: record?.inventoryContribution,
			lastCountAccuracy: record?.lastCountAccuracy,
			secondLastCountAccuracy: record?.secondLastCountAccuracy,
			calculatedRiskRating: record?.calculatedRiskRating,
			inventoryTurnRate: record?.inventoryTurnRate,
			assignedRiskRating: record?.assignedRiskRating,
			expectedCountNumber: record?.expectedCountNumber,
			rationaleForDeviation: record?.rationaleForDeviation,
			notes: record?.notes,
		});
	}, [record, form]);
	return (
		<div>
			<Modal
				footer={null}
				onCancel={onCancel}
				width={1093}
				open={visible}
				forceRender
				okButtonProps={{ loading }}>
				<Form form={form} disabled={!canUpdate}>
					<div className="flex">
						<div
							className="p-10"
							style={{ width: '403px', background: '#F4F4F4' }}>
							<div className="text-jnj_black">
								<div className="text-lg font-black flex gap-2 items-center pb-6 ">
									<div>Location Data</div>
									<div>
										<Info />
									</div>
								</div>
								<div className="border-t-2 pb-4 " />
								<div className="text-base font-black">
									<div className="pb-4 flex gap-2">
										<div>CAC Name:</div>
										<div className="text-base font-normal">{` ${record?.cac}`}</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Ship To name:</div>
										<div className="text-base font-normal">{` ${record?.shipTo}`}</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Subfranchise:</div>
										<div className="text-base font-normal">{` ${record?.franchiseCode}`}</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Branch:</div>
										<div className="text-base font-normal">
											{` ${record?.branch}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Total Consigned Value:</div>
										<div className="text-base font-normal">
											{` ${record?.totalConsignedValue}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Actual Count Number:</div>
										<div className="text-base font-normal">
											{` ${record?.actualCountNumber}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Location Status:</div>
										<div className="text-base font-normal">
											{` ${record?.locationStatus}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Last Count Date:</div>
										<div className="text-base font-normal">
											{` ${
												record?.lastCountDate &&
												record?.lastCountDate instanceof Date
													? moment(
															record?.lastCountDate,
															databaseDateFormat,
													  ).format(dateFormat)
													: ''
											}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Planned Count Month:</div>
										<div className="text-base font-normal">
											{` ${
												record?.plannedCountMonth &&
												record?.plannedCountMonth instanceof Date
													? moment(
															record?.plannedCountMonth,
															databaseDateFormat,
													  ).format(dateFormat)
													: ''
											}`}
										</div>
									</div>
									<div className="pb-4 flex gap-2">
										<div>Status Risk Assessment:</div>
										<div className="text-base font-normal">
											{` ${
												record?.statusRiskAssessment
													? record?.statusRiskAssessment
													: ''
											}`}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className="p-10"
							style={{
								background: '#ffff',
								width: '650px',
							}}>
							<div className="flex gap-2">
								<div className="text-lg font-black flex gap-2 content-center pb-6">
									Update Location Data
								</div>
								<div>
									<Info />
								</div>
							</div>
							<div className="border-t-2 pb-4 " />
							<div className="text-jnj_black text-base font-bold flex gap-1 justify-between">
								<div>
									<div className="flex">
										<div>Inventory Contribution:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="inventoryContribution">
											<Input maxLength={50} />
										</Form.Item>
									</div>
								</div>
								<div>
									<div className="flex">
										<div>Accuracy Last Count (%):</div>
									</div>
									<div>
										{' '}
										<Form.Item name="lastCountAccuracy">
											<Input maxLength={50} />
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="text-jnj_black text-base font-bold flex gap-1 justify-between">
								<div>
									<div className="flex">
										<div>Accuracy Second Last Count(%):</div>
									</div>
									<div>
										{' '}
										<Form.Item name="secondLastCountAccuracy">
											<Input maxLength={50} />
										</Form.Item>
									</div>
								</div>
								<div>
									<div className="flex">
										<div>Calculated Risk Rating:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="calculatedRiskRating">
											<Input />
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="text-jnj_black text-base font-bold flex gap-1 justify-between">
								<div>
									<div className="flex">
										<div>Inventory Turn Rate:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="inventoryTurnRate">
											<Input />
										</Form.Item>
									</div>
								</div>
								<div>
									<div className="flex">
										<div>Assigned Risk Rating:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="assignedRiskRating">
											<Input />
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="text-jnj_black text-base font-bold flex gap-1 justify-between">
								<div>
									<div className="flex">
										<div>Expected Count Number:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="expectedCountNumber">
											<Input />
										</Form.Item>
									</div>
								</div>
							</div>

							<div className="text-jnj_black text-base font-bold flex gap-1 justify-between">
								<div>
									<div className="flex">
										<div>Rationale for Deviation:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="rationaleForDeviation">
											<TextArea />
										</Form.Item>
									</div>
								</div>
								<div>
									<div className="flex">
										<div>Notes:</div>
									</div>
									<div>
										{' '}
										<Form.Item name="notes">
											<TextArea />
										</Form.Item>
									</div>
								</div>
							</div>

							<div className="flex gap-1 justify-between">
								<div>
									<Button onClick={onCancel} style={{ width: '224px' }}>
										Cancel
									</Button>
								</div>
								<div>
									<Button
										style={{ width: '224px' }}
										onClick={handleSubmit}
										type="primary">
										Update Location
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default LocationCreateForm;
