/* eslint-disable react/react-in-jsx-scope */
import { CloseOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import React from 'react';
import errorSvg from '../assets/icons/error.svg';
/* Error Message Modal */
export const ErrorMessage = (errorMessage = null) =>
	Modal.success({
		content: (
			<div className="pl-10 pr-10 pb-10">
				<Button onClick={() => Modal.destroyAll()}type="ghost" shape='circle' icon={<CloseOutlined />} style={{float: 'right', marginTop: '-100px', marginRight: '-20px', color: '#000000'}}/>
				{/* error message */}
				<div className="text-jnj_black font-normal text-lg pb-4 ">
					Something is not done correctly.
				</div>
				<div className="text-jnj_black font-normal text-lg flex ">
					<div>
						{errorMessage}
						<div className="py-5">
							<h1 className="text-jnj_red">
								please contact your market Super user
							</h1>
						</div>
					</div>
				</div>
			</div>
		),
		icon: (
			<div className="pt-10 flex pb-4 justify-center">
				<div>
					{/* error icon */}
					<img src={errorSvg} alt="React Logo" />
				</div>
			</div>
		),
		footer: null,
	});
/* error modal */
export const ErrorModal = messageToDisplay =>
	message.error({
		content: (
			<div className="pl-10 pr-10 pb-10">
				<Button onClick={() => message.destroy()}type="ghost" shape='circle' icon={<CloseOutlined />} style={{float: 'right', marginTop: '-100px', marginRight: '-20px', color: '#000000'}}/>
				<div
					className="text-jnj_black font-bold text-lg "
					style={{ width: '500px' }}>
					{/* message to Display */}
					{messageToDisplay}
				</div>
			</div>
		),
		style: {
			marginTop: '20vh',
		},
		icon: (
			<div className="pt-10 flex pb-4 justify-center">
				<div>
					{/* error icon */}
					<img src={errorSvg} alt="React Logo" />
				</div>
			</div>
		),
	});
