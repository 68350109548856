import CountInstanceActionTypes from '../CountInstance.types';

const INITIAL_STATE = {
	successMessage: null,
	errorMessage: null,
	isFetching: false,
	showCloseCountInstanceModal: false,
};

const deleteCountInstanceReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case CountInstanceActionTypes.SHOW_CLOSE_COUNT_INSTANCE_MODAL:
			return {
				...state,
				showCloseCountInstanceModal: !state.showCloseCountInstanceModal,
			};
		case CountInstanceActionTypes.DELETE_COUNT_INSTANCE_START:
			return {
				...state,
				isFetching: true,
			};
		case CountInstanceActionTypes.DELETE_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isFetching: false,
				successMessage: payload,
			};
		case CountInstanceActionTypes.DELETE_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default deleteCountInstanceReducer;
