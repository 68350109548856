import CountInstanceActionTypes from '../CountInstance.types';

const INITIAL_STATE = {
	isBulkRequestFetching: false,
	bulkCountInstanceData: null,
	errorBulkMessage: null,
};

const exportCountInstanceReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_START:
			return {
				...state,
				isBulkRequestFetching: true,
			};
		case CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_SUCCESS:
			return {
				...state,
				isBulkRequestFetching: false,
				bulkCountInstanceData: payload.result,
			};
		case CountInstanceActionTypes.BULK_EXPORT_COUNT_INSTANCE_FAILURE:
			return {
				...state,
				isBulkRequestFetching: false,
				errorBulkMessage: payload,
			};
		default:
			return state;
	}
};

export default exportCountInstanceReducer;
