const AuditCountActionTypes = {
	SEARCH_AUDIT_COUNT_INSTANCE_START: 'SEARCH_AUDIT_COUNT_INSTANCE_START',
	SEARCH_AUDIT_COUNT_INSTANCE_SUCCESS: 'SEARCH_AUDIT_COUNT_INSTANCE_SUCCESS',
	SEARCH_AUDIT_COUNT_INSTANCE_FAILURE: 'SEARCH_AUDIT_COUNT_INSTANCE_FAILURE',
	BULK_EXPORT_AUDIT_COUNT_START: 'BULK_EXPORT_AUDIT_COUNT_START',
	BULK_EXPORT_AUDIT_COUNT_SUCCESS: 'BULK_EXPORT_AUDIT_COUNT_SUCCESS',
	BULK_EXPORT_AUDIT_COUNT_FAILURE: 'BULK_EXPORT_AUDIT_COUNT_FAILURE',
	CSV_UPLOAD_AUDIT_COUNT_START: 'CSV_UPLOAD_AUDIT_COUNT_START',
	CSV_UPLOAD_AUDIT_COUNT_SUCCESS: 'CSV_UPLOAD_AUDIT_COUNT_SUCCESS',
	CSV_UPLOAD_AUDIT_COUNT_FAILURE: 'CSV_UPLOAD_AUDIT_COUNT_FAILURE',
	SIGNATURE_DOWNLOAD_START: 'SIGNATURE_DOWNLOAD_START',
	SIGNATURE_DOWNLOAD_SUCCESS: 'SIGNATURE_DOWNLOAD_SUCCESS',
	SIGNATURE_DOWNLOAD_FAILURE: 'SIGNATURE_DOWNLOAD_FAILURE',
};

export default AuditCountActionTypes;
