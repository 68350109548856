import LocationActionTypes from './Location.types';

const INITIAL_STATE = {
	locations: null,
	errorMessage: undefined,
	isFetching: false,
	scheduledCountStatus: '',
	loading: false,
	isSearchActive: {
		active: false,
		keyword: '',
		field: '',
		order: '',
	},
	totalNumberOfRecord: 0,
	filters: null,
	selectedLocationFilters: null,
	errorFilterMessage: undefined,
};

const locationGetReducer = (state = INITIAL_STATE, { type, payload } = {}) => {
	switch (type) {
		case LocationActionTypes.UPDATE_LOCATION_START:
		case LocationActionTypes.GET_LOCATION_FILTERS_START:
		case LocationActionTypes.SEARCH_LOCATION_START:
			return {
				...state,
				isFetching: true,
			};
		case LocationActionTypes.SEARCH_LOCATION_SUCCESS:
			return {
				...state,
				isFetching: false,
				locations: payload.searchedData,
				totalNumberOfRecord: payload.searchedCount,
			};
		case LocationActionTypes.UPDATE_LOCATION_SUCCESS:
			return {
				...state,
				isFetching: false,
				locations: state.locations.map(item => {
					if (item.id === payload.updatedLocation[0].id) {
						return payload.updatedLocation[0];
					}
					return item;
				}),
			};
		case LocationActionTypes.SEARCH_LOCATION_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: payload,
			};
		case LocationActionTypes.IS_SEARCH_ACTIVE:
			return {
				...state,
				isSearchActive: payload,
			};
		case LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_START:
			return {
				...state,
				loading: true,
			};
		case LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				scheduledCountStatus: payload.countStatus,
			};
		case LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_FAILURE:
			return {
				...state,
				loading: false,
				errorMessage: payload,
			};
		case LocationActionTypes.GET_LOCATION_FILTERS_SUCCESS:
			return {
				...state,
				isFetching: false,
				filters: payload,
			};
		case LocationActionTypes.GET_LOCATION_FILTERS_FAILURE:
			return {
				...state,
				isFetching: false,
				errorFilterMessage: payload,
			};
		case LocationActionTypes.SET_LOCATION_FILTERS:
			return {
				...state,
				selectedLocationFilters: payload,
			};
		default:
			return state;
	}
};

export default locationGetReducer;
