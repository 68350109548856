/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, message } from 'antd';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as ArrowBackIcon } from '../../assets/icons/arrow-back-icon.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon-white.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow-icon.svg';
import { ReactComponent as SendIcon } from '../../assets/icons/send-icon.svg';
import {
	AUDIT_COMPLETED,
	CANCELED,
	CLOSED,
	RECONCILIATION_IN_PROGRESS,
	userRoles,
} from '../../constants';
import { databaseDateFormat } from '../../constants/country';
import {
	closeCountInstanceAsync,
	showCloseCountInstanceModal,
	updateVarianceAnalysisAsync,
} from '../../redux/CountInstance/CountInstance.actions';
import { selectShowCloseCountInstance } from '../../redux/CountInstance/CountInstance.selectors';
import {
	showEditCountInstanceModal,
	showSendNotificationModal,
} from '../../redux/CountInstanceDetails/CountInstanceDetails.actions';
import {
	selectShowEditCountInstanceModal,
	selectShowSendNotificationModal,
} from '../../redux/CountInstanceDetails/CountInstanceDetails.selectors';
import {
	selectCountInstance,
	selectCountInstanceError,
} from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import {
	selectLoggedInUserRoles,
	selectUserCountry,
} from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';
import { disableVarAnalysisButton } from '../../utils/VarianceAnalysis.utils';
import { checkNull } from '../../utils/Verify.utils';
import CloseCountInstanceWarning from '../CloseCountInstanceWarning/CloseCountInstanceWarning.component';
import EditCountInstanceModal from '../EditCountInstanceModal/EditCountInstanceModal.component';
import SendNotificationComponent from '../SendNotificationModal/SendNotificationModal.component';

function CountInstanceSingle({
	countryCode,
	countInstance,
	errorMessage,
	isCloseCountInstanceVisible,
	showCloseCountInstance,
	showEditModal,
	showNotificationModal,
	isEditModalVisible,
	isSendNotificationModalVisible,
	closeCountInstance,
	updateVarianceAnalysisState,
	loggedInUserRoles,
}) {
	const t = useSelector(selectTranslations);
	const history = useHistory();
	// close count instance
	const handleConfirm = closeReason => {
		closeCountInstance(closeReason, countInstance);
	};
	// dateformat for the front end
	const dateFormat = getCountryDateFormat(countryCode);
	// replace the variance id when redirect
	const callBack = data => {
		history.replace(
			`${history.location.pathname}/variance-analysis/${encodeURI(data.id)}/${
				data.country
			}`,
		);
	};
	// redirects to Variance analysis when button is clicked
	const handleVAButtonClick = () => {
		if (
			countInstance?.status === RECONCILIATION_IN_PROGRESS ||
			countInstance?.status === CLOSED
		)
			return history.replace(
				`${history.location.pathname}/variance-analysis/${encodeURI(
					countInstance?.id,
				)}/${countInstance.country}`,
			);

		return updateVarianceAnalysisState(countInstance, callBack);
	};
	return (
		<>
			{errorMessage ? message.error(errorMessage) : null}
			<div className="flex flex-wrap">
				<div
					type="button"
					className="float-left  cursor-pointer"
					onClick={() => history.replace('/smart/count-instance')}>
					<ArrowBackIcon className="mr-2" />
					<span className=" relative bottom-1 font-bold">{t.Back}</span>
				</div>
			</div>
			<div className="py-5 w-fit px-5 w-full border-2 rounded-lg">
				<div className="flex justify-between flex-wrap border-b-2 pb-4">
					<div className="text-xl flex items-center font-bold">
						{`${t.ScheduledCountDetails} `}
						{countInstance ? countInstance.scheduledCountID : null}
					</div>
					<div>
						<Button
							className="mr-2 w-56 px-1 border-jnj_red border-2 h-12"
							disabled={disableVarAnalysisButton(
								countInstance,
								countInstance?.status,
							)}
							onClick={handleVAButtonClick}>
							<span className="relative bottom-1">{t.VarianceAnalysis}</span>
							<RightArrowIcon className="ml-1" />
						</Button>
						<Button
							disabled={
								countInstance?.status === CANCELED ||
								countInstance?.status === CLOSED ||
								!countInstance ||
								countInstance?.archive_year !== new Date().getFullYear()
							}
							className="mr-2 w-56 px-1 border-jnj_red border-2 h-12"
							onClick={showNotificationModal}>
							<span className="relative bottom-1">{t.SendNotification}</span>
							<SendIcon className="ml-3" />
						</Button>
						<Button
							disabled={
								countInstance?.status === CANCELED ||
								countInstance?.status === CLOSED ||
								!countInstance ||
								countInstance?.archive_year !== new Date().getFullYear() ||
								loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
							}
							className="mr-2 w-56 px-1 h-12"
							type="primary"
							onClick={showEditModal}>
							<span className="relative bottom-1">{t.EditDetails}</span>
							<EditIcon className="ml-3" />
						</Button>
						<Button
							disabled={countInstance?.status !== AUDIT_COMPLETED}
							className="mr-2 w-56 px-1 border-jnj_red border-2 h-12"
							onClick={showCloseCountInstance}>
							<CancelIcon className="mr-3" />
							<span className="relative bottom-1">{t.ForceCloseStatus}</span>
						</Button>
					</div>
				</div>
				<div className="flex w-3/4">
					<div className="flex-1 flex-wrap mt-2">
						<div className="pb-1">
							<span className="font-bold mr-2">{t.Status}: </span>
							{countInstance ? countInstance.status : null}
						</div>
						<div className="pb-1">
							<span className="font-bold mr-2">{t.ScheduledDate}:</span>
							{!checkNull(countInstance, 'expectedStartDate')
								? `${moment(
										countInstance?.expectedStartDate,
										databaseDateFormat,
								  ).format(dateFormat)} __`
								: ''}
							{!checkNull(countInstance, 'expectedEndDate')
								? `__${moment(
										countInstance?.expectedEndDate,
										databaseDateFormat,
								  ).format(dateFormat)}`
								: ''}
						</div>

						<div className="pb-1">
							<span className="font-bold mr-2">{t.Operator}: </span>
							{countInstance ? countInstance.operator : null}
						</div>
						<div className="pb-1">
							<span className="font-bold mr-2">{t.Approver}:</span>
							{countInstance ? countInstance.approver : null}
						</div>
						<div className="pb-1">
							<span className="font-bold mr-2"> {t.Franchises}:</span>
							{countInstance?.ship_data
								? countInstance?.ship_data.forEach(shipTo => (
										<span className="mr-2">{shipTo.franchise_code} ,</span>
								  ))
								: null}
						</div>
						<div className="pb-1">
							<span className="font-bold mr-2">{t.Reviewer}:</span>
						</div>
						{countInstance && countInstance.status === CLOSED ? (
							<div className="pb-1">
								<span className="font-bold mr-2">{t.ReasonToClose}:</span>
								{countInstance?.closeReason}
							</div>
						) : null}
					</div>
				</div>
				{!isEditModalVisible || <EditCountInstanceModal title={t.EditCount} />}
				{!isSendNotificationModalVisible || (
					<SendNotificationComponent title={t.SendNotification} />
				)}
				{!isCloseCountInstanceVisible || (
					<CloseCountInstanceWarning handleConfirm={handleConfirm} />
				)}
			</div>
		</>
	);
}
// maps selector to props
const mapStateToProps = createStructuredSelector({
	countryCode: selectUserCountry,
	isCloseCountInstanceVisible: selectShowCloseCountInstance,
	countInstance: selectCountInstance,
	errorMessage: selectCountInstanceError,
	isEditModalVisible: selectShowEditCountInstanceModal,
	isSendNotificationModalVisible: selectShowSendNotificationModal,
	loggedInUserRoles: selectLoggedInUserRoles,
});
// maps props to actions
const mapDispatchToProps = dispatch => ({
	showEditModal: () => dispatch(showEditCountInstanceModal()),
	showNotificationModal: () => dispatch(showSendNotificationModal()),
	closeCountInstance: (closeReason, countInstance) =>
		dispatch(closeCountInstanceAsync(closeReason, countInstance)),
	showCloseCountInstance: () => dispatch(showCloseCountInstanceModal()),
	updateVarianceAnalysisState: (data, callBack) =>
		dispatch(updateVarianceAnalysisAsync(data, callBack)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(CountInstanceSingle));
