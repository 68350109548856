const CountInstanceSingle = {
	FETCH_SINGLE_COUNT_INSTANCE_START: 'FETCH_SINGLE_COUNT_INSTANCE_START',
	FETCH_SINGLE_COUNT_INSTANCE_SUCCESS: 'FETCH_SINGLE_COUNT_INSTANCE_SUCCESS',
	FETCH_SINGLE_COUNT_INSTANCE_FAILURE: 'FETCH_SINGLE_COUNT_INSTANCE_FAILURE',
	UPDATE_COUNT_INSTANCE_START: 'UPDATE_COUNT_INSTANCE_START',
	UPDATE_COUNT_INSTANCE_FAILURE: 'UPDATE_COUNT_INSTANCE_FAILURE',
	UPDATE_COUNT_INSTANCE_SUCCESS: 'UPDATE_COUNT_INSTANCE_SUCCESS',
};

export default CountInstanceSingle;
