import { Button, Dropdown, Menu, message } from 'antd';
import 'jspdf-autotable';
import React from 'react';
import { CSVLink } from 'react-csv';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx/xlsx.mjs';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/export-down-icon.svg';
import {
	COUNT_INSTANCE_INDEX_URL_HISTORY,
	fileName,
	userRoles,
} from '../../constants';
import { bulkExportCountInstanceAsync } from '../../redux/CountInstance/CountInstance.actions';
import { bulkExportAuditCountInstanceAsync } from '../../redux/CountInstanceDetails/AuditCount/AuditCount.action';
import { bulkExportErpCountInstanceAsync } from '../../redux/CountInstanceDetails/ErpCount/ErpCount.action';
import { bulkExportLocationSearchAsync } from '../../redux/Location/Location.action';
import { bulkExportUsersListSearch } from '../../redux/User/User.actions';
import { bulkExportVarianceAnalysisAsync } from '../../redux/VarianceAnalysis/VarianceAnalysis.action';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { store } from '../../redux/store';
import exportPdf, { generateFileName } from '../../utils/ExportPdf.utils';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';

// ExportDataMenu
function ExportDataMenu({
	csvData,
	exportHeaders,
	exportedFileName,
	mappedData,
	bulkData,
	excelBulkData,
	loggedInUserRoles,
	isBulkRequestFetching,
	totalRecord,
	searchQuery,
	errorMessage,
	filterData,
	sorter,
	year = new Date().getFullYear(),
}) {
	const t = useSelector(selectTranslations);
	const params = useParams();
	const countryCode = useSelector(state => state.user.loggedInUser.country);
	// Date Format change from db format
	const dateFormat = getCountryDateFormat(countryCode);

	const erpType = useSelector(state => state.user.erpType);
	const exportPDFHandler = () =>
		exportPdf(
			bulkData,
			exportHeaders,
			generateFileName(exportedFileName, dateFormat),
			mappedData,
		);
	const history = useHistory();
	let excelBulk = bulkData;
	const exportExcelHandler = () => {
		const workbook = XLSX.utils.book_new();
		if (exportedFileName === fileName.Count_Instance)
			excelBulk = bulkData.map(obj => ({
				...obj,
				shipTo: obj.shipTo.join(','),
			}));
		if (exportedFileName === fileName.Users_List)
			excelBulk = bulkData.map(obj => ({
				...obj,
				roles: obj.roles.join(','),
			}));
		const worksheet = XLSX.utils.json_to_sheet(
			exportedFileName === fileName.Variance_Analysis
				? excelBulkData
				: excelBulk,
		);

		XLSX.utils.book_append_sheet(workbook, worksheet);
		setTimeout(
			() =>
				XLSX.writeFileXLSX(
					workbook,
					generateFileName(
						exportedFileName,
						dateFormat,
						store?.getState()?.getVarianceAnalysisExport?.countInstanceId ||
							history.location.pathname.split('/')[
								COUNT_INSTANCE_INDEX_URL_HISTORY
							],
					).concat('.xlsx'),
				),
			200,
		);
	};
	const country = `country=${
		!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
			? countryCode.join(' ')
			: ''
	}`;
	const bulkExportHandler = () => {
		switch (exportedFileName) {
			// Risk Assessment
			case fileName.RISK_ASSESSMENT:
				return store.dispatch(
					bulkExportLocationSearchAsync(
						erpType,
						country,
						totalRecord,
						searchQuery,
						filterData,
						year,
					),
				);

			// Count Instance
			case fileName.Count_Instance:
				return store.dispatch(
					bulkExportCountInstanceAsync(
						erpType,
						country,
						totalRecord,
						searchQuery,
						year,
					),
				);
			case fileName.Users_List:
				return store.dispatch(
					bulkExportUsersListSearch(country, totalRecord, searchQuery),
				);
			case fileName.Audit_Count:
				return store.dispatch(
					bulkExportAuditCountInstanceAsync(
						totalRecord,
						params.uuid,
						searchQuery,
						year,
					),
				);
			case fileName.ERP_Count:
				return store.dispatch(
					bulkExportErpCountInstanceAsync(
						totalRecord,
						params.uuid,
						searchQuery,
						year,
					),
				);
			// Variance Analysis
			case fileName.Variance_Analysis:
				return store.dispatch(
					bulkExportVarianceAnalysisAsync(
						totalRecord,
						params.uuid,
						'current',
						sorter,
						year,
					),
				);
			default:
				return {};
		}
	};
	// List of Menu
	const menu = (
		<>
			{errorMessage ? message.error(errorMessage) : null}
			<Menu disabled={isBulkRequestFetching || !!errorMessage}>
				<Menu.Item key="0">
					{bulkData ? (
						<CSVLink
							data={bulkData}
							filename={generateFileName(
								exportedFileName,
								dateFormat,
								store.getState().getVarianceAnalysisExport?.countInstanceId,
							).concat('.csv')}
							className="btn btn-primary text-w"
							target="_blank">
							<div className="flex content-center ">
								<span className="px-10 bottom-5 font-bold">CSV</span>
								<div className="pt-1">
									<DownloadIcon />
								</div>
							</div>
						</CSVLink>
					) : (
						<CSVLink
							data={csvData}
							filename={generateFileName(
								exportedFileName,
								dateFormat,
								store.getState().getVarianceAnalysisExport?.countInstanceId,
							).concat('.csv')}
							className="btn btn-primary text-w"
							target="_blank">
							<div className="flex content-center">
								<span className="px-10 bottom-5 font-bold">CSV</span>
								<DownloadIcon />
							</div>
						</CSVLink>
					)}
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item key="2" onClick={() => exportExcelHandler()}>
					<div className="flex content-center">
						<span className=" px-10 bottom-5  font-bold"> Excel</span>
						<DownloadIcon />
					</div>
				</Menu.Item>
				<Menu.Divider />

				{exportedFileName !== fileName.Variance_Analysis && (
					<Menu.Item key="1" onClick={() => exportPDFHandler()}>
						<div className="flex content-center">
							<span className="px-10 bottom-5  font-bold"> PDF</span>
							<DownloadIcon />
						</div>
					</Menu.Item>
				)}
			</Menu>
		</>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Button
				disabled={isBulkRequestFetching}
				loading={isBulkRequestFetching}
				onClick={bulkExportHandler}>
				<span className="pl-0 pr-3 relative bottom-1">{t.Export}</span>
				<ExportIcon />
			</Button>
		</Dropdown>
	);
}
// Map dispatch to props
const mapStateToProps = (state, ownProps) => {
	switch (ownProps.exportedFileName) {
		case 'Risk Assessment':
			return {
				bulkData: state.exportLocation.bulkLocationData,
				errorMessage: state.exportLocation.errorMessage,
				isBulkRequestFetching: state.exportLocation.isBulkRequestFetching,
				totalRecord: state.getLocation.totalNumberOfRecord,
				loggedInUserRoles: state.user.loggedInUser.roles,
				filterData: state.getLocation.selectedLocationFilters,
			};
		case 'Count Instance':
			return {
				bulkData: state.exportCountInstance.bulkCountInstanceData,
				isBulkRequestFetching: state.exportCountInstance.isBulkRequestFetching,
				totalRecord: state.getCountInstance.totalCount,
				errorMessage: state.exportCountInstance.errorBulkMessage,
				loggedInUserRoles: state.user.loggedInUser.roles,
			};
		case 'Users List':
			return {
				bulkData: state.exportUserList.bulkUserListData,
				isBulkRequestFetching: state.exportUserList.isBulkRequestFetching,
				totalRecord: state.user.totalNumberOfRecord,
				errorMessage: state.exportUserList.errorBulkMessage,
				loggedInUserRoles: state.user.loggedInUser.roles,
			};
		case 'Audit Count':
			return {
				bulkData:
					state.exportAuditCount.bulkAuditCountInstanceData &&
					state.exportAuditCount.bulkAuditCountInstanceData.map(
						({ id, ...keepAttrs }) => keepAttrs,
					),
				isBulkRequestFetching:
					state.exportAuditCount.isAuditBulkRequestFetching,
				totalRecord: state.getAuditCount.totalCount,
				errorMessage: state.exportAuditCount.errorAuditBulkMessage,

				loggedInUserRoles: state.user.loggedInUser.roles,
			};
		case 'ERP Count':
			return {
				bulkData:
					state.exportErpCount.bulkErpCountInstanceData &&
					state.exportErpCount.bulkErpCountInstanceData.map(
						({ id, ...keepAttrs }) => keepAttrs,
					),
				isBulkRequestFetching: state.exportErpCount.isErpBulkRequestFetching,
				totalRecord: state.getErpCount.totalCount,
				errorMessage: state.exportErpCount.errorErpBulkMessage,
				loggedInUserRoles: state.user.loggedInUser.roles,
			};

		case 'Variance Analysis':
			return {
				bulkData: state.getVarianceAnalysisExport.bulkVarianceAnalysisData,
				errorMessage: state.getVarianceAnalysisExport.errorMessage,
				excelBulkData:
					state.getVarianceAnalysisExport.bulkExcelVarianceAnalysisData,
				isBulkRequestFetching: state.getVarianceAnalysisExport.isExportGrid,
				totalRecord: state.getCurrentVarianceAnalysis.totalCount,
				loggedInUserRoles: state.user.loggedInUser.roles,
				sorter: state.getCurrentVarianceAnalysis.vaSorter,
			};

		default:
			return {};
	}
};

export default connect(mapStateToProps, null)(ExportDataMenu);
