/* eslint-disable class-methods-use-this */
import url from '../config/url';
import {
	AUTH_GRANT_TYPE,
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	DEFAULT_SORT_KEY_USER,
	DEFAULT_SORT_ORDER,
	legalDocTypes,
} from '../constants';
import { setUserSession } from '../utils/Authentication/UserSession.utils';
import HttpService from './HttpService';

export default class UserService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.user}`;
	}

	async showLogin() {
		window.location.href = `${
			process.env.REACT_APP_SSO_URL
		}/as/authorization.oauth2?client_id=${
			process.env.REACT_APP_SSO_CLIENT_ID
		}&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
			process.env.REACT_APP_URL,
		)}`;
	}

	async authenticateUser(data) {
		const body = Object.keys(data)
			.map(key => [
				`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
			])
			.join('&');

		return fetch(`${process.env.REACT_APP_SSO_URL}/as/token.oauth2`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body,
		})
			.then(res => res.json())
			.then(res => {
				setUserSession(res.access_token, res.expires_in);
				return this.authenticateToken({
					grant_type: AUTH_GRANT_TYPE,
					client_id: process.env.REACT_APP_SSO_CLIENT_ID,
					client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
					redirect_uri: process.env.REACT_APP_URL,
					token: res.access_token,
				});
			});
	}

	async authenticateToken(token) {
		const body = Object.keys(token)
			.map(key => [
				`${encodeURIComponent(key)}=${encodeURIComponent(token[key])}`,
			])
			.join('&');
		return fetch(`${process.env.REACT_APP_SSO_URL}/as/token.oauth2`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body,
		});
	}

	async getLoggedInUser(email) {
		const { data } = await this.http.get({
			url: `${this.url}/by-email/${email}`,
		});

		return data;
	}

	async loginUserFactory({ email, password, legalDocsToAccept }) {
		const [response, abort] = await this.http.post({
			url: `${this.url}/login`,
			body: {
				email,
				password,
				...(legalDocsToAccept
					? {
							...(legalDocsToAccept.indexOf(legalDocTypes.TERMS) !== -1
								? { termsAccepted: true }
								: null),
							...(legalDocsToAccept.indexOf(legalDocTypes.PRIVACY) !== -1
								? { privacyPolicyAccepted: true }
								: null),
					  }
					: {}),
			},
		});

		return [response, abort];
	}

	async searchUsersFactory(
		country,
		searchQuery = '',
		userStatus = null,
		offset = DEFAULT_OFFSET_START,
		limit = DEFAULT_LIMIT_START,
		sortBy = DEFAULT_SORT_KEY_USER,
		sortKey = DEFAULT_SORT_ORDER,
		userRole = null,
	) {
		const { data } = await this.http.get({
			url: `${
				this.url
			}?searchFieldKey=${searchQuery.trim()}&sortColumn=${sortBy}&sortKey=${sortKey}`,
			queryParams: { offset, limit, country, userRole, userStatus },
		});
		return data;
	}

	async deleteUserListFactory(id, loginId) {
		return this.http.delete({
			url: `${this.url}/${id.trim()}?userSession=${loginId}`,
		});
	}

	checkPermission(permission, webPerms) {
		if (!permission) {
			return true;
		}
		if (
			Array.isArray(webPerms) &&
			typeof permission === 'object' &&
			webPerms.includes(permission.name)
		) {
			return true;
		}
		return !!(
			Array.isArray(webPerms) &&
			Array.isArray(permission) &&
			webPerms.some(
				webPerm => permission.map(perm => perm.name).indexOf(webPerm) >= 0,
			)
		);
	}

	async sendPasswordResetLinkFactory(payload) {
		const { response } = await this.http.post({
			url: `${this.url}/reset-password`,
			data: payload,
		});
		return response;
	}

	async updateUserFactory(id, request, loginId, action = '') {
		const { data } = await this.http.patch({
			url: `${this.url}/${id}?userSession=${loginId}&action=${action}`,
			data: request,
		});
		return data;
	}

	async createUserFactory(request, loginId) {
		const { data } = await this.http.post({
			url: `${this.url}?userSession=${loginId}`,
			data: request,
		});
		return data;
	}

	getUserList(endpoint) {
		return this.http.get({ url: endpoint });
	}
}
