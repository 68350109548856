/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Modal, Table } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';
import warningSvg from '../../assets/icons/warning.svg';
import { generateFileName } from '../ExportPdf.utils';
import './VarianceImportErrorMessage.styles.scss';

const columns = [
	{
		title: 'fieldName',
		dataIndex: 'fieldName',
		key: 'fieldName',
	},
	{
		title: 'errorValue',
		dataIndex: 'errorValue',
		key: 'errorValue',
		render: text =>
			typeof text === 'object' && text.length > 10 ? (
				<div className="errorValues">
					<span
						className="showMore text-blue-600 cursor-pointer font-light text-base justify-center"
						tabIndex={0}>
						Display More
					</span>

					<h4>{text.slice(0, 10).toString()}</h4>
					<h4 className="moreElement">
						, {text.slice(10, text.length).toString()}
					</h4>
				</div>
			) : (
				<h4> {text.toString()}</h4>
			),
	},
];

const VarianceImportError = (messageToDisplay, dateFormat) =>
	Modal.confirm({
		content: (
			<div className="pb-10">
				<div className="text-jnj_black font-bold text-lg flex text-center">
					<div>
						<Table
							scroll={{ y: 'max-content', x: 50 }}
							rowKey={record => record.fieldName}
							pagination={false}
							dataSource={messageToDisplay}
							columns={columns}
						/>
					</div>
				</div>
			</div>
		),
		icon: (
			<div className="pt-10 flex pb-4 justify-center">
				<div>
					<img src={warningSvg} alt="React Logo" />
				</div>
			</div>
		),
		okText: (
			<CSVLink
				data={!messageToDisplay ? [] : messageToDisplay}
				filename={generateFileName(
					'Import Variance Analysis Errors',
					dateFormat,
				).concat('.csv')}>
				Download
			</CSVLink>
		),
		width: 700,
	});

export default VarianceImportError;
