const ErpCountActionTypes = {
	SEARCH_ERP_COUNT_INSTANCE_START: 'SEARCH_ERP_COUNT_INSTANCE_START',
	SEARCH_ERP_COUNT_INSTANCE_SUCCESS: 'SEARCH_ERP_COUNT_INSTANCE_SUCCESS',
	SEARCH_ERP_COUNT_INSTANCE_FAILURE: 'SEARCH_ERP_COUNT_INSTANCE_FAILURE',

	GET_ERP_COUNT_DETAIL_START: 'GET_ERP_COUNT_DETAIL_START',
	GET_ERP_COUNT_DETAIL_SUCCESS: 'GET_ERP_COUNT_DETAIL_SUCCESS',
	GET_ERP_COUNT_DETAIL_FAILURE: 'GET_ERP_COUNT_DETAIL_FAILURE',

	BULK_EXPORT_ERP_COUNT_START: 'BULK_EXPORT_ERP_COUNT_START',
	BULK_EXPORT_ERP_COUNT_SUCCESS: 'BULK_EXPORT_ERP_COUNT_SUCCESS',
	BULK_EXPORT_ERP_COUNT_FAILURE: 'BULK_EXPORT_ERP_COUNT_FAILURE',
};

export default ErpCountActionTypes;
