/* eslint-disable arrow-body-style */
import SendNotificationService from '../../services/SendNotificationService';
import { ErrorMessage } from '../../utils/errorMessage';
import SuccessMessage from '../../utils/successMessage';
import CountInstanceDetailActionTypes from './CountInstanceDetails.types';

const sendNotificationService = new SendNotificationService();

// Count Detail page Actions
export const showEditCountInstanceModal = () => ({
	type: CountInstanceDetailActionTypes.SHOW_EDIT_COUNT_INSTANCE_MODAL,
});

export const showSendNotificationModal = () => ({
	type: CountInstanceDetailActionTypes.SHOW_SEND_NOTIFICATION_MODAL,
});

// Send Notification
export const sendNotificationStart = () => ({
	type: CountInstanceDetailActionTypes.SEND_NOTIFICATION_START,
});

export const sendNotificationFailure = errorMessage => ({
	type: CountInstanceDetailActionTypes.SEND_NOTIFICATION_FAILURE,
	payload: errorMessage,
});
export const sendNotificationSuccess = () => ({
	type: CountInstanceDetailActionTypes.SEND_NOTIFICATION_SUCCESS,
});

export const sendNotificationStartAsync = data => {
	return dispatch => {
		const response = sendNotificationService.sendNotificationFactory(data);
		dispatch(sendNotificationStart());
		response
			.then(successResponse => {
				SuccessMessage(successResponse.message);
				dispatch(showSendNotificationModal());
				dispatch(sendNotificationSuccess());
			})
			.catch(error => {
				dispatch(sendNotificationFailure(error.message));
				ErrorMessage();
			});
	};
};
