/* eslint-disable no-unsafe-optional-chaining */
import { Form, Select, Tag } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SEARCH_MIN_CHAR, userRoles } from '../../../constants';
import { searchApproversStartAsync } from '../../../redux/Configuration/Config.actions';
import {
	selectApproversList,
	selectIsSearching,
} from '../../../redux/Configuration/Config.selector';
import {
	selectLoggedInUserRoles,
	selectUserCountry,
} from '../../../redux/User/User.selector';
import { searchSpinner } from '../../../utils/UserRole.utils';

/* Email compose Form */
function EmailComposerForm({
	isReconciliationApprover,
	configurationsList,
	searchApproversList,
	approvers,
	isSearching,
	loggedInUserRoles,
	countryCode,
}) {
	const [, setOptions] = useState([]);

	/* init state for selected Element */
	const [selectedElement, setSelectedElement] = useState([]);
	/* onselect handler */
	const onSelect = (option, value) => {
		/* filter selectedElement with key */
		if (!selectedElement.filter(x => x === value).length) {
			setSelectedElement([...selectedElement, value]);
		}
	};

	const onClear = () => setOptions([]);

	const tagRender = props => {
		const { value, closable, onClose } = props;
		const onPreventMouseDown = event => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color="#CA001B"
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}>
				{value}
			</Tag>
		);
	};

	/* on search handler */
	const handleSearch = keyword => {
		searchApproversList(
			keyword,
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
		);
	};

	const searchOptions = () =>
		approvers.map(data => ({
			value: data.email,
			label: (
				<div
					key={data.id}
					style={{
						boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
						transition: '0.3',
						borderRadius: '5px',
						display: 'flex',
					}}>
					<div className="pl-4 pt-4">
						<p>{`${data.lastName}, ${data.firstName}`}</p>
					</div>
				</div>
			),
		}));
	return (
		<div className="gap-2">
			{isReconciliationApprover ? (
				<>
					<div
						style={{
							height: `${
								configurationsList?.reconConfig?.approversL1?.length * 50
							}px`,
							minHeight: '100px',
						}}>
						{/* Approvers L1 */}
						<p className=" text-base font-bold pb-2">Approvers L1:</p>
						<Form.Item
							initialValue={configurationsList?.reconConfig.approversL1}
							name="approversL1">
							<Select
								placeholder="Search Approvers L1"
								mode="multiple"
								onSelect={onSelect}
								onSearch={handleSearch}
								style={{ width: '100%' }}
								tagRender={tagRender}
								onClear={onClear}
								notFoundContent={searchSpinner(isSearching)}
								options={approvers?.length ? searchOptions() : null}
								filterOption={false}
								allowClear
							/>
						</Form.Item>
					</div>
					<div
						style={{
							height: `${
								configurationsList?.reconConfig?.approversL2?.length * 50
							}px`,
							minHeight: '100px',
						}}>
						{/* Approvers L2 */}
						<p className=" text-base font-bold pb-2">Approvers L2:</p>
						<Form.Item
							initialValue={configurationsList?.reconConfig.approversL2}
							name="approversL2">
							<Select
								placeholder="Search Approvers L2"
								mode="multiple"
								onSelect={onSelect}
								onSearch={handleSearch}
								style={{ width: '100%' }}
								tagRender={tagRender}
								onClear={onClear}
								notFoundContent={searchSpinner(isSearching)}
								options={approvers?.length ? searchOptions() : null}
								filterOption={false}
								allowClear
							/>
						</Form.Item>
					</div>
					<div
						className="mb-24 md:mb-1"
						style={{
							maxHeight: `${
								configurationsList?.reconConfig?.approversL3?.length * 50
							}px`,
							minHeight: '100px',
						}}>
						{/* Approvers L3 */}
						<p className=" text-base font-bold pb-2">Approvers L3:</p>
						<Form.Item
							initialValue={configurationsList?.reconConfig.approversL3}
							name="approversL3">
							<Select
								placeholder="Search Approvers L3"
								mode="multiple"
								onSelect={onSelect}
								onSearch={handleSearch}
								style={{ width: '100%' }}
								tagRender={tagRender}
								onClear={onClear}
								notFoundContent={searchSpinner(isSearching)}
								options={approvers?.length ? searchOptions() : null}
								filterOption={false}
								allowClear
							/>
						</Form.Item>
					</div>
				</>
			) : (
				<div>
					{/* list of Approvers */}
					<p className=" ext-base font-bold pb-2">Approvers:</p>
					<Form.Item name="approvers">
						<Select
							onSelect={onSelect}
							onSearch={handleSearch}
							style={{ width: '100%' }}
							tagRender={tagRender}
							defaultValue={configurationsList?.riskAssessmentConfig.approvers}
							placeholder="Search Approvers"
							mode="multiple"
							onClear={onClear}
							loading={isSearching}
							notFoundContent={searchSpinner(isSearching)}
							options={approvers?.length ? searchOptions() : null}
							filterOption={false}
							allowClear
						/>
					</Form.Item>
				</div>
			)}
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	searchApproversList: (query, country) => {
		if (query.length > SEARCH_MIN_CHAR) {
			dispatch(searchApproversStartAsync(country, query));
		}
	},
});

const mapStateToProps = createStructuredSelector({
	countryCode: selectUserCountry,
	approvers: selectApproversList,
	isSearching: selectIsSearching,
	loggedInUserRoles: selectLoggedInUserRoles,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailComposerForm);
