import url from '../config/url';
import {
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	DEFAULT_SORT_KEY,
	DEFAULT_SORT_ORDER,
} from '../constants';
import HttpService from './HttpService';

export default class CountInstanceService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.scheduledCount}`;
	}

	async getCountInstanceFactory(id) {
		const { data } = await this.http.get({
			url: `${this.url}/${id.trim()}`,
		});
		return data;
	}

	async postCountInstancesFactory(request) {
		const { data } = await this.http.post({
			url: `${this.url}`,
			data: request,
		});

		return data;
	}

	async updateCountInstanceFactory(request, id) {
		const { data } = await this.http.put({
			url: `${this.url}/${id.trim()}`,
			data: request,
		});
		return data;
	}

	// TODO will replaced with real api implementation
	async sendNotificationFactory(emailRequest) {
		const { data } = await this.http.post({
			url: `${this.url}`,
			data: emailRequest,
		});
		return data;
	}

	async getCountInstanceFilters(erpType, country, selectedYear) {
		if (!erpType) {
			throw new Error('ERP type is required.');
		}
		const { data } = await this.http.get({
			url: `${
				url.backendEndpoints.erpUrl
			}/erps/${erpType.toLowerCase()}/scheduledCount/filters`,
			queryParams: { country, year: selectedYear },
		});
		return data;
	}

	async searchCountInstanceFactory(
		erpType,
		country,
		year = new Date().getFullYear(),
		searchQuery = '',
		offset = DEFAULT_OFFSET_START,
		limit = DEFAULT_LIMIT_START,
		sortBy = DEFAULT_SORT_KEY,
		sortKey = DEFAULT_SORT_ORDER,
		filter = null,
	) {
		const params = {
			year,
		};
		if (filter?.expectedStartDate) {
			params.filterExpectedStartDate = filter.expectedStartDate.join(',');
		}
		if (filter?.actualStartDate) {
			params.filterActualStartDate = filter.actualStartDate.join(',');
		}
		if (filter?.expectedEndDate) {
			params.filterExpectedEndDate = filter.expectedEndDate.join(',');
		}
		if (filter?.actualEndDate) {
			params.filterActualEndDate = filter.actualEndDate.join(',');
		}
		if (filter?.reconciliationEndDate) {
			params.filterReconEndDate = filter.reconciliationEndDate.join(',');
		}
		if (filter?.variance_status) {
			params.filterVarianceStatus = filter.variance_status.join(',');
		}
		if (filter?.country) {
			params.filterCountry = filter.country.join(',');
		}
		if (filter?.status) {
			params.filterScheduledStatus = filter.status.join(',');
		}
		if (filter?.variance_status) {
			params.filterVarianceStatus = filter.variance_status.join(',');
		}
		if (filter?.daysOpen) {
			params.filterDaysOpen = filter.daysOpen.join(',');
		}
		const filterParams = Object.entries(params)
			.map(([key, value]) => `${key}=${value}`)
			.join('&');

		return this.http.get({
			url: `${
				url.backendEndpoints.erpUrl
			}/erps/${erpType.toLowerCase()}/scheduledCount/search?searchField=${searchQuery.trim()}&coulumnName=${sortBy}&sortkey=${sortKey}&${filterParams}`,
			queryParams: { offset, limit, country },
		});
	}

	async deleteCountInstanceFactory(id) {
		return this.http.delete({
			url: `${this.url}/${id.trim()}`,
		});
	}

	getCountInstance(endpoint) {
		return this.http.get({ url: endpoint });
	}

	// eslint-disable-next-line class-methods-use-this
	getErpUrl() {
		return url.backendEndpoints.erpUrl;
	}
}
