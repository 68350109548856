import VarianceAnalysisTypes from '../VarianceAnalysis.types';

const INITIAL_STATE = {
	errorMessage: undefined,
	successMessage: null,
	isImportFetching: false,
	dbUpdateError: undefined,
	dbUpdateStatus: null,
	isDbUpdateProgress: false,
};

const varianceAnalysisImportReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_START:
			return {
				...state,
				isImportFetching: true,
			};
		case VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_SUCCESS:
			return {
				...state,
				isImportFetching: false,
				successMessage: payload,
			};

		case VarianceAnalysisTypes.CSV_UPLOAD_VARIANCE_FAILURE:
			return {
				...state,
				isImportFetching: false,
				errorMessage: payload,
			};
		case VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_START:
			return {
				...state,
				isDbUpdateProgress: true,
			};
		case VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_SUCCESS:
			return {
				...state,
				isDbUpdateProgress: false,
				dbUpdateStatus: payload,
			};

		case VarianceAnalysisTypes.VARIANCE_ANALYSIS_DB_UPDATE_FAILURE:
			return {
				...state,
				isDbUpdateProgress: false,
				dbUpdateError: payload,
			};
		default:
			return state;
	}
};

export default varianceAnalysisImportReducer;
