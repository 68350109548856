import AuditActionTypes from '../AuditCount.types';

const INITIAL_STATE = {
	errorMessage: undefined,
	isCsvUploadFetching: false,
};

const auditCountUploadReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case AuditActionTypes.CSV_UPLOAD_AUDIT_COUNT_START:
			return {
				...state,
				isCsvUploadFetching: true,
			};
		case AuditActionTypes.CSV_UPLOAD_AUDIT_COUNT_SUCCESS:
			return {
				...state,
				isCsvUploadFetching: false,
			};
		case AuditActionTypes.CSV_UPLOAD_AUDIT_COUNT_FAILURE:
			return {
				...state,
				isCsvUploadFetching: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default auditCountUploadReducer;
