import LocationActionTypes from './Location.types';

const INITIAL_STATE = {
	bulkLocationData: null,
	errorMessage: undefined,
	isBulkRequestFetching: false,
};

const locationExportReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case LocationActionTypes.BULK_EXPORT_LOCATION_START:
			return {
				...state,
				isBulkRequestFetching: true,
			};

		case LocationActionTypes.BULK_EXPORT_LOCATION_SUCCESS:
			return {
				...state,
				isBulkRequestFetching: false,
				bulkLocationData: payload.allLocations,
			};
		case LocationActionTypes.BULK_EXPORT_LOCATION_FAILURE:
			return {
				...state,
				isBulkRequestFetching: false,
				errorMessage: payload,
			};
		default:
			return state;
	}
};

export default locationExportReducer;
