import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import {
	ONE_SECOND_DELAY,
	SESSION_TIMEOUT,
	errorMessages,
} from '../../constants';
import HttpError from '../../errors/HttpError';
import SessionTimeoutModal from './SessionTimeoutModal.utils';

let countdownInterval;
let timeout;

function SessionTimeout({ isAuthenticated, logOut }) {
	const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
	const [timeoutCountdown, setTimeoutCountdown] = useState(0);
	const idleTimer = useRef(null);

	const clearSessionTimeout = () => {
		clearTimeout(timeout);
	};

	const clearSessionInterval = () => {
		clearInterval(countdownInterval);
	};

	const handleLogout = async () => {
		try {
			setTimeoutModalOpen(false);
			clearSessionInterval();
			clearSessionTimeout();
			logOut();
		} catch (err) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
	};

	const handleContinue = () => {
		setTimeoutModalOpen(false);
		clearSessionInterval();
		clearSessionTimeout();
	};

	const onActive = () => {
		if (!timeoutModalOpen) {
			clearSessionInterval();
			clearSessionTimeout();
		}
	};

	const onIdle = () => {
		const delay = ONE_SECOND_DELAY * 1;
		if (isAuthenticated && !timeoutModalOpen) {
			timeout = setTimeout(() => {
				let countDown = 10;
				setTimeoutModalOpen(true);
				setTimeoutCountdown(countDown);
				countdownInterval = setInterval(() => {
					if (countDown > 0) {
						// eslint-disable-next-line no-plusplus
						setTimeoutCountdown(--countDown);
					} else {
						handleLogout();
					}
				}, ONE_SECOND_DELAY);
			}, delay);
		}
	};

	return (
		<>
			<IdleTimer
				ref={idleTimer}
				onActive={onActive}
				onIdle={onIdle}
				debounce={250}
				timeout={SESSION_TIMEOUT}
			/>
			<SessionTimeoutModal
				countdown={timeoutCountdown}
				onContinue={handleContinue}
				onLogout={() => handleLogout()}
				open={timeoutModalOpen}
			/>
		</>
	);
}

export default SessionTimeout;
