/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable sonarjs/prefer-immediate-return */
import { Spin } from 'antd';
import React from 'react';

const WithSpinner = WrappedComponent => {
	function Spinner({ isLoaded, ...otherProps }) {
		return !isLoaded ? (
			<Spin className="flex justify-center p-64" />
		) : (
			<WrappedComponent {...otherProps} />
		);
	}
	return Spinner;
};

export default WithSpinner;
