import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { getActiveUser } from '../../../utils/Authentication/UserSession.utils';
/* Variance Analysis Reject */
function VarianceAnalysisReject(props) {
	/* distract props */
	const {
		mode,
		showReject,
		setShowReject,
		approveVarianceAnalysis,
		id,
		workflowLoading,
	} = props;
	const [form] = Form.useForm();
	const onSubmitHandler = async value => {
		let payloadForReject = {};
		if (mode === 'emailTrigger') {
			payloadForReject = {
				action: 'rejection',
				rejectionReason: `${value.reason}`,
			};
			return approveVarianceAnalysis(payloadForReject);
		}
		payloadForReject = {
			action: 'rejection',
			rejectionReason: `${value.reason}`,
			approver: `${JSON.parse(getActiveUser()).email.toLowerCase()}`,
		};
		return approveVarianceAnalysis(id, payloadForReject, setShowReject);
	};
	/* render */
	return (
		<Modal width="538px" open={showReject} footer={null}>
			<div>
				<div className="p-10">
					{/* Count Rejected */}
					<div className="text-jnj_black text-lg font-black pb-4">
						Count Rejected
					</div>
					<div className="border-t-2 pb-4" />
					{/* Reason for Rejection */}
					<div className="text-jnj_black text-base font-bold pb-2 ">
						Reason for Rejection:
					</div>
					<Form form={form} onFinish={onSubmitHandler}>
						<div className="pb-4">
							<div className="text-jnj_black text-base font-normal px-3 pt-3 pb-5 ">
								<Form.Item
									name="reason"
									rules={[
										{
											required: true,
											message: 'Please input the reason for rejection',
										},
									]}>
									<TextArea
										showCount
										maxLength={2000}
										style={{
											height: 120,
										}}
									/>
								</Form.Item>
							</div>
						</div>
						<div className="border-t-2 pb-4" />
						<div className="flex justify-between">
							{/* Cancel Button */}
							<div>
								<Button
									style={{ width: '220px' }}
									onClick={() => setShowReject(false)}>
									Cancel
								</Button>
							</div>
							{/* submit button */}
							<div>
								<Button
									loading={workflowLoading}
									style={{ width: '220px' }}
									type="primary"
									htmlType="submit">
									Submit
								</Button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</Modal>
	);
}

export default VarianceAnalysisReject;
