/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import WithSpinner from '../../components/WithSpinner/WithSpinner.HOC';
import {
	DEFAULT_LIMIT_START,
	DEFAULT_OFFSET_START,
	userRoles,
} from '../../constants';
import {
	getLocationFiltersStartAsync,
	searchForLocationAsync,
} from '../../redux/Location/Location.action';
import {
	selectIsLocationLoaded,
	selectLocationError,
	selectLocationFiltersErrorMessage,
} from '../../redux/Location/Location.selectors';
import {
	selectErpType,
	selectLoggedInUser,
	selectUserCountry,
} from '../../redux/User/User.selector';
import Location from './Location.component';

const LocationWithSpinner = WithSpinner(Location);
function LocationContainer({
	countryCode,
	loggedInUser,
	errorMessage,
	errorMessageFilters,
	fetchLocationStart,
	fetchLocationFilters,
	loaded,
	erpType,
	year,
	...otherProps
}) {
	useEffect(() => {
		const country = !loggedInUser?.roles.includes(userRoles.SYSTEM_ADMIN)
			? countryCode.join(' ')
			: '';
		fetchLocationStart(country, erpType, year);
		fetchLocationFilters(erpType, country);
		return () => {};
	}, []);

	return errorMessage == null && errorMessageFilters == null ? (
		<LocationWithSpinner isLoaded={loaded} {...otherProps} />
	) : (
		<LocationWithSpinner isLoaded={!loaded} {...otherProps} />
	);
}

const mapStateToProps = createStructuredSelector({
	loaded: selectIsLocationLoaded,
	countryCode: selectUserCountry,
	loggedInUser: selectLoggedInUser,
	errorMessage: selectLocationError,
	errorMessageFilters: selectLocationFiltersErrorMessage,
	erpType: selectErpType,
});
const mapDispatchToProps = dispatch => ({
	fetchLocationStart: (country, erpType, year) =>
		dispatch(
			searchForLocationAsync({
				erpType,
				country,
				searchField: '',
				limit: DEFAULT_LIMIT_START,
				offset: DEFAULT_OFFSET_START,
				coulumnName: 'cac',
				sortkey: 'asc',
				year,
			}),
		),
	fetchLocationFilters: (erpType, country) =>
		dispatch(getLocationFiltersStartAsync(erpType, country)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
