import url from '../config/url';
import HttpService from './HttpService';

export default class LocationService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.location}`;
	}

	/* updateLocationFactory */
	async updateLocationFactory(request) {
		const { data } = await this.http.put({
			url: `${this.url}`,
			data: request,
		});
		return data;
	}

	/* postLocationFactory */
	async postLocationFactory(request) {
		const { data } = await this.http.post({
			url: `${this.url}/csvUpdate`,
			data: request,
		});
		return data;
	}

	async getLocationFilters(erpType, country) {
		if (!erpType) {
			throw new Error('ERP type is required.');
		}
		const { data } = await this.http.get({
			url: `${
				url.backendEndpoints.erpUrl
			}/erps/${erpType.toLowerCase()}/location/filters`,
			queryParams: { country },
		});
		return data;
	}

	/* deleteLocationFactory */
	async deleteLocationFactory(id) {
		const { data } = await this.http.delete({ url: `${this.url}/${id}` });
		return data;
	}

	/* searchLocationFactory */
	async searchLocationFactory({
		erpType,
		year = new Date().getFullYear(),
		...params
	}) {
		if (!erpType) {
			throw new Error('ERP type is required.');
		}
		const { data } = await this.http.get({
			url: `${
				url.backendEndpoints.erpUrl
			}/erps/${erpType.toLowerCase()}/location/search`,
			queryParams: { year, ...params },
		});
		return data;
	}

	/* schedule count status */
	async getScheduleCountStatus(locations) {
		const { data } = await this.http.get({
			url: `${this.url}/scheduledCountStatus`,
			queryParams: { locations },
		});
		return data;
	}

	/* getLocationUrl */
	getLocationUrl(endpoint) {
		return this.http.get({ url: endpoint });
	}

	// eslint-disable-next-line class-methods-use-this
	getErpUrl() {
		return url.backendEndpoints.erpUrl;
	}
}
