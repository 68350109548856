import url from '../config/url';
import HttpService from './HttpService';

export default class ErpCountService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.searchUrl}`;
		this.countSheetUrl = `${url.backendEndpoints.countSheet}`;
	}

	async searchErpCountInstanceFactory(id, offset, limit, searchQuery = '') {
		return this.http.get({
			url: `${
				this.url
			}/erp-auditCount?countType=erpCount&scheduleCountid=${id.trim()}&searchField=${searchQuery.trim()}`,
			queryParams: { offset, limit },
		});
	}

	getErpCount(endpoint) {
		return this.http.get({ url: endpoint });
	}

	async getErpCountDetails(id) {
		const { data } = await this.http.get({
			url: `${this.countSheetUrl}/${id.trim()}/details`,
		});
		return data;
	}
}
