import AuditCountActionTypes from '../AuditCount.types';

const INITIAL_STATE = {
	isAuditBulkRequestFetching: false,
	bulkAuditCountInstanceData: null,
	errorAuditBulkMessage: null,
};

const auditCountExportReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_START:
			return {
				...state,
				isAuditBulkRequestFetching: true,
			};
		case AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_SUCCESS:
			return {
				...state,
				isAuditBulkRequestFetching: false,
				bulkAuditCountInstanceData: payload.result,
			};
		case AuditCountActionTypes.BULK_EXPORT_AUDIT_COUNT_FAILURE:
			return {
				...state,
				isAuditBulkRequestFetching: false,
				errorAuditBulkMessage: payload,
			};
		default:
			return state;
	}
};

export default auditCountExportReducer;
