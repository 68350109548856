/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { notificationEmail } from '../../config/config';
import {
	sendNotificationStartAsync,
	showSendNotificationModal,
} from '../../redux/CountInstanceDetails/CountInstanceDetails.actions';
import {
	selectIsSendingNotification,
	selectSendNotificationError,
	selectSendNotificationSuccess,
} from '../../redux/CountInstanceDetails/CountInstanceDetails.selectors';
import {
	selectCountInstance,
	selectCountInstanceError,
} from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
/* Text Area */
const { TextArea } = Input;
/* send notification modal component */
function SendNotificationModal({
	successMessageSent,
	errorMessageSent,
	isSending,
	sendNotification,
	countInstance,
	errorMessage,
	showModal,
	title,
}) {
	/* select translations */
	const t = useSelector(selectTranslations);
	/* declare Form */
	const [form] = Form.useForm();

	/* Form on Finish handler */
	const onFinish = values => {
		const { attachCountSheet, ...newObject } = values;
		if (values?.attachCountSheet && countInstance) {
			newObject.scheduled_count_id = countInstance.scheduledCountID;
		}
		/* send Notification */
		sendNotification(newObject);

		if (successMessageSent) {
			/* popup success message */
			message.info(successMessageSent);
			/* close Modal */
			showModal();
		}
	};
	/* render */
	return (
		<Modal
			style={{ width: '538px' }}
			maskClosable={false}
			open
			closeIcon={
				<div className="text-lg p-4">
					<CloseOutlined />
				</div>
			}
			footer={null}
			title={null}
			closable={false}>
			<div className="px-10 py-10">
				{' '}
				<div className="text-lg font-black text-jnj_black pb-4">{title}</div>
				<div className="border-t-2 pb-4" />
				<div>
					<Form
						labelCol={{
							span: 8,
						}}
						initialValues={{
							/* initial message */
							message: countInstance
								? notificationEmail(countInstance || null)
								: null,
							/* subject */
							subject: countInstance
								? `${countInstance?.scheduledCountID}_${countInstance.ship_data[0]?.ship_to}_${countInstance.ship_data[0]?.ship_to_name}`
								: null,
						}}
						onFinish={onFinish}
						form={form}>
						{errorMessage ? message.error(errorMessage) : null}
						<div className="text-base font-bold">
							{' '}
							{/* operators input field */}
							<div>Operator:</div>
							<Form.Item
								name="toList"
								rules={[
									{
										required: true,
										message: 'Operators Email is Required!',
									},
								]}>
								<Select
									placeholder="Email"
									mode="tags"
									style={{ width: '100%' }}
									tokenSeparators={[',']}
								/>
							</Form.Item>
							{/* CC input field */}
							<div>CC:</div>
							<Form.Item name="ccList">
								<Select
									mode="tags"
									placeholder="CC"
									style={{ width: '100%' }}
									tokenSeparators={[',']}
								/>
							</Form.Item>
							{/* Subject input field */}
							<div>Subject:</div>
							<Form.Item
								name="subject"
								labelCol={{
									span: 4,
								}}
								rules={[
									{
										required: true,
										message: 'Email Subject is required!',
									},
								]}>
								<Input
									defaultValue={
										countInstance ? countInstance.scheduledCountID : null
									}
								/>
							</Form.Item>
							<Form.Item name="attachCountSheet" valuePropName="checked">
								<Checkbox>
									<div>Attach Count Sheet</div>
								</Checkbox>
							</Form.Item>
							{/* Comment input field */}
							<div>Comment:</div>
							<Form.Item
								name="message"
								rules={[
									{
										required: false,
										message: 'Email Content Required',
									},
								]}>
								<TextArea
									rows={7}
									defaultValue={notificationEmail(countInstance || null)}
								/>
							</Form.Item>
						</div>
						<div className="border-t-2 pb-4" />

						<div className="flex-1 flex-wrap">
							<div className="flex justify-between">
								{/* cancel Button */}
								<Button onClick={showModal} className="w-56" htmlType="button">
									{t.Cancel}
								</Button>
								{/* Submit Button */}
								<Button
									type="primary"
									loading={isSending}
									className="w-56"
									htmlType="submit">
									{t.SendEmail}
								</Button>
							</div>
							{/* error message */}
							{errorMessageSent ? (
								<div className="text-red-700 px-left-64 py-4 flex justify-center">
									<WarningOutlined className="px-2 py-1" />
									{errorMessageSent}
								</div>
							) : null}
						</div>
					</Form>
				</div>
			</div>
		</Modal>
	);
}
const mapStateToProps = createStructuredSelector({
	countInstance: selectCountInstance,
	errorMessage: selectCountInstanceError,
	successMessageSent: selectSendNotificationSuccess,
	errorMessageSent: selectSendNotificationError,
	isSending: selectIsSendingNotification,
});
const mapDispatchToProps = dispatch => ({
	showModal: () => dispatch(showSendNotificationModal()),
	sendNotification: data => dispatch(sendNotificationStartAsync(data)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SendNotificationModal);
