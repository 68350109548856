import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
/* Footer component */
function Footer() {
	const t = useSelector(selectTranslations);

	return (
		<div className="p-5 bg-gray bottom-0 fixed w-full text-center bg-jnj_red z-10 text-jnj_gray">
			{/* render Link */}
			<Link
				className="px-3 hover:text-black"
				target="_blank"
				to="/terms-and-conditions">
				{t.TermsAndConditions}
			</Link>
			<Link
				className="px-3 hover:text-black"
				target="_blank"
				to="/privacy-policy">
				{t.PrivacyPolicy}
			</Link>
			<Link className="px-3 hover:text-black" target="_blank" to="/faq">
				{t.FAQ}
			</Link>
		</div>
	);
}

export default Footer;
