import { Button } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../redux/i18n/i18nSlice';
import { generateFileName } from '../../../utils/ExportPdf.utils';
import { getCountryDateFormat } from '../../../utils/GetCountryFormats.utils';

function DownLoadInitVariance({ exportData, id, exportLoading }) {
	/* useSelector */
	const t = useSelector(selectTranslations);
	const countryCode = useSelector(state => state.user.loggedInUser.country);
	const dateFormat = getCountryDateFormat(countryCode);
	return (
		<CSVLink
			data={!exportData ? [] : exportData}
			filename={generateFileName('Variance Analysis', dateFormat, id).concat(
				'.csv',
			)}>
			<Button
				disabled={!!(exportData && exportData.length === 0)}
				type="primary"
				className="border-jnj_red border-2"
				loading={exportLoading}>
				{t.DownLoadInitialVariance}
			</Button>
		</CSVLink>
	);
}
export default DownLoadInitVariance;
