import AuditCountActionTypes from '../AuditCount.types';

const INITIAL_STATE = {
	isSignatureDownloadFetching: false,
	signatureDownloadFile: null,
	errorSignatureDownloadMessage: null,
};

const auditCountSignatureReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case AuditCountActionTypes.SIGNATURE_DOWNLOAD_START:
			return {
				...state,
				isSignatureDownloadFetching: true,
			};
		case AuditCountActionTypes.SIGNATURE_DOWNLOAD_SUCCESS:
			return {
				...state,
				isSignatureDownloadFetching: false,
				signatureDownloadFile: payload,
			};
		case AuditCountActionTypes.SIGNATURE_DOWNLOAD_FAILURE:
			return {
				...state,
				isSignatureDownloadFetching: false,
				errorSignatureDownloadMessage: payload,
			};
		default:
			return state;
	}
};

export default auditCountSignatureReducer;
