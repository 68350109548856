import React from 'react';
import { ReactComponent as SortDownIcon } from '../assets/icons/sort-down-icon.svg';
import { ReactComponent as SortUpIcon } from '../assets/icons/sort-up-icon.svg';
/* LocationColumnsList method */
export const VarianceColumnsList = column => {
	/* if the column contain status RiskAssessment or location status no
					up and down Icon */
	if (column?.title) {
		/* check for colum has a title */
		return {
			...column,
			title: (
				<div className="flex font-bold  justify-between">
					<div className="w-4/5 whitespace-normal">{column.title}</div>
					{column.key === 'franchise' ||
					column.key === 'subFranchise' ||
					column.key === 'reasonCode' ? (
						<div />
					) : (
						<div className="block py-1">
							<div className="h-2">
								<SortUpIcon />
							</div>
							<div>
								<SortDownIcon />
							</div>
						</div>
					)}
				</div>
			),
		};
	}
	/* if the column contain status RiskAssessment or location status no
					up and down Icon */
	return column;
};
export default VarianceColumnsList();
