import { createSelector } from 'reselect';

const selectCountryList = state => state.getCountriesList.countryList;
const selectCountryCode = state => state.user.loggedInUser.country;

export const selectAllCountries = createSelector(
	[selectCountryList],
	getCountriesList => getCountriesList,
);

export const selectCurrentCountryName = createSelector(
	selectCountryList,
	selectCountryCode,
	(countryList, country) =>
		countryList?.filter(({ countryName }) => country.indexOf(countryName) > -1),
);
