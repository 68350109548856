import { message } from 'antd';
import ConfigurationsService from '../../services/ConfigurationsService';
import UserService from '../../services/UserService';
import SuccessMessage from '../../utils/successMessage';
import ConfigurationActionTypes from './Config.types';

const ConfigService = new ConfigurationsService();
const userService = new UserService();

// Fetch Config Data
export const fetchConfigStart = () => ({
	type: ConfigurationActionTypes.FETCH_CONFIG_START,
});

export const fetchConfigSuccess = usersMap => ({
	type: ConfigurationActionTypes.FETCH_CONFIG_SUCCESS,
	payload: usersMap,
});

export const fetchConfigFailure = errorMessage => ({
	type: ConfigurationActionTypes.FETCH_CONFIG_FAILURE,
	payload: errorMessage,
});
/* update config data */
export const updateConfigStart = () => ({
	type: ConfigurationActionTypes.UPDATE_CONFIG_START,
});

export const updateConfigSuccess = usersMap => ({
	type: ConfigurationActionTypes.UPDATE_CONFIG_SUCCESS,
	payload: usersMap,
});

export const updateConfigFailure = errorMessage => ({
	type: ConfigurationActionTypes.UPDATE_CONFIG_FAILURE,
	payload: errorMessage,
});

// eslint-disable-next-line arrow-body-style
export const fetchConfigStartAsync = countryCode => dispatch => {
	const usersData = ConfigService.getConfigurationsFactory(countryCode);
	dispatch(fetchConfigStart());
	usersData
		.then(usersMap => {
			dispatch(fetchConfigSuccess(usersMap));
		})
		.catch(error => dispatch(fetchConfigFailure(error.message)));
};

export const updateConfigStartAsync = (payload, countryCode) => dispatch => {
	const usersData = ConfigService.updateConfigurationFactory(
		payload,
		countryCode,
	);
	dispatch(updateConfigStart());
	usersData
		.then(usersMap => {
			SuccessMessage(usersMap.updatedMessage);
			dispatch(updateConfigSuccess(payload));
			dispatch(fetchConfigStartAsync(payload.country));
		})
		.catch(error => {
			message.error(error.message);
			dispatch(updateConfigFailure(error.message));
		});
};

/* search for Approvers */

export const searchApproverStart = () => ({
	type: ConfigurationActionTypes.SEARCH_FOR_APPROVER_START,
});

export const searchApproverSuccess = usersMap => ({
	type: ConfigurationActionTypes.SEARCH_FOR_APPROVER_SUCCESS,
	payload: usersMap,
});

export const searchApproverFailure = errorMessage => ({
	type: ConfigurationActionTypes.SEARCH_FOR_APPROVER_FAILURE,
	payload: errorMessage,
});

export const searchApproversStartAsync =
	(country, searchQuery, offset, limit, sortKey, sortOrder) => dispatch => {
		const approverList = userService.searchUsersFactory(
			country,
			searchQuery,
			null,
			offset,
			limit,
			sortKey,
			sortOrder,
			'approver',
		);
		dispatch(searchApproverStart());
		approverList
			.then(usersMap => {
				dispatch(searchApproverSuccess(usersMap));
			})
			.catch(error => dispatch(searchApproverFailure(error.message)));
	};
