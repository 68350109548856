import React from 'react';
import { NavLink } from 'react-router-dom';
import { PRIVATE_ROUTE_BASE } from '../../../constants';

function CustomNavLink({ children, to }) {
	return (
		<NavLink
			isActive={(match, location) => {
				if (location.pathname === '/smart' && to === 'location') {
					return true;
				}
				return !!match;
			}}
			to={`${PRIVATE_ROUTE_BASE}/${to}`}
			activeClassName="border-b-4 border-jnj_red text-jnj_red"
			className="block">
			{children}
		</NavLink>
	);
}

export default CustomNavLink;
