import {
	APPROVAL_PENDING,
	APPROVED,
	AUDIT_COMPLETED,
	AUDIT_COUNT,
	CANCELED,
	CLOSED,
	RECONCILIATION_IN_PROGRESS,
	RISK_ASSESSMENT,
	UPLOAD_ACTIVE,
	UPLOAD_DISABLED,
	UPLOAD_ERROR_RESPONSE,
	UPLOAD_HIDDEN,
	VARIANCE_ANALYSIS,
} from '../constants';

const isCurrentYear = year => year > 0 && year === new Date().getFullYear();

export const uploadLogic = (
	type,
	status = null,
	_totalCount = null,
	secondStatus = null,
	year = null,
) => {
	switch (type) {
		case RISK_ASSESSMENT:
			return status === null ? UPLOAD_ACTIVE : UPLOAD_DISABLED;
		case AUDIT_COUNT:
			if (status === null) {
				return UPLOAD_DISABLED;
			}
			if (
				status === CLOSED ||
				status === CANCELED ||
				status === RECONCILIATION_IN_PROGRESS
			) {
				return UPLOAD_ERROR_RESPONSE;
			}
			if (status === AUDIT_COMPLETED || status === _totalCount > 0) {
				return UPLOAD_DISABLED;
			}
			return isCurrentYear(year) && UPLOAD_ACTIVE;
		case VARIANCE_ANALYSIS:
			if (
				status === APPROVAL_PENDING ||
				status === APPROVED ||
				secondStatus === CLOSED
			) {
				return UPLOAD_DISABLED;
			}
			return UPLOAD_ACTIVE;
		default:
			return UPLOAD_HIDDEN;
	}
};

export const httpErrors = err => {
	switch (err) {
		case 403:
			return 'Access Denied';
		case 404:
			return 'Resource not found';
		case 401:
			return 'User is not authorized';
		case 429:
			return 'Sent too many requests';
		default:
			return 'There is Something Wrong with our system please try again later';
	}
};
// 403: 'Access Denied',
// 201: 'Resource has been Created',
// 200: 'Request is Successful',
// 404: 'Resource not found',
// 401: 'User is not authorized',
// 429: 'Sent too many requests',
