import { createSelector } from 'reselect';

const selectGetCountInstance = state => state.getCountInstance;
const selectExportCountInstance = state => state.exportCountInstance;
const selectPostCountInstance = state => state.postCountInstance;
const selectDeleteCountInstance = state => state.deleteCountInstance;

export const selectShowCloseCountInstance = createSelector(
	[selectDeleteCountInstance],
	deleteCountInstance => deleteCountInstance.showCloseCountInstanceModal,
);

export const selectCountInstances = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.countInstances,
);

export const selectCountInstancesError = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.errorMessage,
);

export const selectIsCountInstancesFetching = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.isFetching,
);
export const selectIsCountInstancesLoaded = createSelector(
	[selectGetCountInstance],
	getCountInstance => !!getCountInstance.countInstances,
);

export const selectCountInstanceFilters = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.filters,
);

export const selectCountInstanceFiltersErrorMessage = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.errorFilterMessage,
);

export const selectTotalCountInstanceCount = createSelector(
	[selectGetCountInstance],
	getCountInstance => getCountInstance.totalCount,
);

export const selectPostCountInstances = createSelector(
	[selectPostCountInstance],
	postCountInstance => postCountInstance.countInstancesResponse,
);

export const selectIsPostingCountInstances = createSelector(
	[selectPostCountInstance],
	postCountInstance => postCountInstance.isPosting,
);

export const selectErrorCountInstances = createSelector(
	[selectPostCountInstance],
	postCountInstance => postCountInstance.errorMessage,
);

// bulk export
export const selectBulkCountInstanceExportRecord = createSelector(
	[selectExportCountInstance],
	exportCountInstance => exportCountInstance.bulkCountInstanceData,
);

export const selectBulkCountInstanceExportFetching = createSelector(
	[selectExportCountInstance],
	exportCountInstance => exportCountInstance.isBulkRequestFetching,
);
