/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import AuditCountInstance from '../../components/AuditCountInstance/AuditCountInstance.component';
import CountInstanceSingle from '../../components/CountInstanceSingle/CountInstanceSingle.component';
import ErpCountInstance from '../../components/ErpCountInstance/ErpCountInstance.component';
import WithSpinner from '../../components/WithSpinner/WithSpinner.HOC';
import { DEFAULT_LIMIT_START, DEFAULT_OFFSET_START } from '../../constants';
import { searchAuditCountInstanceStartAsync } from '../../redux/CountInstanceDetails/AuditCount/AuditCount.action';
import {
	selectAuditCountInstanceError,
	selectIsAuditCountInstanceLoaded,
} from '../../redux/CountInstanceDetails/AuditCount/AuditCount.selector';
import { fetchCountInstanceStartAsync } from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.actions';
import {
	selectCountInstanceError,
	selectIsCountInstanceLoaded,
} from '../../redux/CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.selector';
import { searchERPCountInstanceStartAsync } from '../../redux/CountInstanceDetails/ErpCount/ErpCount.action';
import {
	selectErpCountInstanceError,
	selectIsErpCountInstanceLoaded,
} from '../../redux/CountInstanceDetails/ErpCount/ErpCount.selector';

const CountInstanceSingleWithSpinner = WithSpinner(CountInstanceSingle);
const ErpCountInstanceWithSpinner = WithSpinner(ErpCountInstance);
const AuditCountInstanceWithSpinner = WithSpinner(AuditCountInstance);

function CountInstanceContainer({
	fetchErpCountStart,
	fetchAuditCountStart,
	fetchCountInstanceStart,
	isErpCountLoaded,
	isAuditCountLoaded,
	isCountInstanceLoaded,
	errorErpFetchMessage,
	errorAuditFetchMessage,
	errorCountInstanceMessage,
	...otherProps
}) {
	useEffect(() => {
		fetchAuditCountStart(otherProps.match.params.uuid);
		fetchErpCountStart(otherProps.match.params.uuid);
		fetchCountInstanceStart(otherProps.match.params.uuid);
	}, []);
	return (
		<div className="py-5 overflow-x-scroll">
			{errorCountInstanceMessage == null ? (
				<CountInstanceSingleWithSpinner
					isLoaded={isCountInstanceLoaded}
					{...otherProps}
				/>
			) : (
				<CountInstanceSingleWithSpinner
					isLoaded={!isCountInstanceLoaded}
					{...otherProps}
				/>
			)}
			<div className="grid sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2  overflow-x-scroll">
				{errorErpFetchMessage == null ? (
					<ErpCountInstanceWithSpinner
						isLoaded={isErpCountLoaded}
						{...otherProps}
					/>
				) : (
					<ErpCountInstanceWithSpinner
						isLoaded={!isErpCountLoaded}
						{...otherProps}
					/>
				)}

				{errorAuditFetchMessage == null ? (
					<AuditCountInstanceWithSpinner
						isLoaded={isAuditCountLoaded}
						{...otherProps}
					/>
				) : (
					<AuditCountInstanceWithSpinner
						isLoaded={!isAuditCountLoaded}
						{...otherProps}
					/>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	isErpCountLoaded: selectIsErpCountInstanceLoaded,
	isAuditCountLoaded: selectIsAuditCountInstanceLoaded,
	isCountInstanceLoaded: selectIsCountInstanceLoaded,
	errorErpFetchMessage: selectErpCountInstanceError,
	errorAuditFetchMessage: selectAuditCountInstanceError,
	errorCountInstanceMessage: selectCountInstanceError,
});
const mapDispatchToProps = dispatch => ({
	fetchAuditCountStart: id =>
		dispatch(
			searchAuditCountInstanceStartAsync(
				id,
				DEFAULT_OFFSET_START,
				DEFAULT_LIMIT_START,
				'',
			),
		),
	fetchErpCountStart: id =>
		dispatch(
			searchERPCountInstanceStartAsync(
				id,
				DEFAULT_OFFSET_START,
				DEFAULT_LIMIT_START,
				'',
			),
		),
	fetchCountInstanceStart: id => dispatch(fetchCountInstanceStartAsync(id)),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(CountInstanceContainer));
