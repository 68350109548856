import url from '../config/url';
import HttpService from './HttpService';

export default class VarianceAnalysisWorkflowService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.varianceAnalysisWorkflow}`;
	}

	/* get variance analysis workflow status */
	async getVarianceAnalysisWorkflow({ scheduledCountId }) {
		const { data } = await this.http.get({
			url: `${this.url}/scheduledCount/${scheduledCountId}`,
		});
		return data;
	}

	/* Initialize work flow */
	async initVarianceAnalysisWorkflow({ scheduledCountId }) {
		const { data } = await this.http.post({
			url: `${this.url}/scheduledCount/${scheduledCountId}`,
			payload: {},
		});
		return data;
	}

	/* Initialize work flow step */
	async setVarianceAnalysisWorkflowStepFactory({ scheduledCountId, payload }) {
		const { data } = await this.http.post({
			url: `${this.url}/scheduledCount/${scheduledCountId}/workflow-step`,
			data: payload,
		});
		return data;
	}

	/* this service is called when the user redirect back from his email work flow */
	async triggerWorkflowStep({ scheduledCountId, token, payload }) {
		const { data } = await this.http.post({
			url: `${this.url}/scheduledCount/${scheduledCountId}/workflow-step/${token}`,
			data: payload,
		});
		return data;
	}
}
