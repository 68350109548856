import url from '../config/url';
import HttpService from './HttpService';

export default class ConfigurationsService {
	constructor() {
		this.http = new HttpService();
		this.url = `${url.backendEndpoints.configuration}`;
	}

	async getConfigurationsFactory(countryCode) {
		const { data } = await this.http.get({
			url: `${this.url}`,
			queryParams: { countryCode },
		});
		return data;
	}

	async updateConfigurationFactory(payload, countryCode) {
		const { data } = await this.http.patch({
			url: `${this.url}`,
			data: payload,
			queryParams: `countryCode=${countryCode}`,
		});
		return data;
	}
}
