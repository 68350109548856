/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import RedirectUrl from '../../pages/AuthPages/Login/RedirectUrl/RedirectUrl.component';
import { getActiveUser } from './UserSession.utils';

function PrivateRoute({ component: Component, ...rest }) {
	const loggedInUser = useSelector(state => state.user.loggedInUser);
	return (
		<Route
			{...rest}
			render={props =>
				getActiveUser() !== null && loggedInUser !== null ? (
					<Component {...props} />
				) : (
					<RedirectUrl>
						<Redirect
							to={{ pathname: '/login', state: { from: props.location } }}
						/>
					</RedirectUrl>
				)
			}
		/>
	);
}

export default PrivateRoute;
