/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, Spin, Upload } from 'antd';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload-icon.svg';
import {
	AUDIT_COUNT,
	RISK_ASSESSMENT,
	VARIANCE_ANALYSIS,
	XLSX_CSV_UPLOAD_ERROR,
} from '../../constants';
import { uploadAuditAsync } from '../../redux/CountInstanceDetails/AuditCount/AuditCount.action';
import { uploadLocationAsync } from '../../redux/Location/Location.action';
import { uploadVarianceAsync } from '../../redux/VarianceAnalysis/VarianceAnalysis.action';
import WarningModal from '../../utils/DeleteWarningMessage.utils';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';
import { ErrorModal } from '../../utils/errorMessage';

/* csv upload */
function CsvUpload({ uploadData, type, isCsvUpload, disabled, errorResponse }) {
	const params = useParams();
	const loggedInUserName = useSelector(state => state.user.loggedInUser.email);
	const isVarianceImportFetching = useSelector(
		state => state.importVarianceAnalysis.isImportFetching,
	);

	const countryCode = useSelector(state => state.user.loggedInUser.country);
	const dateFormat = getCountryDateFormat(countryCode);

	/* check the file type */
	const uploadImage = async file => {
		switch (type) {
			case RISK_ASSESSMENT:
				/* upload for risk assessment */
				return uploadData(file, type);
			case AUDIT_COUNT:
				/* upload for audit count */
				return uploadData(file, type, params.uuid);
			case VARIANCE_ANALYSIS:
				return WarningModal(
					'Make sure that Write Offs are uploaded with the standard reason code format "Write Off"',
					() => {
						uploadData(file, type, params.uuid, loggedInUserName, dateFormat);
					},
				);

			default:
				return null;
		}
	};
	/* call back handler */
	const startUploadHandler = async file => {
		await uploadImage(file);
	};
	/* dummy Request */
	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	/* onChange handler */
	const onChange = info => {
		/* if the status is done take the blob */
		if (info.file.status === 'done') {
			startUploadHandler(info.file.originFileObj);
		}
	};

	/* before upload method */
	const beforeUpload = file => {
		/* check the file type for csv */
		const CSV =
			file.type === 'application/vnd.ms-excel' || file.type === 'text/csv';
		const XLSX =
			file.type ===
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		if (!(CSV || XLSX) && type === RISK_ASSESSMENT) {
			/* if the type not csv return message with pop up */
			ErrorModal(XLSX_CSV_UPLOAD_ERROR);
			return Upload.LIST_IGNORE;
		}
		if (!(CSV || XLSX) && type === AUDIT_COUNT) {
			/* if the type not csv return message with pop up */
			ErrorModal(XLSX_CSV_UPLOAD_ERROR);
			return Upload.LIST_IGNORE;
		}
		if (!(CSV || XLSX) && type === VARIANCE_ANALYSIS) {
			/* if the type not csv return message with pop up */
			ErrorModal(XLSX_CSV_UPLOAD_ERROR);
			return Upload.LIST_IGNORE;
		}
		return true;
	};

	/* render title */
	const renderTitle = () => {
		/* if the type not csv return message with pop up */
		switch (type) {
			case RISK_ASSESSMENT:
				/* for risk assessment */
				return (
					<span className="pr-3 relative bottom-1">Import Risk Assessment</span>
				);
			case VARIANCE_ANALYSIS:
				/* for risk assessment */
				return <span className="pr-3 relative bottom-1">Import</span>;
			case AUDIT_COUNT:
				/* for audit count */
				return <span className="pr-3 relative bottom-1">Upload File</span>;
			default:
				return <span className="pr-3 relative bottom-1">Upload File</span>;
		}
	};

	/* return */
	return (
		<>
			{isVarianceImportFetching ? (
				<span>
					<Spin className="ml-2" />
				</span>
			) : (
				<span className="ml-2" />
			)}
			<Upload
				disabled={
					type === VARIANCE_ANALYSIS ? isVarianceImportFetching : disabled
				}
				onChange={onChange}
				beforeUpload={beforeUpload}
				customRequest={dummyRequest}
				maxCount={1}
				showUploadList={isCsvUpload}>
				<Button
					className="lg:px-5 px-0"
					onClick={() => (errorResponse ? ErrorModal(errorResponse) : null)}
					disabled={!errorResponse ? disabled : false}
					type="file"
					loading={isCsvUpload}>
					{renderTitle()}
					<UploadIcon />
				</Button>
			</Upload>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	switch (ownProps.type) {
		case RISK_ASSESSMENT:
			return {
				isCsvUpload: state.uploadLocation.isCsvUploadFetching,
			};
		case AUDIT_COUNT:
			return {
				isCsvUpload: state.uploadAuditCount.isCsvUploadFetching,
			};
		default:
			return {};
	}
};

const mapDispatchToProps = dispatch => ({
	uploadData: (file, type, id = null, username = null, dateFormat = null) => {
		switch (type) {
			case RISK_ASSESSMENT:
				dispatch(uploadLocationAsync(file));
				break;
			case AUDIT_COUNT:
				dispatch(uploadAuditAsync(file, id));
				break;
			case VARIANCE_ANALYSIS:
				dispatch(uploadVarianceAsync(file, username, id, dateFormat));
				break;
			default:
				break;
		}
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(CsvUpload);
