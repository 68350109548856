import {
	APPROVAL_PENDING,
	APPROVED,
	AUDIT_COMPLETED,
	AUTO_APPROVED,
	CLOSED,
	RECONCILIATION_IN_PROGRESS,
} from '../constants';
import { ErrorModal } from './errorMessage';

const GenerateVarianceAnalysisExportLink = (
	totalRecord,
	id,
	type,
	limit,
	url,
	sorter,
	year = new Date().getFullYear()
) => {
	const concatUrl = [];
	let sortParam = '';
	if (
		sorter?.column !== null &&
		sorter?.column !== undefined &&
		sorter?.order !== null &&
		sorter?.order !== undefined
	) {
		sortParam = `&sortColumn=${sorter.column}&sortKey=${sorter.order}`;
	}
	for (let index = 0; index < Math.ceil(totalRecord / limit); index += 1) {
		concatUrl.push(
			`${url}/${id}?varianceType=${type}&limit=${limit}&offset=${
				limit * index
			}${sortParam}&year=${year}`,
		);
	}
	return concatUrl;
};

export default GenerateVarianceAnalysisExportLink;

export const disableVarAnalysisButton = (countInstance, status) => {
	if (countInstance === null) return true;
	return !(
		status === AUDIT_COMPLETED ||
		status === RECONCILIATION_IN_PROGRESS ||
		status === CLOSED
	);
};
export const perseAndPrepareAdvancedFilterParams = advFilterParams => {
	const propValue = Object.values(advFilterParams);

	let filterInvalidTo = propValue.filter(
		x =>
			x?.to.split(':')[1] !== 'undefined' ||
			x?.from.split(':')[1] !== 'undefined',
	);
	filterInvalidTo = filterInvalidTo.filter(
		x => x?.to.split(':')[1] !== 'null' || x?.from.split(':')[1] !== 'null',
	);
	filterInvalidTo = filterInvalidTo.filter(
		x =>
			x?.to.split(':')[1] !== 'null' || x?.from.split(':')[1] !== 'undefined',
	);
	filterInvalidTo = filterInvalidTo.filter(
		x =>
			x?.to.split(':')[1] !== 'undefined' || x?.from.split(':')[1] !== 'null',
	);
	let advancedParams = filterInvalidTo.map(
		e =>
			`${e?.to.replace('undefined', '')},${e?.from.replace('undefined', '')}`,
	);

	advancedParams = advancedParams.map(e => `${e?.replace('null', '')}`);

	return advancedParams.join('%26');
};

export const checkAccessForApprove = (status, arr, userEmail) => {
	switch (status) {
		case 'NotSubmitted':
			return true;
		case 'SentForRevision':
			return true;
		case 'SentForApproval':
			if (
				arr?.approversL1.find(e => e === userEmail) ||
				arr?.approversL2.find(e => e === userEmail) ||
				arr?.approversL2.find(e => e === userEmail)
			)
				return false;
			return true;

		default:
			return true;
	}
};

export const reasonForNotAllowed = (from, resp) => {
	if (from === 'reject') {
		return ErrorModal(
			`Rejection Not Allowed: Status - ${resp?.approvalStatus} `,
		);
	}

	if (from === 'approve') {
		return ErrorModal(
			`Approval Not Allowed: Status - ${resp?.approvalStatus} `,
		);
	}

	return null;
};

export const checkApprovalLevel = (
	approvers,
	actualWriteOff,
	approverWriteOff,
) => {
	if (
		approvers.approversL1.length &&
		approvers.approversL2.length &&
		actualWriteOff >= +approverWriteOff.writeOffApprovalThresholdL3
	) {
		return approvers.approversL3.length;
	}
	if (
		approvers.approversL1.length &&
		actualWriteOff >= +approverWriteOff.writeOffApprovalThresholdL2
	) {
		return approvers.approversL2.length;
	}
	if (actualWriteOff >= +approverWriteOff.writeOffApprovalThresholdL1) {
		return approvers.approversL1.length;
	}
	return !!(
		actualWriteOff < +approverWriteOff.writeOffApprovalThresholdL1 ||
		+approverWriteOff.writeOffApprovalThresholdL1 === null
	);
};

export const isDisableApprovalButton = (resonCode, approvalStatus) => {
	if (resonCode) return true;
	return !!(
		approvalStatus === APPROVAL_PENDING ||
		approvalStatus === APPROVED ||
		approvalStatus === AUTO_APPROVED
	);
};
export const isDisableButton = (status, reasonCodeStatus) =>
	!(status !== APPROVED && !reasonCodeStatus);
