/* eslint-disable arrow-body-style */
import axios from 'axios';
import { errorMessages, MAX_LIMIT_RECORD_BULK } from '../../../constants';
import HttpError from '../../../errors/HttpError';
import ErpCountService from '../../../services/ErpCountService';
import ErpCountActionTypes from './ErpCount.types';

const erpCountService = new ErpCountService();

// search ERP count
export const searchERPCountInstantStart = () => ({
	type: ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_START,
});

export const searchERPCountInstanceSuccess = erpCountMap => ({
	type: ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_SUCCESS,
	payload: erpCountMap,
});

export const searchERPCountInstanceFailure = errorMessage => ({
	type: ErpCountActionTypes.SEARCH_ERP_COUNT_INSTANCE_FAILURE,
	payload: errorMessage,
});

export const searchERPCountInstanceStartAsync = (
	id,
	offset,
	limit,
	searchQuery,
) => {
	return dispatch => {
		const erpCountResponse = erpCountService.searchErpCountInstanceFactory(
			id,
			offset,
			limit,
			searchQuery,
		);
		if (!erpCountResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		dispatch(searchERPCountInstantStart());
		erpCountResponse
			.then(erpCountInstancesSearchDataMap => {
				dispatch(
					searchERPCountInstanceSuccess({
						result: erpCountInstancesSearchDataMap.data.searchedErpCountData,
						totalCount: erpCountInstancesSearchDataMap.data.searchedErpCount,
					}),
				);
			})
			.catch(error => dispatch(searchERPCountInstanceFailure(error.message)));
	};
};

// get ERP count Details
export const getErpCountDetailsStart = () => ({
	type: ErpCountActionTypes.GET_ERP_COUNT_DETAIL_START,
});

export const getErpCountDetailsSuccess = erpCountMap => ({
	type: ErpCountActionTypes.GET_ERP_COUNT_DETAIL_SUCCESS,
	payload: erpCountMap,
});

export const getErpCountDetailsFailure = errorMessage => ({
	type: ErpCountActionTypes.GET_ERP_COUNT_DETAIL_FAILURE,
	payload: errorMessage,
});

export const getErpCountDetailsStartAsync = id => {
	return dispatch => {
		const erpCountDetailResponse = erpCountService.getErpCountDetails(id);
		dispatch(getErpCountDetailsStart());

		if (!erpCountDetailResponse) {
			throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
		}
		erpCountDetailResponse
			.then(erpCountDetailDataMap => {
				dispatch(
					getErpCountDetailsSuccess(
						erpCountDetailDataMap
							? erpCountDetailDataMap.createdDateTime
							: null,
					),
				);
			})
			.catch(error => dispatch(getErpCountDetailsFailure(error.message)));
	};
};

// erp bulk export
export const bulkExportErpCountInstanceStart = () => ({
	type: ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_START,
});

export const bulkExportErpCountInstanceSuccess = erpCountMap => ({
	type: ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_SUCCESS,
	payload: erpCountMap,
});

export const bulkExportErpCountInstanceFailure = errorMessage => ({
	type: ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_FAILURE,
	payload: errorMessage,
});

export const bulkExportErpCountInstanceAsync =
	(totalRecord, id, searchQuery, year) => dispatch => {
		const { url } = erpCountService;
		const concatUrl = [];
		const erpCountData = [];
		for (
			let index = 0;
			index < Math.ceil(totalRecord / MAX_LIMIT_RECORD_BULK);
			index += 1
		) {
			concatUrl.push(
				`${url}/erp-auditCount?countType=erpCount&scheduleCountid=${id}&searchField=${searchQuery}&limit=${MAX_LIMIT_RECORD_BULK}&offset=${
					index * MAX_LIMIT_RECORD_BULK
				}&year=${year}`,
			);
		}
		dispatch(bulkExportErpCountInstanceStart());
		Promise.all(
			concatUrl.map(endpoint => erpCountService.getErpCount(endpoint)),
		)
			.then(
				axios.spread((...bulkData) => {
					bulkData.forEach(result => {
						erpCountData.push(...result.data.searchedErpCountData);
					});
					dispatch(
						bulkExportErpCountInstanceSuccess({
							result: erpCountData,
						}),
					);
				}),
			)
			.catch(error =>
				dispatch(bulkExportErpCountInstanceFailure(error.message)),
			);
	};
