const CountInstanceActionTypes = {
	SEARCH_COUNT_INSTANCE_START: 'SEARCH_COUNT_INSTANCE_START',
	SEARCH_COUNT_INSTANCE_SUCCESS: 'SEARCH_COUNT_INSTANCE_SUCCESS',
	SEARCH_COUNT_INSTANCE_FAILURE: 'SEARCH_COUNT_INSTANCE_FAILURE',
	ADD_COUNT_INSTANCE_START: 'ADD_COUNT_INSTANCE_START',
	ADD_COUNT_INSTANCE_SUCCESS: 'ADD_COUNT_INSTANCE_SUCCESS',
	ADD_COUNT_INSTANCE_FAILURE: 'ADD_COUNT_INSTANCE_FAILURE',
	BULK_EXPORT_COUNT_INSTANCE_START: 'BULK_EXPORT_COUNT_INSTANCE_START',
	BULK_EXPORT_COUNT_INSTANCE_SUCCESS: 'BULK_EXPORT_COUNT_INSTANCE_SUCCESS',
	BULK_EXPORT_COUNT_INSTANCE_FAILURE: 'BULK_EXPORT_COUNT_INSTANCE_FAILURE',
	DELETE_COUNT_INSTANCE_START: 'DELETE_COUNT_INSTANCE_START',
	DELETE_COUNT_INSTANCE_SUCCESS: 'DELETE_COUNT_INSTANCE_SUCCESS',
	DELETE_COUNT_INSTANCE_FAILURE: 'DELETE_COUNT_INSTANCE_START',
	CLOSE_COUNT_INSTANCE_START: 'CLOSE_COUNT_INSTANCE_START',
	CLOSE_COUNT_INSTANCE_SUCCESS: 'CLOSE_COUNT_INSTANCE_SUCCESS',
	CLOSE_COUNT_INSTANCE_FAILURE: 'CLOSE_COUNT_INSTANCE_FAILURE',
	SHOW_CLOSE_COUNT_INSTANCE_MODAL: 'SHOW_CLOSE_COUNT_INSTANCE_MODAL',
	GET_COUNT_INSTANCE_FILTERS_START: 'GET_COUNT_INSTANCE_FILTERS_START',
	GET_COUNT_INSTANCE_FILTERS_SUCCESS: 'GET_COUNT_INSTANCE_FILTERS_SUCCESS',
	GET_COUNT_INSTANCE_FILTERS_FAILURE: 'GET_COUNT_INSTANCE_FILTERS_FAILURE',
};

export default CountInstanceActionTypes;
