import { Button, Form, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import JnjLogo from '../../../assets/icons/JnJ_MedTech.png';
import { ReactComponent as InternationalizationIcon } from '../../../assets/icons/internationalization-icon.svg';
import { ReactComponent as BrandingLogo } from '../../../assets/icons/smart-logo.svg';
import { supportedLanguages } from '../../../config/config';
import { PRIVATE_ROUTE_BASE } from '../../../constants';
import {
	ssoSignInFailure,
	ssoSignInStartAsync,
} from '../../../redux/User/User.actions';
import {
	selectIsUserLoginIn,
	selectUserLoginError,
	selectUserLoginSuccess,
} from '../../../redux/User/User.selector';
import { selectTranslations, setLang } from '../../../redux/i18n/i18nSlice';
import UserService from '../../../services/UserService';

const { Option } = Select;

function Login({
	history,
	ssoSignIn,
	isSigningIn,
	errorSignIn,
	successSignIn,
}) {
	const t = useSelector(selectTranslations);
	const lang = useSelector(state => state.i18n.lang);
	const dispatch = useDispatch();
	const userService = new UserService();

	const handleLangChange = newLang => {
		dispatch(setLang(newLang));
	};
	const onFinish = () => {
		userService.showLogin();
	};

	const onFinishFailed = errorInfo => errorInfo;

	const LanguageDropdown = (
		<Select onChange={handleLangChange} defaultValue={lang} bordered={false}>
			{Object.entries(supportedLanguages).map(([key, value]) => (
				<Option key={key}>{value}</Option>
			))}
		</Select>
	);
	const params = Object.fromEntries(
		new URLSearchParams(window.location.search).entries(),
	);

	useEffect(() => {
		if (params.code) {
			ssoSignIn(params.code);
		}
		if (params.error) {
			dispatch(ssoSignInFailure(params.error.replace('_', ' ')));
		}
	}, []);

	useEffect(() => {
		if (isSigningIn === false && successSignIn === true) {
			/* if the user successfully login, check if there
			is redirect path and redirect to the page.
			if there is not redirect path, push it into /smart */
			const storedRedirectURL =
				localStorage?.getItem(`redirect-url`) !== null
					? JSON.parse(localStorage.getItem(`redirect-url`))
					: PRIVATE_ROUTE_BASE;
			localStorage?.removeItem(`redirect-url`);
			history.push(`${storedRedirectURL}`);
		}
	}, [isSigningIn]);

	if (isSigningIn) return <Spin className="flex justify-center p-64" />;
	return (
		<div className=" flex h-screen w-screen">
			<div className="m-auto">
				<div className="flex justify-between">
					<span className="flex w-60 h-10">
						<BrandingLogo />
						<img alt="JnjLogo" src={JnjLogo} />
					</span>
					<span>
						<InternationalizationIcon />
						{LanguageDropdown}
					</span>
				</div>
				<Form
					initialValues={{
						remember: true,
					}}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					className="container border-2 shadow-md py-10 px-20 max-w-md">
					<Form.Item>
						<div className="flex justify-center text-jnj_red">
							{errorSignIn || ''}
						</div>
						<div className="flex justify-center font-bold">
							Login to your account
						</div>
					</Form.Item>
					<Form.Item>
						<div className="md:flex md:space-x-20 sm:flex">
							<Button type="primary" className="w-60 my-2" htmlType="submit">
								{t.ExternalLogin}
							</Button>
						</div>
					</Form.Item>
					<Form.Item>
						<Link
							className="text-gray-400"
							target="_blank"
							to="/privacy-policy">
							{t.PrivacyPolicy}
						</Link>
						<span className="text-gray-500 px-2">|</span>
						<Link
							className="text-gray-400"
							target="_blank"
							to="/terms-and-conditions">
							{t.TermsAndConditions}
						</Link>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	isSigningIn: selectIsUserLoginIn,
	errorSignIn: selectUserLoginError,
	successSignIn: selectUserLoginSuccess,
});
const mapDispatchToProps = dispatch => ({
	ssoSignIn: code => dispatch(ssoSignInStartAsync(code)),
	ssoSignInFailure: error => dispatch(ssoSignInFailure(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
