import ErpCountActionTypes from '../ErpCount.types';

const INITIAL_STATE = {
	isErpDetailFetching: false,
	detailErpData: null,
	errorErpDetailMessage: null,
};

const erpCountGetDetailReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case ErpCountActionTypes.GET_ERP_COUNT_DETAIL_START:
			return {
				...state,
				isErpDetailFetching: true,
			};
		case ErpCountActionTypes.GET_ERP_COUNT_DETAIL_SUCCESS:
			return {
				...state,
				isErpDetailFetching: false,
				detailErpData: payload,
			};
		case ErpCountActionTypes.GET_ERP_COUNT_DETAIL_FAILURE:
			return {
				...state,
				isErpDetailFetching: false,
				errorErpDetailMessage: payload,
			};
		default:
			return state;
	}
};

export default erpCountGetDetailReducer;
