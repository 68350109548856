import ConfigurationActionTypes from './Config.types';

const INITIAL_STATE = {
	isFetching: false,
	isUpdate: false,
	configurations: null,
	errorMessage: null,
	isSearching: false,
	approversList: null,
};

// eslint-disable-next-line default-param-last
const configReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// Fetch configuration api
		case ConfigurationActionTypes.FETCH_CONFIG_START:
			return {
				...state,
				isFetching: true,
				errorMessage: null,
			};
		case ConfigurationActionTypes.UPDATE_CONFIG_START:
			return {
				...state,
				isUpdate: true,
				errorMessage: null,
			};
		case ConfigurationActionTypes.FETCH_CONFIG_SUCCESS:
			return {
				...state,
				isFetching: false,
				configurations: action.payload,
				errorMessage: null,
			};
		case ConfigurationActionTypes.UPDATE_CONFIG_SUCCESS:
			return {
				...state,
				isUpdate: false,
				configurations: action.payload,
				errorMessage: null,
			};
		case ConfigurationActionTypes.FETCH_CONFIG_FAILURE:
		case ConfigurationActionTypes.UPDATE_CONFIG_FAILURE:
		case ConfigurationActionTypes.SEARCH_FOR_APPROVER_FAILURE:
			return {
				...state,
				isFetching: false,
				isUpdate: false,
				errorMessage: action.payload,
			};
		case ConfigurationActionTypes.SEARCH_FOR_APPROVER_START:
			return {
				...state,
				isSearching: true,
				errorMessage: null,
			};
		case ConfigurationActionTypes.SEARCH_FOR_APPROVER_SUCCESS:
			return {
				...state,
				isSearching: false,
				approversList: action.payload.searchedData,
				errorMessage: null,
			};

		default:
			return state;
	}
};

export default configReducer;
