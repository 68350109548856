import { createSelector } from 'reselect';

const selectSingleCountInstance = state => state.singleCountInstance;

export const selectCountInstance = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.countInstance,
);
export const selectCountInstanceError = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.errorCountInstance,
);
export const selectIsCountInstanceFetching = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.isCountInstanceFetching,
);
export const selectIsCountInstanceLoaded = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => !!singleCountInstance.countInstance,
);

// Update count instance
export const selectCountInstanceIsUpdating = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.isCountInstanceUpdating,
);

export const selectUpdateCountInstanceSuccess = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.successUpdateCountInstance,
);
export const selectUpdateCountInstanceError = createSelector(
	[selectSingleCountInstance],
	singleCountInstance => singleCountInstance.errorUpdateCountInstance,
);
