/* eslint-disable react/jsx-props-no-spreading */
import { Button, Modal, Typography } from 'antd';
import React from 'react';

function SessionTimeoutModal({ open, countdown, onLogout, onContinue }) {
	return (
		<Modal open={open} closable={false} footer={null}>
			<div className="py-2 font-bold flex justify-center">Session Timeout</div>
			<div className="px-5">
				<Typography>
					The current session is about to expire in
					<span className="text-jnj_red px-2">{countdown}</span>
					seconds.
				</Typography>
				<Typography>Would you like to continue the session?</Typography>
			</div>
			<div className="flex justify-center p-4">
				<Button type="primary" onClick={onLogout}>
					Logout
				</Button>
				<Button onClick={onContinue}>Continue Session</Button>
			</div>
		</Modal>
	);
}

export default SessionTimeoutModal;
