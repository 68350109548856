import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { fileName } from '../constants';

const exportPdf = (data, header, title, mappedData) => {
	const unit = 'pt';
	const size = 'A2';
	const orientation = 'landscape';
	const marginLeft = 20;
	const doc = jsPDF(orientation, unit, size);
	doc.setFontSize(15);
	const exportData = data.map(mappedData);
	doc.text(title, marginLeft, 22);
	doc.autoTable({
		startY: 50,
		head: header,
		body: exportData,
		styles: { overflow: 'linebreak' },
		columnStyles: {
			0: { cellWidth: 65 },
			1: { cellWidth: 65 },
			2: { cellWidth: 65 },
			3: { cellWidth: 65 },
			4: { cellWidth: 65 },
			5: { cellWidth: 65 },
			6: { cellWidth: 65 },
			7: { cellWidth: 65 },
			8: { cellWidth: 65 },
			9: { cellWidth: 65 },
			10: { cellWidth: 65 },
			11: { cellWidth: 65 },
			12: { cellWidth: 65 },
			13: { cellWidth: 65 },
			14: { cellWidth: 65 },
			15: { cellWidth: 65 },
			16: { cellWidth: 65 },
			17: { cellWidth: 65 },
			18: { cellWidth: 65 },
			19: { cellWidth: 65 },
			20: { cellWidth: 65 },
			21: { cellWidth: 65 },
			22: { cellWidth: 65 },
			23: { cellWidth: 65 },
			24: { cellWidth: 65 },
		},
	});
	doc.save(`${title}.pdf`);
};

export const generateFileName = (name, dateFormat, id = null) => {
	if (name === fileName.Variance_Analysis)
		return `${id}_Variance_Analysis_${moment().format(dateFormat).toString()}`;

	return `${name}_${moment().format(dateFormat).toString()}`;
};

export default exportPdf;
