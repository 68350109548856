/* eslint-disable no-param-reassign */
import axios from 'axios';
import { app, REQUEST_CANCEL, REQUEST_TIMEOUT } from '../constants';

const source = axios.CancelToken.source();
axios.defaults.headers.post[app.REQUEST_HEADER_NAME] = app.Name;

axios.defaults.headers.common.client_id = process.env.REACT_APP_CLIENT_ID_KEY;
axios.defaults.headers.common.client_secret =
	process.env.REACT_APP_SECRET_ID_KEY;
axios.defaults.headers.common['X-Content-Type-Options'] = 'nosniff';
axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN';
axios.defaults.headers.common['X-XSS-Protection'] = '1; mode=block';
axios.defaults.headers.common['Strict-Transport-Security'] =
	'max-age=31536000; includeSubDomains';

axios.interceptors.request.use(
	config => config,
	error => Promise.reject(error),
);

let instance;
export default class HttpService {
	constructor() {
		if (!instance) {
			this.middleware = [];
			instance = this;
		}
		// eslint-disable-next-line no-constructor-return
		return instance;
	}

	// eslint-disable-next-line class-methods-use-this
	apiAxios(method, url, params) {
		return new Promise((resolve, reject) => {
			const headers = {
				'Content-Type':
					method === 'POST' || method === 'PUT' || method === 'PATCH'
						? 'application/json;charset=UTF-8'
						: 'application/x-www-form-urlencoded;charset=UTF-8',
			};
			axios({
				method,
				url,
				data:
					method === 'POST' || method === 'PUT' || method === 'PATCH'
						? params
						: null,
				params: method === 'GET' || method === 'DELETE' ? params : null,
				headers,
				timeout: REQUEST_TIMEOUT,
				cancelToken: source.token,
			})
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					if (axios.isCancel(err)) {
						source.cancel(REQUEST_CANCEL);
					} else {
						this.handleErrors(err);
					}
					reject(err);
				});
		});
	}

	// eslint-disable-next-line class-methods-use-this
	handleErrors(error) {
		if (error.name === 'AbortError') {
			throw error;
		}
		process.env.NODE_ENV !== 'production' && console.error(error); // eslint-disable-line
		return null;
	}

	get({ url, queryParams }) {
		return this.apiAxios('GET', url, queryParams);
	}

	post({ url, data }) {
		const isFormDataObject = data instanceof FormData;
		return this.apiAxios(
			'POST',
			url,
			isFormDataObject ? data : JSON.stringify(data),
		);
	}

	put({ url, data }) {
		const isFormDataObject = data instanceof FormData;
		return this.apiAxios(
			'PUT',
			url,
			isFormDataObject ? data : JSON.stringify(data),
		);
	}

	patch({ url, data }) {
		const isFormDataObject = data instanceof FormData;
		return this.apiAxios(
			'PATCH',
			url,
			isFormDataObject ? JSON.stringify(data) : data,
		);
	}

	delete({ url, queryParams = null }) {
		return this.apiAxios('DELETE', url, queryParams);
	}
}
