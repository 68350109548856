import { Button, Dropdown, Menu, message } from 'antd';
import 'jspdf-autotable';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import * as XLSX from 'xlsx/xlsx.mjs';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export-down-icon.svg';
import { MAX_LIMIT_RECORD_BULK } from '../../../constants';
import { bulkExportVarianceAnalysisSAPStartAsync } from '../../../redux/VarianceAnalysis/VarianceAnalysis.action';
import {
	selectBulkExportSAP,
	selectBulkExportSAPError,
	selectBulkExportSAPFetching,
} from '../../../redux/VarianceAnalysis/VarianceAnalysis.selectors';
import { selectTranslations } from '../../../redux/i18n/i18nSlice';

// ExportDataMenu
function ExportDataMenuSAP({
	bulkData,
	isBulkRequestFetching,
	// totalRecord,
	bulkExportHandler,
	errorMessage,
}) {
	const t = useSelector(selectTranslations);
	const params = useParams();

	const exportExcelHandler = () => {
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(bulkData);
		XLSX.utils.book_append_sheet(workbook, worksheet);
		XLSX.writeFileXLSX(workbook, params.uuid.concat('.xlsx'));
	};

	// List of Menu
	const menu = (
		<>
			{errorMessage ? message.error(errorMessage) : null}
			<Menu
				disabled={
					isBulkRequestFetching || !!errorMessage || bulkData?.length === 0
				}>
				<Menu.Item key="2" onClick={() => exportExcelHandler()}>
					<span className="pl-10 pr-7 font-bold"> Excel For SAP </span>
					<DownloadIcon />
				</Menu.Item>
			</Menu>
		</>
	);
	return (
		<Dropdown
			className="border-l border-solid"
			overlay={menu}
			trigger={['click']}>
			<Button
				disabled={isBulkRequestFetching}
				loading={isBulkRequestFetching}
				onClick={() => bulkExportHandler(MAX_LIMIT_RECORD_BULK, params.uuid)}>
				<span className="pr-3 relative bottom-1">{t.Export}</span>
				<ExportIcon />
			</Button>
		</Dropdown>
	);
}
// Map dispatch to props
const mapStateToProps = createStructuredSelector({
	bulkData: selectBulkExportSAP,
	errorMessage: selectBulkExportSAPError,
	isBulkRequestFetching: selectBulkExportSAPFetching,
});

const mapDispatchToProps = dispatch => ({
	bulkExportHandler: (totalRecords, countInstanceId) =>
		dispatch(
			bulkExportVarianceAnalysisSAPStartAsync(totalRecords, countInstanceId),
		),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportDataMenuSAP);
