import { createSelector } from 'reselect';

const selectExportLocation = state => state.exportLocation;
const selectGetLocation = state => state.getLocation;
const selectUploadLocation = state => state.uploadLocation;

export const selectLocations = createSelector(
	[selectGetLocation],
	getLocation => getLocation.locations,
);

export const selectLocationFilters = createSelector(
	[selectGetLocation],
	getLocation => getLocation.filters,
);

export const selectLocationFiltersErrorMessage = createSelector(
	[selectGetLocation],
	getLocation => getLocation.errorFilterMessage,
);

export const selectIsLocationLoaded = createSelector(
	[selectGetLocation],
	getLocation => !!getLocation.locations && !!getLocation.filters,
);

export const selectIsLocationFetching = createSelector(
	[selectGetLocation],
	getLocation => getLocation.isFetching,
);

export const selectTotalLocationRecord = createSelector(
	[selectGetLocation],
	getLocation => getLocation.totalNumberOfRecord,
);

export const selectLocationError = createSelector(
	[selectGetLocation],
	getLocation => getLocation.errorMessage,
);

export const selectBulkLocationExportRecord = createSelector(
	[selectExportLocation],
	exportLocation => exportLocation.bulkLocationData,
);

export const selectBulkLocationExportFetching = createSelector(
	[selectExportLocation],
	exportLocation => exportLocation.isBulkRequestFetching,
);

export const selectCsvUploadFetching = createSelector(
	[selectUploadLocation],
	uploadLocation => uploadLocation.isCsvUploadFetching,
);
export const selectIsSearchActive = createSelector(
	[selectGetLocation],
	getLocation => getLocation.isSearchActive,
);
export const selectIsCountStatusLoading = createSelector(
	[selectGetLocation],
	getLocation => getLocation.loading,
);
