import UserActionTypes from '../User.types';

const INITIAL_STATE = {
	isBulkRequestFetching: false,
	bulkUserListData: null,
	errorBulkMessage: null,
};

const exportUserListReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case UserActionTypes.BULK_EXPORT_USER_LIST_START:
			return {
				...state,
				isBulkRequestFetching: true,
			};
		case UserActionTypes.BULK_EXPORT_USER_LIST_SUCCESS:
			return {
				...state,
				isBulkRequestFetching: false,
				bulkUserListData: payload.result,
			};
		case UserActionTypes.BULK_EXPORT_USER_LIST_FAILURE:
			return {
				...state,
				isBulkRequestFetching: false,
				errorBulkMessage: payload,
			};
		default:
			return state;
	}
};

export default exportUserListReducer;
