import { createSelector } from 'reselect';

const selectGetCurrentVarianceAnalysis = state =>
	state.getCurrentVarianceAnalysis;

export const selectFranchiseSubFranchiseReasonCode = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.franchiseSubFranchiseReasonCode,
);

export const selectErrorFranchiseSubFranchiseReasonCode = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.errorFranchiseReasonCode,
);

export const selectIsFranchiseReasonCodeFetching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.isFranchiseReasonCodeFetching,
);
export const selectIsFranchiseSubFranchiseReasonCodeLoaded = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.isFranchiseSubFranchiseRCodeLoaded,
);

export const selectCurrentVarianceAnalysis = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.currentVarianceAnalysis,
);

export const selectCurrentVarianceAnalysisError = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.errorMessage,
);

export const selectCurrentVarianceAnalysisIsSearching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.isFetching,
);

export const selectCurrentVarianceAnalysisTotalRecords = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.totalCount,
);

export const selectIsCurrentVarianceAnalysisLoaded = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		!!getCurrentVarianceAnalysis.currentVarianceAnalysis,
);

const selectVarianceAnalysisExport = state => state.getVarianceAnalysisExport;

export const selectIsInitialVarianceAnalysisExportLoading = createSelector(
	[selectVarianceAnalysisExport],
	getVarianceAnalysisExport => getVarianceAnalysisExport.isDownloadInit,
);
export const selectIsVarianceAnalysisGridExportLoading = createSelector(
	[selectVarianceAnalysisExport],
	getVarianceAnalysisExport => getVarianceAnalysisExport.isExportGrid,
);

export const selectInitialVarianceAnalysisExportData = createSelector(
	[selectVarianceAnalysisExport],
	getVarianceAnalysisExport =>
		getVarianceAnalysisExport.bulkVarianceAnalysisData,
);

export const selectBulkExportSAP = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.bulkExportSAP,
);

export const selectBulkExportSAPError = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.bulkExportSAPError,
);

export const selectBulkExportSAPFetching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.isbulkExportSAPFetching,
);

export const selectVarianceAnalysisSummary = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.summaryData,
);
export const selectVarianceAnalysisSummaryLoaded = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.summaryLoading,
);
export const selectShowSummaryModal = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.showSummaryModal,
);

export const selectWorkflowStatus = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.workflowStatus,
);
export const selectWorkflowStatusFetching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.isWorkflowFetching,
);
export const selectWorkflowInit = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis => getCurrentVarianceAnalysis.workflowInit,
);
export const selectWorkflowInitFetching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.isWorkflowInitFetching,
);
export const selectWorkflowStepFetching = createSelector(
	[selectGetCurrentVarianceAnalysis],
	getCurrentVarianceAnalysis =>
		getCurrentVarianceAnalysis.isWorkflowStepFetching,
);
