import url from '../config/url';
import HttpService from './HttpService';

export default class SendNotificationService {
	constructor() {
		/* http Service */
		this.http = new HttpService();
		/*  get send notification endpoint */
		this.url = `${url.backendEndpoints.sendNotification}`;
	}
	/* send Notification Factory */

	async sendNotificationFactory(emailRequest) {
		const { data } = await this.http.post({
			/* url */
			url: `${this.url}`,
			/* data */
			data: emailRequest,
		});
		/* return data */
		return data;
	}
}
