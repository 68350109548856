import { Modal } from 'antd';
import React from 'react';
import warningSvg from '../assets/icons/warning.svg';

const WarningModal = (messageToDisplay, handleConfirm) =>
	Modal.confirm({
		content: (
			<div className="pb-10">
				<div className="text-jnj_black font-bold text-lg flex text-center">
					<div>{messageToDisplay}</div>
				</div>
			</div>
		),
		icon: (
			<div className="pt-10 flex pb-4 justify-center">
				<div>
					<img src={warningSvg} alt="React Logo" />
				</div>
			</div>
		),
		okText: 'Proceed',
		onOk: handleConfirm,
		width: 500,
	});

export default WarningModal;
