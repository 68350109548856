import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import configReducer from './Configuration/Config.reducer';
import deleteCountInstanceReducer from './CountInstance/Reducers/CountInstance.delete.reducer';
import exportCountInstanceReducer from './CountInstance/Reducers/CountInstance.export.reducer';
import getCountInstanceReducer from './CountInstance/Reducers/CountInstance.get.reducer';
import postCountInstanceReducer from './CountInstance/Reducers/CountInstance.post.reducer';
import auditCountExportReducer from './CountInstanceDetails/AuditCount/Reducers/AuditCount.export.reducer';
import auditCountGetReducer from './CountInstanceDetails/AuditCount/Reducers/AuditCount.get.reducer';
import auditCountSignatureReducer from './CountInstanceDetails/AuditCount/Reducers/AuditCount.signature.reducer';
import auditCountUploadReducer from './CountInstanceDetails/AuditCount/Reducers/AuditCount.upload.reducer';
import countInstanceDetailReducer from './CountInstanceDetails/CountInstanceDetails.reducer';
import singleCountInstance from './CountInstanceDetails/CountInstanceSingle/CountInstanceSingle.reducer';
import erpCountGetDetailReducer from './CountInstanceDetails/ErpCount/Reducers/ErpCount.detail.reducer';
import erpCountExportReducer from './CountInstanceDetails/ErpCount/Reducers/ErpCount.export.reducer';
import erpCountGetReducer from './CountInstanceDetails/ErpCount/Reducers/ErpCount.get.reducer';
import countryReducer from './CountryList/CountryList.reducer';
import i18nReducer from './i18n/i18nSlice';
import locationExportReducer from './Location/Location.export.reducer';
import locationGetReducer from './Location/Location.get.reducer';
import locationUploadReducer from './Location/Location.upload.reducer';
import exportUserListReducer from './User/Reducers/UserList.export.reducer';
import userReducer from './User/User.reducer';
import varianceAnalysisExportReducer from './VarianceAnalysis/Reducers/VarianceAnalysis.export.reducer';
import getCurrentVarianceAnalysisReducer from './VarianceAnalysis/Reducers/VarianceAnalysis.get.reducer';
import varianceAnalysisImportReducer from './VarianceAnalysis/Reducers/VarianceAnalysis.import.reducer';

const languagePersistConfig = {
	key: 'language',
	storage,
	whitelist: ['lang'],
};

const loggedInUserPersistConfig = {
	key: 'loggedInUser',
	storage,
	whitelist: ['loggedInUser', 'erpType'],
};
const rootReducer = combineReducers({
	getConfigurations: configReducer,
	getCountriesList: countryReducer,
	getCountInstance: getCountInstanceReducer,
	exportCountInstance: exportCountInstanceReducer,
	exportUserList: exportUserListReducer,
	postCountInstance: postCountInstanceReducer,
	deleteCountInstance: deleteCountInstanceReducer,
	countInstanceDetail: countInstanceDetailReducer,
	getErpCount: erpCountGetReducer,
	exportErpCount: erpCountExportReducer,
	uploadAuditCount: auditCountUploadReducer,
	singleCountInstance,
	getAuditCount: auditCountGetReducer,
	exportAuditCount: auditCountExportReducer,
	getErpCountDetail: erpCountGetDetailReducer,
	getLocation: locationGetReducer,
	exportLocation: locationExportReducer,
	uploadLocation: locationUploadReducer,
	signatureDownload: auditCountSignatureReducer,
	getCurrentVarianceAnalysis: getCurrentVarianceAnalysisReducer,
	user: persistReducer(loggedInUserPersistConfig, userReducer),
	i18n: persistReducer(languagePersistConfig, i18nReducer),
	getVarianceAnalysisExport: varianceAnalysisExportReducer,
	importVarianceAnalysis: varianceAnalysisImportReducer,
});

export default rootReducer;
