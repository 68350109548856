import CountInstanceDetailActionTypes from './CountInstanceDetails.types';

const INITIAL_STATE = {
	showEditCountInstanceModal: false,
	showSendNotificationModal: false,
	isNotificationSending: false,
};

const countInstanceDetailReducer = (state = INITIAL_STATE, { type } = {}) => {
	switch (type) {
		case CountInstanceDetailActionTypes.SHOW_EDIT_COUNT_INSTANCE_MODAL:
			return {
				...state,
				showEditCountInstanceModal: !state.showEditCountInstanceModal,
			};
		case CountInstanceDetailActionTypes.SHOW_SEND_NOTIFICATION_MODAL:
			return {
				...state,
				showSendNotificationModal: !state.showSendNotificationModal,
			};
		// Send Notification
		case CountInstanceDetailActionTypes.SEND_NOTIFICATION_START:
			return {
				...state,
				isNotificationSending: true,
			};
		case CountInstanceDetailActionTypes.SEND_NOTIFICATION_FAILURE:
		case CountInstanceDetailActionTypes.SEND_NOTIFICATION_SUCCESS:
			return {
				...state,
				isNotificationSending: false,
			};

		default:
			return state;
	}
};

export default countInstanceDetailReducer;
