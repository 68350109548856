export const checkArrayNull = object =>
	object && Array.isArray(object) && object.length;

export const checkNull = (object, attribute) => {
	if (!object) return true;
	const isNull = Object.fromEntries(
		Object.entries(object).filter(
			([key, value]) => key === attribute && value !== null,
		),
	);

	return Object.keys(isNull).length === 0;
};
