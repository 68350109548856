export const legalDocTypes = {
	TERMS: 'TERMS',
	PRIVACY: 'PRIVACY',
};

export const app = { NAME: 'SMART', REQUEST_HEADER_NAME: 'app-name' };

export const MAX_DECIMAL_LENGTH = 4;
export const COUNT_INSTANCE_INDEX_URL_HISTORY = 3;
export const REQUEST_CANCEL = 'Request Canceled';
export const REQUEST_TIMEOUT = '1000000';
export const ONE_SECOND_DELAY = 1000;
export const DEBOUNCE_SEARCH_DELAY = 500;
export const SESSION_TIMEOUT = 2700000;
export const PRIVATE_ROUTE_BASE = '/smart';
export const SEARCH_MIN_CHAR = 2;
export const DEFAULT_OFFSET_START = 0;
export const DEFAULT_LIMIT_START = 100;
export const MAX_LIMIT_SEARCH = 100;
export const XLSX_CSV_UPLOAD_ERROR =
	'File format not as expected, check fields and separator.';

export const UPLOAD_ACTIVE = 'Active';
export const UPLOAD_DISABLED = 'Disabled';
export const UPLOAD_HIDDEN = 'Hidden';
export const UPLOAD_ERROR_RESPONSE = 'Error Response';
export const JNJ_BLUE = 'text-jnj_blue';
export const JNJ_RED = 'text-jnj_red';

export const CREATED = 'Created';
export const AUDIT_COMPLETED = 'Audit Complete';
export const RECONCILIATION_IN_PROGRESS = 'Reconciliation in Progress';
export const CLOSED = 'Closed';
export const CANCELED = 'Canceled';
export const IN_PROGRESS = 'In Progress';

export const DELETE = 'Delete';
export const UPDATE = 'Update';

export const RISK_ASSESSMENT = 'Risk Assessment';
export const AUDIT_COUNT = 'Audit Count';
export const VARIANCE_ANALYSIS = 'Variance Analysis';

export const GRANT_TYPE = 'authorization_code';
export const AUTH_GRANT_TYPE =
	'urn:pingidentity.com:oauth2:grant_type:validate_bearer';
export const defaultDate = '1991-01-13';

export const userSTATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
};

export const httpStatus = {
	CREATED: 201,
	OK: 200,
	NOT_FOUND: 404,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	TOO_MANY_REQUEST: 429,
	FORBIDDEN_ACCESS: 403,
	SERVER_ERROR: 500,
	RESOURCE_CONFLICTED: 409,
};

export const errorMessages = {
	UNDEFINED_RESPONSE: 'Undefined HTTP response',
};

export const SENT_BACK_FROM_REVISION = 'SentForRevision';
export const APPROVED = 'Approved';
export const AUTO_APPROVED = 'Auto-approved';
export const APPROVAL_PENDING = 'SentForApproval';
export const RECORD_LEVEL_VALIDATION_ERROR =
	'VARIANCE:RECORD_LEVEL_VALIDATION_ERROR';
export const TOTAL_EXPECTED_QUANTITY_ERROR =
	'VARIANCE:TOTAL_EXPECTED_QUANTITY_ERROR';
export const HEADER_CHECK_VALIDATION_FAILED =
	'VARIANCE:HEADER_CHECK_VALIDATION_FAILED';

export const MAX_LIMIT_RECORD_BULK = 5000;

export const DEFAULT_SORT_KEY = 'id';
export const DEFAULT_SORT_ORDER = 'asc';
export const DEFAULT_SORT_KEY_USER = 'first_name';
export const DEFAULT_USER_STATUS = 'active';
export const DEFAULT_VISUALIZATION_MODE = 'lot';
export const PRODUCT = 'product';
export const DISCREPANCY_LOT = 'discrepancyLot';
export const DISCREPANCY_PRODUCT = 'discrepancyProduct';
export const LOT_LEVEL_FIELDS = [
	'productLot',
	'reasonCode',
	'reviewer',
	'comments',
	'expiryDate',
	'notes',
];

export const userPermissions = {
	LOCATION_MANAGEMENT: 'location',
	COUNT_INSTANCE_MANAGEMENT: 'count_instance',
	COUNT_INSTANCE_GRID: 'count_instance_grid',
	USER_MANAGEMENT: 'user_management',
	CONFIGURATION_MANAGEMENT: 'config_management',
	VARIANCE_ANALYSIS_MANAGEMENT: 'variance_analysis',
	VARIANCE_LOG_MANAGEMENT: 'variance_log_management',
	VARIANCE_APPROVAL_FUNCTION: 'variance_approval',
};

export const userManagementPermissions = {
	CONSIGNMENT_LEAD_CRUD: 'edit_cons_lead',
	CONSIGNMENT_ANALYST_CRUD: 'edit_cons_analyst',
};
export const webPermissions = [
	'variance_analysis',
	'variance_approval',
	'count_instance_grid',
	'variance_log_management',
	'user_management',
	'config_management',
	'count_instance',
	'location',
];

/* user Roles */
export const userRolesUI = {
	AUDITOR: 'AUDITOR',
	APPROVER: 'APPROVER',
	SYSTEM_ADMIN: 'CONSIGNMENT REGIONAL LEAD',
	CONSIGNMENT_ANALYST: 'CONSIGNMENT ANALYST',
	CONSIGNMENT_LEAD: 'CONSIGNMENT LEAD',
};

/* user Roles */
export const userRoles = {
	OPERATOR: 'auditor',
	AUDITOR: 'auditor',
	APPROVER: 'approver',
	SYSTEM_ADMIN: 'system_admin',
	CONSIGNMENT_LEAD: 'consignment_lead',
	CONSIGNMENT_ANALYST: 'consignment_analyst',
};
export const userRoleNames = {
	operator: 'AUDITOR',
	auditor: 'AUDITOR',
	sales_rep: 'SALES REP',
	approver: 'APPROVER',
	system_admin: 'CONSIGNMENT REGIONAL LEAD',
	consignment_lead: 'CONSIGNMENT LEAD',
	consignment_analyst: 'CONSIGNMENT ANALYST',
};

export const locationDataMapped = table => [
	table.cac,
	table.shipTo,
	table.shipToName,
	table.cacCustomerName,
	table.branch,
	table.location,
	table.franchiseCode,
	table.subfranchiseCode,
	table.subfranchiseDescription,
	table.totalConsignedValue,
	table.inventoryContribution,
	table.lastCountAccuracy,
	table.secondLastCountAccuracy,
	table.calculatedRiskRating,
	table.inventoryTurnRate,
	table.assignedRiskRating,
	table.statusRiskAssessment,
	table.plannedCountMonth,
	table.expectedCountNumber,
	table.actualCountNumber,
	table.lastCountDate,
	table.locationStatus,
	table.rationaleForDeviation,
	table.notes,
	table.productQuantity,
];

export const countInstanceDataMapped = table => [
	table.scheduledCountID,
	table.cac,
	table.shipTo,
	table.numberOfProducts,
	table.productQuantity,
	table.expectedStartDate,
	table.actualStartDate,
	table.expectedEndDate,
	table.actualEndDate,
	table.reconciliationEndDate,
	table.daysOpen,
	table.status,
	table.operator,
	table.approver,
	table.initTotQuantVar,
	table.finalTotQuantVar,
	table.initTotQuantVal,
	table.finalTotQuantVal,
	table.countAccuracy,
	table.variance_status,
	table.variance_approval,
	table.country,
];

export const userDataMapped = table => [
	table.roles,
	table.email,
	table.firstName,
	table.lastName,
	table.country,
	table.phoneNumber,
	table.status,
	table.lastLogin,
];

export const erpAuditCountDataMapped = table => [
	table.itemNumber,
	table.itemDescription,
	table.itemLotNum,
	table.itemLotNumExpry,
	table.itemCount,
	table.franchise,
];

export const varianceAnalysisDataMapped = table => [
	table.franchise,
	table.subFranchise,
	table.productNumber,
	table.productLot,
	table.expectedQuantity,
	table.hardCommit,
	table.expectedValue,
	table.blindCountQuantity,
	table.blindCountvalue,
	table.blindCountVariance,
	table.blindCountVarianceValue,
	table.initialVarianceValue,
	table.initialVariance,
	table.adjustmentQuantity,
	table.finalQuantity,
	table.finalVarianceValue,
	table.reasonCode,
	table.reviewer,
	table.notes,
	table.comments,
	table.lastUpdateDate,
];

export const FRANCHISE_DEP = 'DEP';
export const FRANCHISE_ETH = 'ETH';
export const logicOperators = { AND: 'AND', OR: 'OR' };

export const fileName = {
	RISK_ASSESSMENT: 'Risk Assessment',
	Count_Instance: 'Count Instance',
	Users_List: 'Users List',
	Audit_Count: 'Audit Count',
	ERP_Count: 'ERP Count',
	Variance_Analysis: 'Variance Analysis',
};
