import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { createStructuredSelector } from 'reselect';
import VarianceAnalysisReject from '../../components/VarianceAnalysis/VarianceAnalysisReject/VarianceAnalysisReject.component';
import { triggerWorkflowRedirectStartAsync } from '../../redux/VarianceAnalysis/VarianceAnalysis.action';
import { selectWorkflowStatusFetching } from '../../redux/VarianceAnalysis/VarianceAnalysis.selectors';

function VarianceAnalysisWorkstep({ triggerWorkflow, statusFetching }) {
	const location = useLocation();
	/* show reject state */
	const [showReject, setShowReject] = useState(false);
	const searchParams = new URLSearchParams(location.search);
	const [workflowStepParams] = useState({
		token: searchParams.get('token'),
		action: searchParams.get('action'),
		scheduledCountId: searchParams.get('scheduledCountId'),
	});
	useEffect(() => {
		if (workflowStepParams.action === 'approval') {
			triggerWorkflow(
				workflowStepParams.scheduledCountId,
				workflowStepParams.token,
				{
					action: 'approval',
				},
			);
		}
		if (workflowStepParams.action === 'rejection') {
			setShowReject(true);
		}
	}, []);

	const callBackHandler = payload => {
		triggerWorkflow(
			workflowStepParams.scheduledCountId,
			workflowStepParams.token,
			payload,
		);
		setShowReject(false);
	};
	return (
		<div>
			<span className="flex flex-col items-center justify-center h-screen">
				{statusFetching && <Spin />}
			</span>
			<VarianceAnalysisReject
				mode="emailTrigger"
				showReject={showReject}
				setShowReject={setShowReject}
				approveVarianceAnalysis={callBackHandler}
				id={workflowStepParams.scheduledCountId}
				workflowLoading={statusFetching}
			/>
		</div>
	);
}
const mapStateToProps = createStructuredSelector({
	statusFetching: selectWorkflowStatusFetching,
});
const mapDispatchToProps = dispatch => ({
	triggerWorkflow: (scheduledCountId, token, payload) =>
		dispatch(
			triggerWorkflowRedirectStartAsync({ scheduledCountId, token, payload }),
		),
});
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(VarianceAnalysisWorkstep);
