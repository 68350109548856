import UserActionTypes from './User.types';

const INITIAL_STATE = {
	// Authenticate User and User Perms
	isSignInFetch: false,
	errorSignIn: null,
	successSignIn: false,
	loggedInUser: null,
	erpType: null,
	// Fetch Users
	isFetching: false,
	errorFetch: null,
	userList: null,
	totalNumberOfRecord: null,
	// Search Users
	errorSearch: null,
	isSearching: false,
	// Edit User
	showUserModalForm: false,
	selectedUserToEdit: null,
	// Send Password Reset Link
	isResetLinkSending: false,
	successSendReset: null,
	errorSendReset: null,
	// Submit User
	isSubmitting: false,
	errorSubmitUser: null,
	successSubmitUser: null,
	// user filter by status
	userFilteredInfo: null,
	// user from external link
	userRedirectUrl: null,
};

// eslint-disable-next-line default-param-last
const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// User Madal
		case UserActionTypes.SHOW_USER_MODAL_FORM:
			return {
				...state,
				showUserModalForm: !state.showUserModalForm,
				selectedUserToEdit: action.payload,
			};
		// Authenticate User
		case UserActionTypes.SIGN_IN_START:
			return {
				...state,
				isSignInFetch: true,
			};
		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				isSignInFetch: false,
				successSignIn: true,
			};

		case UserActionTypes.SIGN_IN_FAILURE:
		case UserActionTypes.FETCH_LOGGED_IN_USER_FAILURE:
			return {
				...state,
				isSignInFetch: false,
				errorSignIn: action.payload,
			};
		// User Perms
		case UserActionTypes.FETCH_LOGGED_IN_USER_SUCCESS:
			return {
				...state,
				loggedInUser: action.payload,
			};
		// Logout
		case UserActionTypes.USER_LOGOUT:
			return {};
		// Fetch Users
		case UserActionTypes.DELETE_USERS_START:
		case UserActionTypes.SEARCH_USERS_START:
			return {
				...state,
				isFetching: true,
			};
		case UserActionTypes.SEARCH_USERS_SUCCESS:
			return {
				...state,
				isFetching: false,
				userList: !action.payload.searchedData?.length
					? action.payload.searchedData?.searchedData
					: action.payload.searchedData,
				totalNumberOfRecord: action.payload.searchedCount,
			};
		// Search User
		case UserActionTypes.SEARCH_USERS_FAILURE:
			return {
				...state,
				isFetching: false,
				errorSearch: action.payload,
			};
		case UserActionTypes.DELETE_USERS_SUCCESS:
			return {
				...state,
				isFetching: false,
				userList: state.userList.filter(
					x => x.id !== action.payload.data.message.id,
				),
			};
		// Send Password Reset Link
		case UserActionTypes.SEND_PASSWORD_RESET_START:
			return {
				...state,
				isResetLinkSending: true,
			};
		case UserActionTypes.SEND_PASSWORD_RESET_SUCCESS:
			return {
				...state,
				isResetLinkSending: false,
				successSendReset: action.payload,
			};
		case UserActionTypes.SEND_PASSWORD_RESET_FAILURE:
			return {
				...state,
				isResetLinkSending: false,
				errorSendReset: action.payload,
			};
		// Submit User
		case UserActionTypes.SUBMIT_USER_START:
			return {
				...state,
				isSubmitting: true,
			};
		case UserActionTypes.SUBMIT_USER_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				userList: [...state.userList, action.payload],
				successSubmitUser: 'User successfully created',
			};
		case UserActionTypes.EDIT_USER_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				successSubmitUser: 'User successfully updated',
				userList: state.userList.map(item => {
					if (item.id === action.payload?.userid) {
						return action.payload;
					}
					return item;
				}),
			};

		case UserActionTypes.SUBMIT_USER_FAILURE:
			return {
				...state,
				isSubmitting: false,
				errorSubmitUser: action.payload,
			};
		case UserActionTypes.USER_FILTER_STATUS:
			return {
				...state,
				userFilteredInfo: action.payload,
			};
		case UserActionTypes.USER_REDIRECT_URL:
			return {
				...state,
				userRedirectUrl: action.payload,
			};
		case UserActionTypes.SET_ERP_TYPE:
			return {
				...state,
				erpType: action.payload,
			};

		default:
			return state;
	}
};

export default userReducer;
