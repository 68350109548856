import ErpCountActionTypes from '../ErpCount.types';

const INITIAL_STATE = {
	isErpBulkRequestFetching: false,
	bulkErpCountInstanceData: null,
	errorErpBulkMessage: null,
};

const erpCountExportReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_START:
			return {
				...state,
				isErpBulkRequestFetching: true,
			};
		case ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_SUCCESS:
			return {
				...state,
				isErpBulkRequestFetching: false,
				bulkErpCountInstanceData: payload.result,
			};
		case ErpCountActionTypes.BULK_EXPORT_ERP_COUNT_FAILURE:
			return {
				...state,
				isErpBulkRequestFetching: false,
				errorErpBulkMessage: payload,
			};
		default:
			return state;
	}
};

export default erpCountExportReducer;
