import { Button, Popover, Select } from 'antd';
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ReactComponent as InternationalizationIcon } from '../../assets/icons/internationalization-icon.svg';
import { supportedLanguages } from '../../config/config';
import { logOut } from '../../redux/User/User.actions';
import { setLang } from '../../redux/i18n/i18nSlice';
import {
	getActiveUser,
	removeUserSession,
} from '../../utils/Authentication/UserSession.utils';
/* select option */
const { Option } = Select;
/* Header component */
function Header({ userLogOut }) {
	const lang = useSelector(state => state.i18n.lang);
	/* useDispatch */
	const dispatch = useDispatch();
	/* language change handler  */
	const handleLangChange = newLang => {
		dispatch(setLang(newLang));
	};
	/* language dropdown  */
	const LanguageDropdown = (
		<Select
			onChange={handleLangChange}
			defaultValue={lang}
			bordered={false}
			className="text-white">
			{Object.entries(supportedLanguages).map(([key, value]) => (
				<Option key={key}>{value}</Option>
			))}
		</Select>
	);

	const handleLogout = () => {
		removeUserSession();
		userLogOut();
		<Redirect to={{ pathname: '/login' }} />;
	};
	/* return */
	return (
		<div className="py-1 flex items-center justify-between lg:justify-end h-11 gap-4 pr-5 bg-jnj_red text-white">
			<div className="items-center justify-between flex gap-2 pl-10">
				<Popover
					content={
						<div className="items-center flex h-5">
							<Button>Profile</Button>
							<Button onClick={handleLogout}>Logout</Button>
						</div>
					}
					trigger="click">
					<div className="text-sm pointer cursor-pointer">
						{getActiveUser()
							? `${JSON.parse(getActiveUser()).given_name}  ${
									JSON.parse(getActiveUser()).family_name
							  }`
							: ''}
					</div>
				</Popover>
			</div>
			<div className="items-center justify-between flex">
				<div className="pt-1">
					<InternationalizationIcon />
				</div>
				{LanguageDropdown}
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	userLogOut: () => dispatch(logOut()),
});

export default connect(null, mapDispatchToProps)(Header);
