import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { userRoles } from '../../constants';
import { updateConfigStartAsync } from '../../redux/Configuration/Config.actions';
import {
	selectConfigList,
	selectIsUpdate,
} from '../../redux/Configuration/Config.selector';
import {
	selectLoggedInUserRoles,
	selectUserCountry,
} from '../../redux/User/User.selector';
import {
	ActiveTabFontSize,
	ArrowIcon,
	HideAndShowTab,
	TabsKey,
	isWriteOffThresholdValid,
	nullCheck,
	nullCheckAndSetToString,
	setToDefaultLGD,
	setToDefaultVA,
	setToDefaultVL,
} from '../../utils/Configuration/Configuration.utils';
import CountrySelector from './CountrySelector/CountrySelector.component';
import EmailComposerForm from './EmailComposerFrom/EmailComposerForm.component';
import Financial from './Financial/Financial.component';
import GridDefaultGrouping from './GridDefaultGrouping/GridDefaultGrouping.component';

function Configuration({
	countryCode,
	configurationsList,
	updateConfiguration,
	isUpdating,
	loggedInUserRoles,
}) {
	/* init state for sync consignment */
	const [syncConsignment, setSyncConsignment] = useState(false);
	/* init state for sync master */
	const [syncMaster, setSyncMaster] = useState(false);

	/* init state for open tab */
	const [openTab, setOpenTab] = React.useState(3);

	const [selectedCountry, setSelectedCountry] = useState(countryCode);

	const [form] = Form.useForm();
	const formRef = React.createRef();
	const getEmailSubject = values =>
		values.riskAssEmailSubject ? values.riskAssEmailSubject : '';
	const getEmailBody = values =>
		values.riskAssEmailBody ? values.riskAssEmailBody : '';
	const getReconciliationEmailSubject = values =>
		values.reconciliationEmailBody ? values.reconciliationEmailBody : '';
	const getReconciliationEmailBody = values =>
		values.reconciliationEmailBody ? values.reconciliationEmailBody : '';
	const handleSubmit = () => {
		form.validateFields().then(values => {
			if (!isWriteOffThresholdValid(values)) {
				const payLoad = {
					country: selectedCountry,
					riskAssessmentConfig: {
						approvers: !values.approvers
							? configurationsList?.riskAssessmentConfig.approvers
							: values.approvers,
						emailSubject: getEmailSubject(values),
						emailBody: getEmailBody(values),
					},
					reconConfig: {
						approversL1: !values.approversL1
							? configurationsList?.reconConfig.approversL1
							: values.approversL1,
						approversL2: !values.approversL2
							? configurationsList?.reconConfig.approversL2
							: values.approversL2,
						approversL3: !values.approversL3
							? configurationsList?.reconConfig.approversL3
							: values.approversL3,
						emailSubject: getReconciliationEmailSubject(values),
						emailBody: getReconciliationEmailBody(values),
					},
					gridDefaultGrouping: {
						location: values.locationGridDefault,
						franchiseBreakdown: values.franchiseBreakdown,
						combineDeputySpine: values.combineDeputy,
						varianceAnalysis: values.varianceAnalysis,
						varianceLog: values.varianceLog,
					},
					financial: {
						conversionRate: {
							usd: {
								gbp: values.usdGbp,
								eur: values.usdEur,
							},
							eur: {
								usd: values.eurUsd,
								gbp: values.eurGbp,
							},
							gbp: {
								usd: values.gbpUsd,
								eur: values.gbpEur,
							},
						},
						writeOffApprovalThresholdL1: values.writeOffThresholdL1,
						writeOffApprovalThresholdL2: values.writeOffThresholdL2,
						writeOffApprovalThresholdL3: values.writeOffThresholdL3,
					},
				};
				updateConfiguration(payLoad, countryCode);
			}
		});
	};

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			riskAssEmailSubject: nullCheckAndSetToString(
				configurationsList.riskAssessmentConfig.emailSubject,
			),
			riskAssEmailBody: nullCheckAndSetToString(
				configurationsList.riskAssessmentConfig.emailBody,
			),
			reconciliationEmailSubject: nullCheckAndSetToString(
				configurationsList.reconConfig.emailSubject,
			),
			reconciliationEmailBody: nullCheckAndSetToString(
				configurationsList.reconConfig.emailBody,
			),
			locationGridDefault: setToDefaultLGD(
				configurationsList.gridDefaultGrouping.location,
			),
			franchiseBreakdown:
				configurationsList.gridDefaultGrouping.franchiseBreakdown,
			combineDeputy: configurationsList.gridDefaultGrouping.combineDeputySpine,
			varianceAnalysis: setToDefaultVA(
				configurationsList.gridDefaultGrouping.varianceAnalysis,
			),
			varianceLog: setToDefaultVL(
				configurationsList.gridDefaultGrouping.varianceLog,
			),
			writeOffThresholdL1: nullCheck(
				configurationsList.financial.writeOffApprovalThresholdL1,
			),
			writeOffThresholdL2: nullCheck(
				configurationsList.financial.writeOffApprovalThresholdL2,
			),
			writeOffThresholdL3: nullCheck(
				configurationsList.financial.writeOffApprovalThresholdL3,
			),
			gbpUsd: nullCheck(configurationsList.financial.conversionRate.gbp.usd),
			gbpEur: nullCheck(configurationsList.financial.conversionRate.gbp.eur),
			usdGbp: nullCheck(configurationsList.financial.conversionRate.usd.gbp),
			usdEur: nullCheck(configurationsList.financial.conversionRate.usd.eur),
			eurUsd: nullCheck(configurationsList.financial.conversionRate.eur.usd),
			eurGbp: nullCheck(configurationsList.financial.conversionRate.eur.gbp),
		});
	}, [configurationsList, form]);

	/* render */
	return (
		<div className="md:px-36 py-12">
			<div className="border-2 grid grid-cols-2 pt-10 px-10 mb-7">
				<ul className="pr-10" role="tablist">
					<div className="pb-6">
						{/* country selector */}
						<CountrySelector
							countryCode={countryCode}
							setSelectedCountry={setSelectedCountry}
						/>
					</div>
					<div className="border-t-2 pb-6" />

					<div className="border-t-2 pb-6 hidden" />
					{/* Reconciliation Approvers and E-mails */}
					<li
						className={`text-jnj_black text-lg ${ActiveTabFontSize(
							openTab === TabsKey.optionThree,
						)} pb-6`}>
						<a
							onClick={e => {
								/* set state and prevent default */
								e.preventDefault();
								setOpenTab(TabsKey.optionThree);
							}}
							data-toggle="tab"
							href="#link3"
							role="tablist">
							<div className="flex gap-2 ">
								<div>Reconciliation Approvers and E-mails</div>
								{ArrowIcon(openTab === TabsKey.optionThree)}
							</div>
						</a>
					</li>
					<div className="border-t-2 pb-6" />
					{/* Grid Default Grouping */}
					<li
						className={`hidden text-jnj_black text-lg ${ActiveTabFontSize(
							openTab === TabsKey.optionFour,
						)} pb-6`}>
						<a
							onClick={e => {
								/* set state and prevent default */
								e.preventDefault();
								setOpenTab(TabsKey.optionFour);
							}}
							data-toggle="tab"
							href="#link4"
							role="tablist">
							<div className="flex gap-2 ">
								<div>Grid Default Grouping</div>
								{ArrowIcon(openTab === TabsKey.optionFour)}
							</div>
						</a>
					</li>
					<div className="border-t-2 pb-6 hidden" />
					{/* Financial */}
					<li
						className={`text-jnj_black text-lg ${ActiveTabFontSize(
							openTab === TabsKey.optionFive,
						)} pb-6`}>
						<a
							onClick={e => {
								/* set state and prevent default */
								e.preventDefault();
								setOpenTab(TabsKey.optionFive);
							}}
							data-toggle="tab"
							href="#link5"
							role="tablist">
							<div className="flex gap-2 ">
								<div>Financial</div>
								{ArrowIcon(openTab === TabsKey.optionFive)}
							</div>
						</a>
					</li>
				</ul>
				<div className="border-l-2 md:pl-10">
					<div className="">
						<div className="tab-content tab-space">
							{/* Data Synchronization tab */}
							<div
								className={HideAndShowTab(openTab === TabsKey.optionOne)}
								id="link1">
								<div className="px-16 py-48 grid grid-rows-2 gap-2">
									<div>
										<Button
											disabled
											style={{ borderColor: '#CA001B' }}
											className="border-2"
											loading={syncConsignment}
											onClick={() => setSyncConsignment(true)}
											block>
											{syncConsignment
												? 'Sync in progress'
												: 'Synch Consignment Location'}
										</Button>
									</div>
									<div>
										<Button
											disabled
											style={{ borderColor: '#CA001B' }}
											className="border-2"
											loading={syncMaster}
											onClick={() => setSyncMaster(true)}
											block>
											{syncMaster ? 'Sync in progress' : 'Synch Master Data'}
										</Button>
									</div>
								</div>
							</div>
							<Form form={form} onFinish={handleSubmit} ref={formRef}>
								{/* Email compose Form tab */}
								<div
									className={HideAndShowTab(openTab === TabsKey.optionTwo)}
									id="link2">
									{/* Email compose Form */}
									<div>
										<EmailComposerForm
											configurationsList={configurationsList}
										/>
									</div>
								</div>
								{/* Email compose Form tab with reconciliation approver tab */}
								<div
									className={HideAndShowTab(openTab === TabsKey.optionThree)}
									id="link3">
									{/* EmailComposerForm */}
									<div>
										<EmailComposerForm
											isReconciliationApprover
											configurationsList={configurationsList}
										/>
									</div>
								</div>
								{/* grid default grouping tab */}
								<div
									className={HideAndShowTab(openTab === TabsKey.optionFour)}
									id="link4">
									{/* GridDefaultGrouping */}
									<div>
										<GridDefaultGrouping />
									</div>
								</div>
								{/* Financial tab */}
								<div
									className={HideAndShowTab(openTab === TabsKey.optionFive)}
									id="link5">
									<div>
										<Financial />
									</div>
								</div>
								<div
									className={`${HideAndShowTab(
										openTab !== TabsKey.optionOne,
									)} text-center pb-10 pt-10`}>
									{/* Send button */}
									<Button
										type="primary"
										htmlType="submit"
										loading={isUpdating}
										disabled={loggedInUserRoles.includes(
											userRoles.SYSTEM_ADMIN,
										)}>
										Apply
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	updateConfiguration: (payload, countryCode) =>
		dispatch(updateConfigStartAsync(payload, countryCode)),
});

const mapStateToProps = createStructuredSelector({
	countryCode: selectUserCountry,
	loggedInUserRoles: selectLoggedInUserRoles,
	configurationsList: selectConfigList,
	isUpdating: selectIsUpdate,
});
export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
