import { message } from 'antd';
import axios from 'axios';
import pLimit from 'p-limit';
import { MAX_LIMIT_RECORD_BULK, errorMessages } from '../../constants';
import HttpError from '../../errors/HttpError';
import LocationService from '../../services/LocationService';
import { GenerateLocationExportLink } from '../../utils/LocationModule.utils';
import { ErrorModal } from '../../utils/errorMessage';
import SuccessMessage from '../../utils/successMessage';
import LocationActionTypes from './Location.types';

const promiseLimit = pLimit(1);

/* updateLocationStart */
export const updateLocationStart = () => ({
	type: LocationActionTypes.UPDATE_LOCATION_START,
});

/* updateLocationSuccess */
export const updateLocationSuccess = locationMap => ({
	type: LocationActionTypes.UPDATE_LOCATION_SUCCESS,
	payload: locationMap,
});

/* updateLocationFailure */
export const updateLocationFailure = errorMessage => ({
	type: LocationActionTypes.UPDATE_LOCATION_FAILURE,
	payload: errorMessage,
});

/* searchLocationStart */
export const searchLocationStart = () => ({
	type: LocationActionTypes.SEARCH_LOCATION_START,
});

/* searchLocationSuccess */
export const searchLocationSuccess = locationMap => ({
	type: LocationActionTypes.SEARCH_LOCATION_SUCCESS,
	payload: locationMap,
});

/* searchLocationFailure */
export const searchLocationFailure = errorMessage => ({
	type: LocationActionTypes.SEARCH_LOCATION_FAILURE,
	payload: errorMessage,
});

/* csvUploadLocationStart */
export const csvUploadLocationStart = () => ({
	type: LocationActionTypes.CSV_UPLOAD_LOCATION_START,
});

/* csvUploadLocationSuccess */
export const csvUploadLocationSuccess = locationMap => ({
	type: LocationActionTypes.CSV_UPLOAD_LOCATION_SUCCESS,
	payload: locationMap,
});

/* csvUploadLocationFailure */
export const csvUploadLocationFailure = errorMessage => ({
	type: LocationActionTypes.CSV_UPLOAD_LOCATION_FAILURE,
	payload: errorMessage,
});

/* bulkExportLocationStart */
export const bulkExportLocationStart = () => ({
	type: LocationActionTypes.BULK_EXPORT_LOCATION_START,
});

/* bulkExportLocationSuccess */
export const bulkExportLocationSuccess = locationMap => ({
	type: LocationActionTypes.BULK_EXPORT_LOCATION_SUCCESS,
	payload: locationMap,
});

/* bulkExportLocationFailure */
export const bulkExportLocationFailure = errorMessage => ({
	type: LocationActionTypes.BULK_EXPORT_LOCATION_FAILURE,
	payload: errorMessage,
});

/* getLocationFilterStart */
export const getLocationFiltersStart = () => ({
	type: LocationActionTypes.GET_LOCATION_FILTERS_START,
});

/* getLocationFilterSuccess */
export const getLocationFiltersSuccess = locationMap => ({
	type: LocationActionTypes.GET_LOCATION_FILTERS_SUCCESS,
	payload: locationMap,
});

/* getLocationFilterFailure */
export const getLocationFiltersFailure = errorMessage => ({
	type: LocationActionTypes.GET_LOCATION_FILTERS_FAILURE,
	payload: errorMessage,
});

export const getLocationFiltersStartAsync = (erpType, country) => dispatch => {
	const locationService = new LocationService();
	const locationFiltersResponse = locationService.getLocationFilters(
		erpType,
		country,
	);
	if (!locationFiltersResponse) {
		throw new HttpError(0, errorMessages.UNDEFINED_RESPONSE);
	}
	dispatch(getLocationFiltersStart());
	locationFiltersResponse
		.then(locationServiceMap => {
			dispatch(getLocationFiltersSuccess(locationServiceMap));
		})
		.catch(error => dispatch(getLocationFiltersFailure(error.message)));
};

/* isLocationSearchActive */
export const isLocationSearchActive = value => ({
	type: LocationActionTypes.IS_SEARCH_ACTIVE,
	payload: value,
});

/* updateLocationAsync */
export const updateLocationAsync = (value, id) => dispatch => {
	const locationService = new LocationService();
	const request = { ...value, id };
	const locationData = locationService.updateLocationFactory(request);
	dispatch(updateLocationStart());
	return locationData
		.then(locationMap => {
			SuccessMessage(
				`The Location ${locationMap.updatedLocation[0].location} was updated successfully.`,
			);
			dispatch(updateLocationSuccess(locationMap));
		})
		.catch(error => dispatch(updateLocationFailure(error.message)));
};

/* uploadLocationAsync */
export const uploadLocationAsync = uploadedLocation => dispatch => {
	const locationService = new LocationService();
	const formData = new FormData();
	formData.append('file1', uploadedLocation);
	const locationData = locationService.postLocationFactory(formData);

	dispatch(csvUploadLocationStart());
	return locationData
		.then(locationMap => {
			dispatch(csvUploadLocationSuccess(locationMap));
			SuccessMessage(`${locationMap.message}`);
		})
		.catch(error => {
			const respData = error?.response?.data;
			if (respData?.code === '422') {
				ErrorModal(`${respData?.description}`);
			} else {
				ErrorModal(`${error.message}`);
			}
			dispatch(csvUploadLocationFailure(error.message));
		});
};

/* searchForLocationAsync */
export const searchForLocationAsync =
	(erpType, country, keyword, limit, offset, columnName, sortKey) =>
	dispatch => {
		const locationService = new LocationService();
		const locationData = locationService.searchLocationFactory(
			erpType,
			country,
			keyword,
			limit,
			offset,
			columnName,
			sortKey,
		);
		dispatch(searchLocationStart());
		return locationData
			.then(locationMap => {
				dispatch(searchLocationSuccess(locationMap));
			})
			.catch(error => {
				message.error(error.message);
				dispatch(searchLocationFailure(error.message));
			});
	};

export const setSelectedLocationFilters = selectedFilters => ({
	type: LocationActionTypes.SET_LOCATION_FILTERS,
	payload: selectedFilters,
});

export const bulkExportLocationSearchAsync =
	(erpType, country, totalRecord, searchQuery, filterData, year = new Date().getFullYear()) => dispatch => {
		const locationService = new LocationService();
		const url = locationService.getErpUrl();
		const searchedData = [];
		const urls = GenerateLocationExportLink(
			erpType,
			country,
			totalRecord,
			MAX_LIMIT_RECORD_BULK,
			url,
			searchQuery,
			filterData,
			year
		);
		dispatch(bulkExportLocationStart());

		const result = Promise.all(
			urls.map(endPoint =>
				promiseLimit(() => locationService.getLocationUrl(endPoint)),
			),
		);

		return result
			.then(
				axios.spread((...allData) => {
					allData.forEach(element => {
						searchedData.push(...element.data.searchedData);
					});
					dispatch(bulkExportLocationSuccess({ allLocations: searchedData }));
				}),
			)
			.catch(error => dispatch(bulkExportLocationFailure(error.message)));
	};

/* getScheduledCountStatusStart */
export const getScheduledCountStatusStart = () => ({
	type: LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_START,
});

/* getScheduledCountStatusSuccess */
export const getScheduledCountStatusSuccess = status => ({
	type: LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_SUCCESS,
	payload: status,
});

/* getScheduledCountStatusFailure */
export const getScheduledCountStatusFailure = errorMessage => ({
	type: LocationActionTypes.GET_SCHEDULED_COUNT_STATUS_FAILURE,
	payload: errorMessage,
});

/* getScheduleCountStatusAsync */
export const getScheduleCountStatusAsync =
	(selectedElement, setUniqueKeyVisible, setCountId) => dispatch => {
		const productZero = selectedElement.filter(
			location => location.productQuantity === 0,
		);
		if (productZero.length) {
			return ErrorModal(
				'No stock available for counting. Count Instance can’t be created for this location.',
			);
		}
		const locations = selectedElement.map(x => x?.id).join(',');
		const locationService = new LocationService();
		const locationData = locationService.getScheduleCountStatus(locations);
		dispatch(getScheduledCountStatusStart());
		return locationData
			.then(status => {
				dispatch(getScheduledCountStatusSuccess(status));
				if (status?.countStatus !== 'countInProgress') {
					setUniqueKeyVisible(true);
					if (selectedElement[0].cac !== ' ') {
						return setCountId(selectedElement[0].cac);
					}
					return setCountId('');
				}
				return ErrorModal(
					'One or more locations you selected already have a count in progress. The new count instance will not be created.',
				);
			})
			.catch(error => dispatch(getScheduledCountStatusFailure(error.message)));
	};
