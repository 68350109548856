import React from 'react';

function Page403() {
	return (
		<div className="flex justify-center py-48">
			<div className="m-auto">
				<h1 className="font-bold text-jnj_red text-9xl">403</h1>
				<h2 className="font-bold text-jnj_red text-center	">Unauthorized</h2>
				<h2>You are not authorized to view this page</h2>
			</div>
		</div>
	);
}

export default Page403;
