import { createSelector } from 'reselect';

const selectCountInstanceDetail = state => state.countInstanceDetail;

// Count Detail page Selectors
export const selectShowEditCountInstanceModal = createSelector(
	[selectCountInstanceDetail],
	countInstanceDetail => countInstanceDetail.showEditCountInstanceModal,
);

export const selectShowSendNotificationModal = createSelector(
	[selectCountInstanceDetail],
	countInstanceDetail => countInstanceDetail.showSendNotificationModal,
);

// send notification
export const selectSendNotificationSuccess = createSelector(
	[selectCountInstanceDetail],
	countInstanceDetail => countInstanceDetail.successSendNotification,
);
export const selectSendNotificationError = createSelector(
	[selectCountInstanceDetail],
	countInstanceDetail => countInstanceDetail.errorSendNotification,
);
export const selectIsSendingNotification = createSelector(
	[selectCountInstanceDetail],
	countInstanceDetail => countInstanceDetail.isNotificationSending,
);
