/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Footer from '../../components/Footer/Footer.component';
import Header from '../../components/Header/Header.component';
import NavBar from '../../components/NavBar/NavBar.component';
import { PRIVATE_ROUTE_BASE } from '../../constants';
import { logOut } from '../../redux/User/User.actions';
import { selectWebPerms } from '../../redux/User/User.selector';
import routes from '../../routes';
import PrivateRoute from '../../utils/Authentication/PrivateRoute.utils';
import SessionTimeout from '../../utils/Authentication/SessionTimeout.utils';
import {
	getActiveUser,
	removeUserSession,
} from '../../utils/Authentication/UserSession.utils';
import Page404 from '../ErrorPages/Page404/Page404.component';
import { routeGuard } from '../RouteGuard/RouteGuard.HOC';

function MainPage(webPermissions) {
	const [isAuthenticated, setAuth] = useState(!!getActiveUser());
	const dispatch = useDispatch();
	const handleLogout = () => {
		setAuth(!isAuthenticated);
		removeUserSession();
		dispatch(logOut());
		<Redirect to={{ pathname: '/login' }} />;
	};
	const getRenderRoutes = routesMap => {
		const returnRoutes = [];
		routesMap.forEach(({ key, permission, component, path, ...routeProps }) => {
			if (path && path.indexOf(PRIVATE_ROUTE_BASE) !== -1) {
				returnRoutes.push(
					routeGuard(
						key,
						permission,
						path,
						{ ...routeProps },
						webPermissions,
						component,
					),
				);
			}
		});
		return returnRoutes;
	};

	return (
		<>
			<SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogout} />
			<Switch>
				{routes.public.map(route => {
					const { key, component = Page404, ...routeProps } = route;
					return <Route key={key} component={component} {...routeProps} />;
				})}

				<Route exact path="/">
					<Redirect to={PRIVATE_ROUTE_BASE} />
				</Route>

				<Route path={PRIVATE_ROUTE_BASE}>
					<Header />

					<NavBar />
					<div className=" px-4 mx-auto mb-8">
						<Switch>
							{getRenderRoutes(routes.private)}
							<PrivateRoute
								path={`${PRIVATE_ROUTE_BASE}/:view?`}
								component={Page404}
							/>
						</Switch>
					</div>
					<Footer />
				</Route>
				<Route path="*" component={Page404} />
			</Switch>
		</>
	);
}
const mapStateToProps = createStructuredSelector({
	webPermissions: selectWebPerms,
});
export default connect(mapStateToProps)(MainPage);
