const UserActionTypes = {
	SHOW_USER_MODAL_FORM: 'SHOW_USER_MODAL_FORM',
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	CHECK_USER_SESSION: 'CHECK_USER_SESSION',
	SIGN_OUT_START: 'SIGN_OUT_START',
	SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
	SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
	FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
	SEARCH_USERS_START: 'SEARCH_USERS_START',
	SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
	SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',
	DELETE_USERS_START: 'DELETE_USERS_START',
	DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
	DELETE_USERS_FAILURE: 'DELETE_USERS_FAILURE',
	SEND_PASSWORD_RESET_START: 'SEND_PASSWORD_RESET_START',
	SEND_PASSWORD_RESET_FAILURE: 'SEND_PASSWORD_RESET_FAILURE',
	SEND_PASSWORD_RESET_SUCCESS: 'SEND_PASSWORD_RESET_SUCCESS',
	SUBMIT_USER_START: 'SUBMIT_USER_START',
	SUBMIT_USER_SUCCESS: 'SUBMIT_USER_SUCCESS',
	SUBMIT_USER_FAILURE: 'SUBMIT_USER_FAILURE',
	FETCH_PERMISSION_SUCCESS: 'FETCH_PERMISSION_SUCCESS',
	FETCH_PERMISSION_FAILURE: 'FETCH_PERMISSION_FAILURE',
	EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
	FETCH_LOGGED_IN_USER_SUCCESS: 'FETCH_LOGGED_IN_USER_SUCCESS',
	FETCH_LOGGED_IN_USER_FAILURE: 'FETCH_LOGGED_IN_USER_FAILURE',
	USER_LOGOUT: 'USER_LOGOUT',
	USER_UPDATE_LAST_LOGIN_START: 'USER_UPDATE_LAST_LOGIN_START',
	USER_UPDATE_LAST_LOGIN_SUCCESS: 'USER_UPDATE_LAST_LOGIN_SUCCESS',
	USER_UPDATE_LAST_LOGIN_FAILURE: 'USER_UPDATE_LAST_LOGIN_FAILURE',
	BULK_EXPORT_USER_LIST_START: 'BULK_EXPORT_USER_LIST_START',
	BULK_EXPORT_USER_LIST_SUCCESS: 'BULK_EXPORT_USER_LIST_SUCCESS',
	BULK_EXPORT_USER_LIST_FAILURE: 'BULK_EXPORT_USER_LIST_FAILURE',
	USER_FILTER_STATUS: 'USER_FILTER_STATUS',
	USER_REDIRECT_URL: 'USER_REDIRECT_URL',
	SET_ERP_TYPE: 'SET_ERP_TYPE',
};
export default UserActionTypes;
