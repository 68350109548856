import LocationActionTypes from './Location.types';

const INITIAL_STATE = {
	errorMessage: undefined,
	isCsvUploadFetching: false,
	isSearchActive: false,
};

const locationUploadReducer = (
	state = INITIAL_STATE,
	{ type, payload } = {},
) => {
	switch (type) {
		case LocationActionTypes.CSV_UPLOAD_LOCATION_START:
			return {
				...state,
				isCsvUploadFetching: true,
			};
		case LocationActionTypes.CSV_UPLOAD_LOCATION_SUCCESS:
			return {
				...state,
				isCsvUploadFetching: false,
			};
		case LocationActionTypes.CSV_UPLOAD_LOCATION_FAILURE:
			return {
				...state,
				isCsvUploadFetching: false,
				errorMessage: payload,
			};

		default:
			return state;
	}
};

export default locationUploadReducer;
