import React from 'react';

function Page404() {
	return (
		<div className="flex justify-center py-48">
			<div className="m-auto">
				<h1 className="font-bold text-jnj_red text-9xl">404</h1>
				<h2 className="font-bold text-jnj_red text-center	">Page Not Found</h2>
				<h2>The page you are looking for is not found</h2>
			</div>
		</div>
	);
}

export default Page404;
