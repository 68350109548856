import CountryListTypes from './CountryList.types';

const INITIAL_STATE = {
	// Fetch Countries
	isFetching: false,
	errorFetch: null,
	countryList: null,
};

// eslint-disable-next-line default-param-last
const countryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CountryListTypes.FETCH_COUNTRIES_START:
			return {
				...state,
				isFetching: true,
			};

		case CountryListTypes.FETCH_COUNTRIES_SUCCESS:
			return {
				...state,
				isFetching: false,
				countryList: action.payload,
			};
		case CountryListTypes.FETCH_COUNTRIES_FAILURE:
			return {
				...state,
				isFetching: false,
				errorFetch: action.payload,
			};
		default:
			return state;
	}
};

export default countryReducer;
