import { Form, InputNumber } from 'antd';
import React from 'react';
import { ReactComponent as EqualSign } from '../../../assets/icons/equal-sign-icon.svg';
import { checkDecimalNumber } from '../../../utils/Configuration/Configuration.utils';

/* Financial Component */
function Financial() {
	return (
		<div className="gap-2 text-base font-bold ">
			{/* Conversion Rates Table */}
			<div className="md:px-6 py-4 md:border-2">Conversion Rates</div>
			<div className="md:px-6 py-4 md:border-l-2 md:border-b-2 md:border-r-2">
				{/* List of Table */}
				<div className="grid grid-cols-3 gap-2 ">
					<div className="pt-2 grid grid-cols-3 justify-center">
						<span>GBP</span>
						<span className="xs:sm:text-right px-5">1</span>
						<div className="hidden md:block">
							<EqualSign />
						</div>
					</div>
					<div className="flex gap-5">
						<div className="text-center pt-2">USD</div>
						<Form.Item
							name="gbpUsd"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>
					<div className="flex gap-5">
						<div className="pt-2">EUR</div>
						<Form.Item
							name="gbpEur"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>

					<div className="pt-2 grid grid-cols-3 justify-center">
						<span>USD</span>
						<span className="xs:sm:text-right px-5">1</span>
						<div className="hidden md:block">
							<EqualSign />
						</div>
					</div>
					<div className="flex gap-5">
						<div className="text-center pt-2">GBP</div>{' '}
						<Form.Item
							name="usdGbp"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>
					<div className="flex gap-5">
						<div className="pt-2">EUR</div>
						<Form.Item
							name="usdEur"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>

					<div className="pt-2 grid grid-cols-3 justify-center">
						<span>EUR</span>
						<span className="xs:sm:text-right px-5">1</span>
						<div className="hidden md:block">
							<EqualSign />
						</div>
					</div>
					<div className="flex gap-5">
						<div className="text-center pt-2">GBP</div>{' '}
						<Form.Item
							name="eurGbp"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>
					<div className="flex gap-5">
						<div className="pt-2">USD</div>
						<Form.Item
							name="eurUsd"
							rules={[
								{
									validator: async (_, value) => {
										checkDecimalNumber(value);
									},
								},
							]}>
							{/* Input field */}
							<InputNumber
								disabled
								required
								min={0}
								defaultValue={0}
								className="w-auto"
							/>
						</Form.Item>
					</div>
				</div>
			</div>
			<div className="pt-6 ml-4 md:ml-0">
				{' '}
				{/* Writeoff Approval Threshold L1 Input Field */}
				<p className=" text-base font-bold pb-2">
					Writeoff Approval Threshold L1:
				</p>
				<Form.Item name="writeOffThresholdL1">
					{/* Input field */}
					<InputNumber required min={0} />
				</Form.Item>
				{/* Writeoff Approval Threshold L2 Input Field */}
				<p className=" text-base font-bold pb-2">
					Writeoff Approval Threshold L2:
				</p>
				<Form.Item name="writeOffThresholdL2">
					{/* Input field */}
					<InputNumber required min={0} />
				</Form.Item>
				{/* Writeoff Approval Threshold L3 Input Field */}
				<p className=" text-base font-bold pb-2">
					Writeoff Approval Threshold L3:
				</p>
				<Form.Item name="writeOffThresholdL3">
					{/* Input field */}
					<InputNumber required min={0} />
				</Form.Item>
			</div>
		</div>
	);
}
export default Financial;
