/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import UserService from '../../services/UserService';
import PrivateRoute from '../../utils/Authentication/PrivateRoute.utils';
import Page403 from '../ErrorPages/Page403/Page403.component';
import Page404 from '../ErrorPages/Page404/Page404.component';

export const routeGuard = (
	key,
	permission,
	path,
	routeProps,
	webPerms,
	Component = Page404,
) => {
	const permissionService = new UserService();
	if (
		!permissionService.checkPermission(
			permission,
			webPerms && webPerms.webPermissions,
		)
	) {
		return (
			<PrivateRoute key={key} path={path} component={Page403} {...routeProps} />
		);
	}
	return (
		<PrivateRoute key={key} path={path} component={Component} {...routeProps} />
	);
};

export default routeGuard;
