const LocationActionTypes = {
	UPDATE_LOCATION_START: 'UPDATE_LOCATION_START',
	UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
	UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
	SEARCH_LOCATION_START: 'SEARCH_LOCATION_START',
	SEARCH_LOCATION_SUCCESS: 'SEARCH_LOCATION_SUCCESS',
	SEARCH_LOCATION_FAILURE: 'SEARCH_LOCATION_FAILURE',
	CSV_UPLOAD_LOCATION_START: 'CSV_UPLOAD_LOCATION_START',
	CSV_UPLOAD_LOCATION_SUCCESS: 'CSV_UPLOAD_LOCATION_SUCCESS',
	CSV_UPLOAD_LOCATION_FAILURE: 'CSV_UPLOAD_LOCATION_FAILURE',
	BULK_EXPORT_LOCATION_START: 'BULK_EXPORT_LOCATION_START',
	BULK_EXPORT_LOCATION_SUCCESS: 'BULK_EXPORT_LOCATION_SUCCESS',
	BULK_EXPORT_LOCATION_FAILURE: 'BULK_EXPORT_LOCATION_FAILURE',
	IS_SEARCH_ACTIVE: 'IS_SEARCH_ACTIVE',
	GET_SCHEDULED_COUNT_STATUS_START: 'GET_SCHEDULED_COUNT_STATUS_START',
	GET_SCHEDULED_COUNT_STATUS_SUCCESS: 'GET_SCHEDULED_COUNT_STATUS_SUCCESS',
	GET_SCHEDULED_COUNT_STATUS_FAILURE: 'GET_SCHEDULED_COUNT_STATUS_FAILURE',
	GET_LOCATION_FILTERS_START: 'GET_LOCATION_FILTERS_START',
	GET_LOCATION_FILTERS_SUCCESS: 'GET_LOCATION_FILTERS_SUCCESS',
	GET_LOCATION_FILTERS_FAILURE: 'GET_LOCATION_FILTERS_FAILURE',
	SET_LOCATION_FILTERS: 'SET_LOCATION_FILTERS',
};

export default LocationActionTypes;
