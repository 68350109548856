export const getToken = () => sessionStorage.getItem('fsrToken') || null;

export const removeUserSession = () => {
	sessionStorage.removeItem('fsrToken');
	sessionStorage.removeItem('sessionExpiry');
	sessionStorage.removeItem('currentUser');
};

export const setActiveUser = user => {
	sessionStorage.setItem('currentUser', user);
};

export const getActiveUser = () =>
	sessionStorage.getItem('currentUser') || null;

export const setUserSession = (token, sessionExpiry) => {
	sessionStorage.setItem('fsrToken', token);
	sessionStorage.setItem('sessionExpiry', sessionExpiry);
};
