import { createSelector } from 'reselect';

const selectGetAuditCount = state => state.getAuditCount;
const selectExportAuditCount = state => state.exportAuditCount;
const selectUploadAuditCount = state => state.uploadAuditCount;
const selectSignature = state => state.signatureDownload;

export const selectSignatureFile = createSelector(
	[selectSignature],
	signatureDownload => signatureDownload.signatureDownloadFile,
);

export const selectisSignatureFileDownloading = createSelector(
	[selectSignature],
	signatureDownload => signatureDownload.isSignatureDownloadFetching,
);

// bulk audit count
export const selectAuditBulkExportRecord = createSelector(
	[selectExportAuditCount],
	exportAuditCount => exportAuditCount.bulkAuditCountInstanceData,
);

export const selectAuditBulkExportFetching = createSelector(
	[selectExportAuditCount],
	exportAuditCount => exportAuditCount.isAuditBulkRequestFetching,
);

// Audit Count Selectors
export const selectAuditCountInstance = createSelector(
	[selectGetAuditCount],
	getAuditCount => getAuditCount.scheduledCountAudit,
);

export const selectAuditCountInstanceError = createSelector(
	[selectGetAuditCount],
	getAuditCount => getAuditCount.errorMessageAudit,
);
export const selectIsAuditCountFetching = createSelector(
	[selectGetAuditCount],
	getAuditCount => getAuditCount.isAuditFetching,
);
export const selectTotalAuditCount = createSelector(
	[selectGetAuditCount],
	getAuditCount => getAuditCount.totalCount,
);
export const selectIsAuditCountInstanceLoaded = createSelector(
	[selectGetAuditCount],
	getAuditCount => !!getAuditCount.scheduledCountAudit,
);

export const selectCsvUploadFetching = createSelector(
	[selectUploadAuditCount],
	uploadAuditCount => uploadAuditCount.isCsvUploadFetching,
);
