import React from 'react';
import { JNJ_BLUE, JNJ_RED } from '../../../constants';

function ColoredValue({ value }) {
	const colorsDefinition = color => {
		if (color > 0) return JNJ_BLUE;
		if (color < 0) return JNJ_RED;
		return '';
	};

	return <span className={`${colorsDefinition(value)}`}>{value}</span>;
}
export default ColoredValue;
